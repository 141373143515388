<template>
    <div class="date">{{ displayDate }}</div>
</template>
<style scoped>
.date {
    text-align: right;
}
</style>
<script>
export default {
    props: ['date'],
    computed: {
        displayDate(){
            let d = new Date(this.date)
            let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            return d.getDate().toString() + ' ' + months[d.getMonth()] + ' ' + d.getFullYear().toString();
        }
    }
}
</script>