<template>
    <div class="logged-in">
        <div v-if="user">
            <h1>{{ planName }} <span class="material-symbols-outlined" v-if="planName != 'Free Plan'">
                    diamond
                </span></h1>
            <div style="height:0.5rem; border-bottom: 2px dotted black; margin-bottom: 0.5rem;"></div>
            <div class="date">{{ startTime }} to {{ endTime }}</div>
            <div>Registered Number: {{ user.phone_number }}</div>
            <div style="height:0.5rem; border-bottom: 2px dotted black; margin-bottom: 0.5rem;"></div>

            <div style="height: 0.5rem"></div>
            <p><span class="heading">Next </span> download the STAT mobile app to configure your practice details.</p>
            <p v-if="planName == 'Plan C'"><span style="color: red;:">Important</span> You need the mobile app to generate the time based desktop login code.</p>
            <div style="height: 0.5rem"></div>
            <p class="heading">Mobile / Tab</p>
            <a href="https://play.google.com/store/apps/details?id=com.milieu.prescription"> <div class="item"><i class="fa-brands fa-google-play"></i>&nbsp;&nbsp;Android</div></a>
            <a href="https://apps.apple.com/in/app/stat-prescription-maker/id1526714795"><div class="item"><i class="fa-brands fa-apple"></i>&nbsp;&nbsp;Apple</div></a>
            <div style="height: 0.5rem"></div>
            <div v-if="planName == 'Plan C'">
            <p class="heading">Desktop</p>
            <a href="https://statreleases.blob.core.windows.net/stat-desktop-windows/stat_installer.exe" download><div class="item"><i class="fa-brands fa-windows"></i>&nbsp;&nbsp;Windows</div></a>
            <a href="https://statreleases.blob.core.windows.net/stat-desktop-mac/STAT_installer.dmg" download><div class="item"><i class="fa-brands fa-apple"></i>&nbsp;&nbsp;Mac</div></a>
        </div>
        </div>
        <div v-else>
            <LoaderComponent></LoaderComponent>
        </div>
    </div>
</template>
<script>
import LoaderComponent from "@/components/LoaderComponent.vue"

export default {
    components: {
        LoaderComponent
    },
    data() {
        return {
        }
    },
    computed: {
        baseUrl: {
            get() {
                return this.$store.state.baseUrl
            }
        },
        token: {
            get() {
                return this.$store.state.refreshToken
            }
        },
        user: {
            get() {
                return this.$store.state.user
            },
            set(value) {
                this.$store.commit('SET_USER', value)
            }
        },
        planName: {
            get() {
                if (this.user.membership.pro_status == 3) {
                    return "Plan C"
                }
                if (this.user.membership.pro_status == 2) {
                    return "Plan B"
                }
                if (this.user.membership.pro_status == 1) {
                    return "Plan A"
                }
                return "Free Plan"
            }
        },
        startTime: {
            get() {
                let unixTime = this.user.membership.start_date.T
                let date = new Date(unixTime * 1000)
                let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                return date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear()
            }
        },
        endTime: {
            get() {
                let unixTime = this.user.membership.end_date.T
                let date = new Date(unixTime * 1000)
                let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                return date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear()
            }
        }

    },
    async mounted() {
       
        document.head.innerHTML += '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css" type="text/css"/>';
        let url = this.baseUrl + "/get-doctor"
        let opts = {
            method: 'POST',
            headers: { 
                "Content-Type": "application/json",
                "Authorization": this.token
            },
            body: JSON.stringify({
                "check_payment": true
            })
        }
        // eslint-disable-next-line
        let response = await fetch(url, opts)
        let data = await response.json()
        this.user = data
         if(this.planName == "Free Plan"){
            this.$router.push('/plans')
        }
    }
}
</script>
<style scoped>
.logged-in {
    text-align: left;
    margin: 2rem auto !important;
    max-width: 650px;
}

h1 {
    font-family: 'Zodiak-Extrabold';
}

.material-symbols-outlined {
    font-size: 1.5rem;
    color: goldenrod;
}

.date {
    font-family: 'Zodiak-Extrabold';
    font-size: 1rem;
    color: #5a5a5a;
}

.heading {
    font-weight: bold;
    color: #5a5a5a;
}
.item {
    margin-left: 1rem;
    padding-top: 0.5rem;
    color: blue;
}
i.fa-brands{
    color: black !important;
}
</style>