<template>
    <div>
        <div class="line-one">
            <div class="invoice-id"><strong>Invoice Id</strong> {{ rx.prescription_id }}</div>
            <div class="date">
                <RxDate :date="rx.date" />
            </div>
        </div>
        <div><strong>Billed To</strong></div>
        <div>{{ pt.name }}</div>
        <div v-if="pt.address">{{ pt.address }}</div>
        <div v-if="pt.phone_number">Mobile: {{ pt.phone_number }}</div>
        <div v-if="pt.email">Email: {{ pt.email }}</div>
        <div v-if="isTaxed">
            <WithTax :rx="rx" />
        </div>
        <div v-else>
            <NoTax :rx="rx" />
        </div>
        <PaymentButton :rx="rx" />
    </div>
</template>
<script>
import RxDate from "@/components/prescription/content/RxDate.vue"
import WithTax from "./WithTax.vue"
import NoTax from "./NoTax.vue"
import PaymentButton from "./PaymentButton.vue"

export default {
    props: ['rx'],
    components: {
        RxDate,
        WithTax,
        NoTax,
        PaymentButton
    },
    computed: {
        pt() {
            return this.rx.patient
        },
        isTaxed(){
            for (let i = 0; i < this.rx.charges.length; i++){
                if(this.rx.charges[i].taxed == true){
                    return true
                }
            }
            return false
        },
    }
}
</script>
<style scoped>
.line-one {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    margin-bottom: 1rem;
}
.date {
    margin-left: auto;
}
</style>