<template>
    <div class="rx-footer-standard">
            <div class="disclaimer">
                <div v-if="disclaimer">{{ disclaimer }}</div>
                <div v-else class="footer-info">This prescription has been generated digitally, scan qr code to confirm authenticity.</div>
            </div>
            <qrcode-vue :value="qr" :size="size" level="L" />
    </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue'

export default {
    props: ['disclaimer'],
    components: {
        QrcodeVue
    },
    data(){
        return {
            qr: "https://stat.org.in/rx" + this.$route.params.id + '/' + this.$route.params.password,
            size: 50
        }
    }
}
</script>
<style scoped>
.rx-footer-standard {
    font-size: 16px;
    line-height: 1.2;
    padding: 8px;
    border-top: 2px dotted #3a3a3a;
    margin-top: 12px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    width: 100%;
}
.disclaimer{
    width: 100%;
}

</style>