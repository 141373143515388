<template>
    <div class="pt-web">
        <div style="height: 1rem"> </div>
        <p>Enter the 6 digit code sent to <br>{{ phoneNumber }} <a href="/phone-number" @click="goback">[Change
                Number]</a></p>
        <div style="height: 1rem"> </div>
        <div class="box-holder" ref="wrap">
            <template v-for="(item, index) in otpFields" :key="index + 1">
                <input :autofocus="index == 0" type="number" @keydown="handleKey($event, index)" @paste="onPaste"
                    class="opt-box" :id="'box' + index" />
            </template>
        </div>
        <div id='captcha'></div>
        <Spinner v-if="loading"></Spinner>
    </div>
</template>
<script>
import Spinner from '@/components/SpinnerComponent.vue'
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

export default {
    name: 'EnterOTP',
    data() {
        return {
            loading: false,
            otpFields: ['', '', '', '', '', ''] //only to genrate ui field not bound 
        }
    },
    components: {
        Spinner
    },
    computed: {
        phoneNumber: {
            get() {
                return this.$store.state.mobile
            }
        },
        baseUrl: {
            get(){
                return this.$store.state.baseUrl
            }
        },
        patient_profiles: {
            set(val){
                this.$store.commit('SET_PATIENT_PROFILES', val)
            }
        },
        patient: {
            get(){
                return this.$store.state.patient
            },
            set(val){
                this.$store.commit('SET_PATIENT', val)
            }
        },
        tokens: {
            set(val){
                this.$store.commit('SET_TOKENS', val)
            }
        },
        access_token: {
            set(val){
                this.$store.commit('SET_ACCESS_TOKEN', val)
            }
        },
        refresh_token: {
            set(val){
                this.$store.commit('SET_REFRESH_TOKEN', val)
            }
        },
        boxes() {
            return document.getElementsByTagName('input')
        },
        doctorId: {
            get(){
                return this.$store.state.userId
            }
        }
    },
    mounted() {
        const auth = getAuth();
        const appVerifier = new RecaptchaVerifier('captcha', {
            'size': 'invisible',
            'callback': (response) => {
                // onSignInSubmit();
                console.log(response);
            }
        }, auth);
        signInWithPhoneNumber(auth, this.phoneNumber, appVerifier)
            .then((confirmationResult) => {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                window.confirmationResult = confirmationResult
            }).catch((error) => {
                console.log(error)
            });

    },
    methods: {
        goback() {
            this.$router.back()
            return false
        },
        onPaste(event) {
            const clipboardData = event.clipboardData || window.clipboardData;
            if (!clipboardData) { return; }
            event.preventDefault();
            let code = clipboardData.getData("Text") || clipboardData.getData("text/plain");
            code = code.trim()
            code = code.slice(0, 6)
            let isnum = /^\d+$/.test(code);
            if (isnum) {
                let parts = code.split("")
                for (let i = 0; i < 6; i++) {
                    this.boxes[i].value = parts[i]
                }
                this.boxes[5].focus()
                this.enteredCode()
            }
        },
        handleKey(event, index) {
            if (event.key == 'Unidentified') {
                return
            }

            let key = event.key
            if (key == 'Backspace') {
                event.preventDefault();
                this.boxes[index].value = ''
                if (index > 0) {
                    this.boxes[index - 1].focus()
                }
            }
            else if (key == "ArrowRight" || key == "Right") {
                event.preventDefault();
                if (index < 5) {
                    this.boxes[index + 1].focus()
                }
            }
            else if (key == "ArrowLeft" || key == "Left") {
                event.preventDefault();
                if (index > 0) {
                    this.boxes[index - 1].focus()
                }
            }
            else if (key >= 0 && key < 10) {
                event.preventDefault();
                this.boxes[index].value = key
                if (index < 5) {
                    this.boxes[index + 1].focus()
                }
            }
            this.enteredCode()
        },
        enteredCode() {
            let code = ''
            for (let i = 0; i < 6; i++) {
                code = code + this.boxes[i].value
            }
            let isnum = /^\d+$/.test(code);
            if (code.length == 6 && isnum) {
                this.loading = true
                window.confirmationResult.confirm(code).then(async (result) => {
                    // User signed in successfully.
                    let idToken = result._tokenResponse.idToken;
                    let requestOptions = {
                        method: 'POST',
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            "phone_number": this.phoneNumber,
                            "firebase_token": idToken,
                            "doctor_id": this.doctorId
                        })
                    }
                    let url = this.baseUrl + '/patient-login'
                    let respone = await fetch(url, requestOptions)
                    if(respone.ok){
                        let body = await respone.json()
                        this.patient_profiles = body.patient_profiles
                        this.tokens = body.patient_tokens
                        if(body.patient_profiles.length == 1){
                            this.patient = body.patient_profiles[0]
                            this.access_token = body.patient_tokens[0].access_token
                            this.refresh_token = body.patient_tokens[0].refresh_token
                        }
                        this.navigation()
                    }
                    // ...
                }).catch((error) => {
                    console.log(error)
                    // User couldn't sign in (bad verification code?)
                    // ...
                });
            }
        },
        navigation(){
            this.$router.push('/select-profile')
            // if(this.patient){
            //  this.$router.push('/patient-home')
            // }else{
            //     this.$router.push('/select-profile')
            // }
        }
    }
}
</script>
<style scoped>
.loader {
    margin: 0;
    margin-top: 1rem;
}

.opt-box {
    text-align: center !important;
    padding: 4px !important;
}

.box-holder>input {
    max-width: 40px;
    margin-right: 4px;
    border: 2px solid #0B62F1;
    height: 40px;
    text-align: center;
}

.box-holder>input:focus {
    border: 2px solid black !important;
    outline: 0;
    outline-color: transparent;
    outline-style: none;
}

.box-holder {
    display: flex;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}
</style>
