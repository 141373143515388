<template>
    <div class="pt-web left">
        <p>Are you suffering from any <strong>medical conditions?</strong></p>
        <p>This helps your doctor treat you better & might affect the choice of
          medication.</p>
        <div class="spacer"></div>
        <div class="spacer"></div>

        <div class="checkbox-container" @click="dm = !dm">  
        <input type="checkbox" id="dm" v-model="dm"><label for="dm" @click="dm = !dm">Diabetes Mellitus</label>
        </div>
        <div class="checkbox-container" @click="htn=!htn"> 
        <input type="checkbox" id="htn" v-model="htn"><label for="htn"  @click="htn=!htn">Hypertension</label>
        </div>
        <div class="checkbox-container" @click="asthma = !asthma"> 
        <input type="checkbox" id="asthma" v-model="asthma"><label for="asthma" @click="asthma = !asthma">Asthma</label>
        </div>
        <div class="checkbox-container" @click="chf=!chf"> 
        <input type="checkbox" id="chf" v-model="chf"><label for="chf" @click="chf=!chf">Chronic Heart Failure</label>
        </div>
        <div class="checkbox-container" @click="mi = !mi"> 
        <input type="checkbox" id="mi" v-model="mi"><label for="mi" @click="mi = !mi">Past Heart Attack</label>
        </div>
        <div class="checkbox-container" @click="stroke = !stroke"> 
        <input type="checkbox" id="stroke" v-model="stroke"><label for="stroke"  @click="stroke = !stroke">Stroke</label>
        </div>
        <div class="checkbox-container" @click="ckd = !ckd"> 
        <input type="checkbox" id="ckd" v-model="ckd"><label for="ckd"  @click="ckd = !ckd">Chronic Kidney Disease</label>
        </div>
        <div class="checkbox-container" @click="showSurgeryModal"> 
        <input type="checkbox" id="sx" v-model="sx"><label for="sx">{{pastSurgeryDisaplyTxt}}</label>
        </div>
        <div class="checkbox-container" @click="showAllergyModal"> 
        <input type="checkbox" id="allergy" v-model="allergy"><label for="allergy">{{allergyDisplayText}}</label>
        </div>
        <div class="checkbox-container" @click="showOtherModal"> 
        <input type="checkbox" id="others" v-model="others"><label for="others">{{otherDisplyText}}</label>
        </div>
        {{ patient }}

                <div style="margin-top: 3rem; color: white">.</div>

        <div v-if="showModal" class="modal">
            <div class="modal-content">
                <h2>{{modalHeadling}}</h2>
                <p>{{modalBody}}</p>
                <p class="explanation">Seperate each conditon with a comma</p>
                <input 
                v-model="modalInput" 
                type="text" 
                autofocus 
                class="modal-input"
                :placeholder="modalPlaceHolder">
                <button class="modal-buttons float"  @click="modalDone">Done</button>
                <button class="modal-buttons cancel float" @click="cancelModal">Cancel</button>
                <button class="modal-buttons" style="visibility: hidden">Done</button>
            </div>
        </div>
        <button class="fab" @click="next"><div class="right-arrow"></div></button>
    </div>
</template>
<style scoped>
.spacer {
    height: 1rem
}
.left {
    text-align: left;
}
.float{
    float: right;
}
.modal-buttons{
    height: 2rem;
    width: 4rem;
    background-color:blue;
    margin-top: 1rem;
    color: white;
}
.cancel {
    background-color: red;
    margin-right: 0.5rem;
}
.modal-input {
    width: 100%; 
    border: 2px solid blue; 
    padding: 1rem;
    padding-left: 0.25rem;
    margin-top: 1rem;
}
.modal-input:focus{
    border: 2px solid black;
}
.modal{
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 1rem;
  border: 1px solid #888;
  width: 90%;
  z-index: 10;
}

.checkbox-container {
    border-bottom: 2px dotted black;
    display: flex;
    flex-direction: row;
    padding-bottom: 0.75rem;
    padding-top: 0.5rem;
    margin-top: 0.25rem;
    cursor: pointer;
}
input[type="checkbox"]{
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 1rem;
    border-radius: 0;
    border: 2px solid black;
}
input[type="checkbox"]:checked {
    background-color: red;
}
</style>
<script>
import router from "@/router"

export default {
    data(){
        return {
            showModal: false,
            modalHeadling: '',
            modalBody: '',
            modalInput: '',
            modalPlaceHolder: '',
        }
    },
    computed: {
        patient: {
            get() {
                return this.$store.state.patient
            },
            set(val) {
                let newVal = structuredClone(val)
                this.$store.commit('SET_PATIENT', newVal)
            }
        },
        dm: {
            get(){
                return this.patient.comorbidities.includes('Diabetes Mellitus')
            },
            set(val){
                if(val){
                    this.addComorbidity('Diabetes Mellitus')
                }else{
                    this.removeComorbidity('Diabetes Mellitus')
                }
            }
        },
        htn: {
            get(){
                return this.patient.comorbidities.includes('Hypertension')
            },
            set(val){
                if(val){
                    this.addComorbidity('Hypertension')
                }else{
                    this.removeComorbidity('Hypertension')
                }
            }
        },
        asthma: {
            get(){
                return this.patient.comorbidities.includes('Asthma')
            },
            set(val){
                if(val){
                    this.addComorbidity('Asthma')
                }else{
                    this.removeComorbidity('Asthma')
                }
            }
        },
        chf: {
            get(){
                return this.patient.comorbidities.includes('Chronic Heart Failure')
            },
            set(val){
                if(val){
                    this.addComorbidity('Chronic Heart Failure')
                }else{
                    this.removeComorbidity('Chronic Heart Failure')
                }
            }
        },
        mi: {
            get(){
                return this.patient.comorbidities.includes('Past MI')
            },
            set(val){
                if(val){
                    this.addComorbidity('Past MI')
                }else{
                    this.removeComorbidity('Past MI')
                }
            }
        },
        stroke: {
            get(){
                return this.patient.comorbidities.includes('Past Stroke')
            },
            set(val){
                if(val){
                    this.addComorbidity('Past Stroke')
                }else{
                    this.removeComorbidity('Past Stroke')
                }
            }
        },
        ckd: {
            get(){
                return this.patient.comorbidities.includes('Chronic Kidney Disease')
            },
            set(val){
                if(val){
                    this.addComorbidity('Chronic Kidney Disease')
                }else{
                    this.removeComorbidity('Chronic Kidney Disease')
                }
            }
        },
        sx(){
            if(this.patient.pastSurgeries){
                return true
            }
            return false
        },
        allergy(){
            if(this.patient.allergies){
                return true
            }
            return false
        },
        others() {
            if(this.patient.others){
                return true
            }
            return false
        },
        otherDisplyText(){
            if(this.patient.others){
                return 'Others: ' + this.patient.others
            }
            return 'Others'
        },
        allergyDisplayText(){
            if(this.patient.allergies){
                return 'Allergy: ' + this.patient.allergies
            }
            return 'Allergy'
        },
        pastSurgeryDisaplyTxt(){
            if(this.patient.pastSurgeries){
                return 'Past Surgery: ' + this.patient.pastSurgeries
            }
            return 'Past Surgery'
        }, 
    },
    methods: {
        addComorbidity(item){
            if(!this.patient.comorbidities.includes(item)){
                this.patient.comorbidities.push(item) 
            }
        },
        removeComorbidity(value){
            this.patient.comorbidities = this.patient.comorbidities.filter(function(item) {
                return item !== value
            })
        },
        showOtherModal(){
            this.modalHeadling = 'Others'
            this.modalBody = 'Enter your medical condition'
            this.modalPlaceHolder = 'eg: Tuberculosis, fracture...',
            this.modalInput = this.otherText
            this.showModal = true
        },
        showAllergyModal(){
            this.modalHeadling = 'Allergy'
            this.modalBody = 'Enter your allergies'
            this.modalPlaceHolder = 'eg: egg, shellfish...'
            this.modalInput = this.allergyText
            this.showModal = true
        },
        showSurgeryModal(){
            this.modalHeadling = 'Past Surgery'
            this.modalBody = 'Enter your previous surgeries'
            this.modalPlaceHolder = 'eg: appendectomy, tonsillectomy...'
            this.modalInput = this.pastSurgeryTxt
            this.showModal = true
        },
        cancelModal(){
            this.modalBody = ''
            this.modalPlaceHolder = ''
            if(this.modalInput == ''){
                switch(this.modalHeadling){
                    case 'Others' : {
                        this.others = false
                        break
                    }
                    case 'Allergy': {
                        this.allergy = false
                        break
                    }
                    case 'Past Surgery': {
                        this.sx = false
                        break
                    }
                }
            }else{
                this.modalDone()
            }
            this.modalHeadling = ''
            this.modalInput = ''
            this.showModal = false
        },
        modalDone(){
            this.modalBody = ''
            this.modalPlaceHolder = ''
            if(this.modalInput != ''){
                switch(this.modalHeadling){
                    case 'Others' : {
                        this.patient.others = this.modalInput
                        break
                    }
                    case 'Allergy': {
                        this.patient.allergies = this.modalInput
                        break
                    }
                    case 'Past Surgery': {
                        this.patient.pastSurgeries = this.modalInput
                        break
                    }
                }
            }else{
                this.cancelModal()
            }
            this.modalHeadling = ''
            this.modalInput = ''
            this.showModal = false
        },
        next(){         
            if(this.editingProfile == true){
                router.push({name: 'EditProfile'})
            }else{
                router.push({name: 'Stats'})
            }
            
        }
    }
}
</script>