<template>
    <div v-if="displayText" @click="displayModal=!displayModal" class="timings">
        <span class="icon">schedule</span> {{ displayText }}<span class="icon">expand_more</span>
        <div id="timing-modal" v-if="displayModal == true">
            <div id="timing-modal-content">
                <TimingsTable :timings="timings"></TimingsTable>
                <button class="close">Close</button>
            </div>
        </div>
    </div>
</template>
<script>
import TimingsTable from './TimingsTable.vue'

export default {
    props: ['sessiontimings'],
    components: {
        TimingsTable
    },
    data() {
        return {
            displayText: "",
            todayInt: "",
            timings: this.sessiontimings,
            displayModal: false
        }
    },
    created() {
        this.sortAllSessions()

        //check if today is open return null if no sutable time else returns a string
        let trailOfToday = this.tryTodayTime()
        if (trailOfToday) {
            this.displayText = trailOfToday
        }
        this.setNextOpenTime()

    },
    computed: {
        nextOpenInt() {
            for (let z = 0; z < this.timings.length; ++z) {
                let idx = (z + this.todayInt) % this.timings.length
                if(this.timings[idx].selected){
                    return idx
                }
            }
            return -1
        }
    },
    methods: {
        sortAllSessions() {
            for (let i = 0; i < this.timings.length; i++) {
                if(this.timings[i].selected){
                    this.timings[i].sessions.sort(
                    function (a, b) {
                        let aInt = a.start_hour * 60 + a.start_min
                        let bint = b.start_hour * 60 + b.start_min
                        return aInt - bint
                    }
                )
                }
            }
        },
        tryTodayTime() {
            let now = new Date()
            this.todayInt = now.getDay()
            if (this.timings[this.todayInt].selected) {
                return this.getOpenTimeToday(this.timings[this.todayInt].sessions)
            }
            return null
        },
        getOpenTimeToday(sessions) {
            let now = new Date()

            //check if now is before 15min of any end time 
            const hour = now.getHours()
            const min = now.getMinutes()

            //convert into min for easy comparison add 
            const nowInt = hour*60 + min

            for (let i = 0; i < sessions.length; i++){
                let s = sessions[i]
                let endInt = s.end_hour*60 + s.end_min

                if (endInt - nowInt > 0){
                    let startInt = s.start_hour*60 + s.start_min
                    if(startInt - nowInt > 0){
                        return "Opens " + this.convertTo12Hour(s.start_hour, s.start_min)
                    }
                    return "Closes " + this.convertTo12Hour(s.end_hour, s.end_min)
                }
            }
            return null
        },
        convertTo12Hour(hour, min) {
            let amPm = hour < 12 ? 'am' : 'pm'
            let h12 = hour % 12 || 12

            if (min < 10){
                min = "0" + min
            }

            return h12 + ':' + min + ' ' + amPm
        },
        setNextOpenTime(){
            
            let s = this.timings[this.nextOpenInt]
            if(s){
                let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
                if(this.nextOpenInt == this.todayInt){
                    this.displayText = "Opens Next " + days[this.nextOpenInt] + ' ' + this.convertTo12Hour(s.sessions[0].start_hour, s.sessions[0].start_min)
                }else{
                    this.displayText = "Opens at " + days[this.nextOpenInt] + ' ' + this.convertTo12Hour(s.sessions[0].start_hour, s.sessions[0].start_min)
                }
            }
        },
        hideModal(){
            this.show = false

        },
        showModal(){
            this.show = true
        }
    }
}
</script>
<style scoped>
.icon {
    font-size: 14px;
    vertical-align: middle;
    margin-bottom: 4px;
}
.timings {
    cursor: pointer;
}
.day {
    text-transform: capitalize;
}
#timing-modal {
  position: fixed; /* Stay in place */
  z-index: 10; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
#timing-modal-content{
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}
.close {
    font-weight: bold;
    width: 100%;
    text-align: center;
    height: 3rem;
    border: 2px solid black;
    cursor: pointer;
}
</style>