<template>
    <div class="footer full-width2">
        <a href="/plans">Plans</a>
        <a href="/careers">Careers</a>  
        <a href="/contact">Contact</a>  
        <a href="/terms" target="_blank">Terms & Conditions</a>  
        <a href="/privacy" target="_blank">Privacy Policy</a>
        <a href="/refund" target="_blank">Cancellation & Refund Policy</a>
        <a href="https://merchant.razorpay.com/policy/N1hQo0CsAEprJx/shipping" target="_blank">Shipping Policy</a>
        <div class="spacer"></div>
        <div class="spacer"></div>
        <div class="company">Veisalgia Healthcare LLP</div>
        <div class="company">Address: 260, 5th Main, 4th Block, Jayanagar, Bangalore, Karnataka 560011</div>
        <div class="company">Phone: +918971830061 | Email: suhrt@stat.org.in</div>
    </div>

</template>
<style scoped>
.company {
    color: grey;
}
.spacer {
    height: 1rem;
}
.full-width2 {
  width: 100vw !important;
  margin-left: 50%;
  transform: translateX(-50%);
}
.footer {
    padding: 4rem;
    width: 100%;
    /* border-top: 2px solid black; */
    margin-top: 4rem;
    background-color: #ececec;
    text-align: center;
}
a {
    margin: 20px;
    font-weight: bold;
    color: blue;
    text-decoration: none;
    font-size: 1rem;
}
@media screen and (max-width: 600px) {
    .footer {
        padding: 18px;
    }
    a {
        margin: 10px;
    }
}

</style>