<template>
    <div v-if="taxInfo" class="tax-info">{{ taxInfo }}</div>
    <div class="table-container">
    <table class="bill">
        <tr>
            <th></th>
            <th>Item</th>
            <th class="center-text">Qty</th>
            <th class="center-text">Rate</th>
            <th class="center-text">Tax</th>
            <th style="text-align: right;">Total</th>
        </tr>
        <tr v-for="(charge, index) in charges" v-bind:key="charge.id" class="charge">
            <td class="center-text bold">{{ index + 1 }}</td>
            <td class="bold">{{ charge.name }}<div v-if="charge.taxed" class="item-tax">{{ charge.tax_name }} @{{ charge.tax_percent }}%</div></td>
            <td class="center-text">{{ charge.quantity }}</td>
            <td class="center-text">{{ charge.currency }}{{ displayRate(charge) }}</td>
            <td class="center-text">{{ charge.currency }}{{ displayTax(charge) }}</td>
            <td style="text-align: right;">{{ charge.currency }}{{ charge.total }}</td>
        </tr>
        <tr>
            <td colspan="6" class="display-amount">Amount Payable
                {{ displayAmount }}
            </td>
        </tr>
    </table>
</div>
</template>
<script>
export default {
    props: ["rx"],
    computed: {
        taxInfo(){
            return this.rx.doctor.tax_info
        },
        displayAmount(){
            return this.rx.order.displayAmount
        },
        charges(){
            return this.rx.charges
        }
    },
    methods: {
        displayRate(charge){
            if(charge.tax_inclusive){
                let total = parseFloat(charge.amount)
                let taxPercent = parseFloat(charge.tax_percent)
                let preTax = total / (1 + taxPercent / 100);
                return preTax.toFixed(2) 
            }else {
                return parseFloat(charge.amount).toFixed(2)
            }
        },
        displayTax(charge){
            if(charge.tax_inclusive){
                let total = parseFloat(charge.amount)
                let taxPercent = parseFloat(charge.tax_percent)
                let preTax = total / (1 + taxPercent / 100);
                let tax = total - preTax
                return tax.toFixed(2)
            }else{
                let total = parseFloat(charge.amount)
                let taxPercent = parseFloat(charge.tax_percent)
                let tax = (taxPercent/100)*total
                return tax.toFixed(2)
            }
        }
    }
}
</script>
<style scoped>
.tax-info {
    margin-top: 1rem;
}
.bill {
    border: 0;
    margin: 0 auto;
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.bill th, .bill td {
    padding: 4px;
    vertical-align: top;
}

th {
    text-align: left;
    overflow-y: hidden;
    background-color: #4e4e4ead;
    color: white;
    font-weight: bold;
}
.bill tr:nth-child(odd) {
    background-color: rgba(125, 125, 125, 0.2);
}
.center-text {
    text-align: center;
}

.bold {
    font-weight: bold;
}
.display-amount {
    text-align: right; 
    font-weight: bold; 
    color: #9E0000;
}

.item-tax {
    font-weight: normal;
}
@media only screen and (max-width: 768px) {
.bill th, .bill td {
    padding: 2px;
}

.bill {
    font-size: 14px;
}

}
</style>