<template>
    <table class="work-table">
        <tbody>
            <tr v-for="day in timings" v-bind:key="day.day">
                <td class="day">{{ titlecase(day.day) }}</td>
                <td>
                    <div v-if="day.selected">
                        <div v-for="s in day.sessions" v-bind:key="s.start_hour">
                            <div>{{ modalTime(s) }}</div>
                        </div>
                    </div>
                    <div v-else>
                        <span class="closed">Closed</span>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</template>
<script>
export default {
    props: ["timings"],
    methods: {
        modalTime(s){
            return this.convertTo12Hour(s.start_hour, s.start_min) + " - " + this.convertTo12Hour(s.end_hour, s.end_min)
        },
        titlecase(s){
              let firstLetter = s[0]
              return firstLetter.toUpperCase() + s.toLowerCase().substring(1)
        },
        convertTo12Hour(hour, min) {
            let amPm = hour < 12 ? 'am' : 'pm'
            let h12 = hour % 12 || 12

            if (min < 10){
                min = "0" + min
            }

            return h12 + ':' + min + ' ' + amPm
        },
    }
}
</script>
<style scoped>
.closed{
    color: red;
}
.work-table {
    margin: 0 auto;
    border-spacing: 16px;
}
.work-table td {
    vertical-align: top;
}
</style>