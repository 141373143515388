<template>
    <p v-if="disclaimer">{{ disclaimer }}</p>
    <img v-if="imageUrl" :src="imageUrl" class="footer-img" />
</template>
<script>
export default {
    props: ['imageUrl', 'disclaimer'],
}
</script>
<style scoped>
.footer-img {
    margin: 0;
    padding: 0;
    width: 100%;
    margin-bottom: -5px;
}
p {
    font-size: 14px;
    margin-bottom: 3.5px;
}
</style>