<template>
    <div class="logo-header">
        <img :src="logoUrl" class="logo" />
        <div class="work-details">
            <div class="logo-work-name custom-color">{{ activeWork.name }}</div>
            <div class="address">
                <a :href="googleMapUrl" class="address" target="_blank">{{ activeWork.address }}
                    <span class="link-highlight custom-color">Get&nbsp;Directions&nbsp;<span
                            class="icon">directions</span></span>
                </a>
            </div>
            <div class="local-spacer"></div>
            <div class="time-and-phone">
                <div v-if="activeWork.phone_number">
                    <a :href="phoneUrl" class="phone"><span class="icon">phone</span> {{ activeWork.phone_number }}</a>
                </div>
                <div class="time">
                    <DisplayTimings :sessiontimings="activeTimings"></DisplayTimings>
                </div>
            </div>

        </div>
    </div>
</template>
<style scoped>
th {
    font-weight: normal;
}
.time-and-phone {
    display: flex;
    column-gap: 16px;
}
.local-spacer {
    height: 4px;
}

.icon {
    font-size: 14px;
    vertical-align: middle;
    margin-bottom: 4px;
}

.work-details {
    font-weight: normal;
    padding-top: 8px;
    font-size: 16px;
    line-height: 1.2;
}

.logo-work-name {
    font-family: 'Zodiak-Bold';
    font-size: 24px;
    color: #253D63FF;
}

.logo-header {
    width: 100%;
    padding: 8px;
    padding-bottom: 12px;
    margin: 0;
    display: flex;
    column-gap: 8px;
    align-items: start;
    border-bottom: 2px dotted #3a3a3a;
}

.logo {
    max-width: 100px;
    max-height: 100px;
}

.work-details {
    text-align: left;
}

.address, .phone {
    text-decoration: none;
    color: #3a3a3a;
    margin-top: 2px;
}
</style>
<script>
import DisplayTimings from './DisplayTiming.vue'

export default {
    components: {
        DisplayTimings
    },
    props: ["doctor"],
    data() {
        return {
            activeWork: "",
            googleMapUrl: "",
            phoneUrl: "",
            activeTimings: "",
            logoUrl: this.doctor.customizations.logo_url
        }
    },
    created() {
        for (let i = 0; i < this.doctor.work.length; i++) {
            if (this.doctor.work[i].is_active) {
                this.activeWork = this.doctor.work[i]
                this.googleMapUrl = "https://maps.google.com/?q=" + this.activeWork.latitude + "," + this.activeWork.longitude
                this.phoneUrl = "tel:" + this.activeWork.phone_number
                this.activeTimings = this.activeWork.timings
                break
            }
        }
    },
}
</script>
