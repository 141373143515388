<template>
    <div class="content">
        <div class="header">
            <div class="title custom-color" style="color: rgb(11, 98, 241);">Dr. Anandibai G Joshi</div>
            <div class="rx-bold custom-color" style="color: rgb(11, 98, 241);">MBBS, M.D Medicine</div>
            <div class="one-line-height"></div>
            <div>
                <div class="rx-bold custom-color" style="color: rgb(11, 98, 241);">Consultant Surgeon </div>
                <a href="https://maps.google.com/?q=12.928246041750844,77.59950902312994" target="_blank"
                    class="rx-text">
                    <div class="work-address">Sagar Hospital, Jayanagar 4th block, Bangalore - Swagath Road, SR
                        Krishnappa Garden, Hombegowda Nagar <span class="link-highlight">Get&nbsp;Directions&nbsp;<span
                                class="icon">directions</span></span></div>
                </a>
                <div class="one-line-height"></div>
                <a href="tel:08042888888" class="rx-text"><span class="icon">phone</span> 08042888888</a>
                <div class="rx-text timings-holder" id="one-open-button"><span class="icon">schedule</span> <span
                        id="oneTiming">Next: Wed 8:00 AM</span> <span class="icon">expand_more</span></div>
                <div class="one-full-timings modal" id="one-timing-modal">
                    <div class="modal-content">
                        <table class="work-table">
                            <tbody>
                                <tr style="background-color: white;">
                                    <td class="rx-text">SUNDAY</td>
                                    <td>
                                        <div id="one-SUNDAY" class="rx-text">Closed</div>
                                    </td>
                                </tr>
                                <tr style="background-color: white;">
                                    <td class="rx-text">MONDAY</td>
                                    <td>
                                        <div id="one-MONDAY" class="rx-text"> 8:00 AM - 12:00 PM</div>
                                    </td>
                                </tr>
                                <tr style="background-color: white;">
                                    <td class="rx-text">TUESDAY</td>
                                    <td>
                                        <div id="one-TUESDAY" class="rx-text"> 8:00 AM - 12:00 PM</div>
                                    </td>
                                </tr>
                                <tr style="background-color: white;">
                                    <td class="rx-text">WEDNESDAY</td>
                                    <td>
                                        <div id="one-WEDNESDAY" class="rx-text"> 8:00 AM - 12:00 PM</div>
                                    </td>
                                </tr>
                                <tr style="background-color: white;">
                                    <td class="rx-text">THURSDAY</td>
                                    <td>
                                        <div id="one-THURSDAY" class="rx-text"> 8:00 AM - 12:00 PM</div>
                                    </td>
                                </tr>
                                <tr style="background-color: white;">
                                    <td class="rx-text">FRIDAY</td>
                                    <td>
                                        <div id="one-FRIDAY" class="rx-text"> 8:00 AM - 12:00 PM</div>
                                    </td>
                                </tr>
                                <tr style="background-color: white;">
                                    <td class="rx-text">SATURDAY</td>
                                    <td>
                                        <div id="one-SATURDAY" class="rx-text">Closed</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="dotted-line"></div>
        <div class="watermark">
            <div class="custom-watermark"></div>
            <div class="patient-info">
                <div class="text-bold">K L Ravi Kumar · 60 Years · Male<div class="date-placeholder rx-date"></div>
                </div>
                <div class="one-line-height"></div>
                <div class="one-line-height"></div>
            </div>
            <div class="one-line-height"></div>
            <div class="one-line-height"></div>
            <div class="rx-text"><strong>Complaints </strong>Cough with fever x 5 days</div>
            <div class="one-line-height"></div>
            <div class="rx-text"><strong>Vitals</strong></div>
            <div class="vitals">
                <div class="vital rx-text">Pulse Rate: 90 bpm</div>
                <div class="vital rx-text">Pulse Pattern: Regular </div>
                <div class="vital rx-text">BP: 146/88 mmHg</div>
                <div class="vital rx-text">Resp Rate: 22 cycles/min</div>
                <div class="vital rx-text">Resp Pattern: Regular </div>
                <div class="vital rx-text">Temp: 38 Celsius</div>
                <div class="vital rx-text">SpO2: 90 %</div>
                <div class="vital rx-text">RBS: 110 %</div>
            </div>
            <div class="one-line-height"></div>
            <div class="rx-text"><strong>Examination </strong>B/L Ronchi +</div>
            <div class="one-line-height"></div>
            <div class="rx-text"><span
                    style="background-color: rgb(255, 216, 20); font-weight: bold; padding: 2px 4px;">Diagnosis</span>
                Fever for evaluation </div>
            <div class="one-line-height"></div>
            <img src="../assets/rx-black.png" style="width: 35px; margin-top: 14px;">
            <div style="height: 14px;"></div>
            <div class="grid drug">
                <div class="row">
                    <div class="text-bold cell">1.&nbsp;</div>
                    <div class="rx-text cell-max">
                        <div><strong>Augmentin 625 Duo Tablet</strong></div>
                        <div class="rx-text generic">Amoxycillin (500mg) + Clavulanic Acid (125mg)</div>

                        <div class="rx-text"> 1 tablet - 0 - 1 tablet for 5 Days </div>

                        <div class="rx-text">Instructions: Take on empty stomach</div>
                    </div>
                </div>
                <div class="row">
                    <div class="text-bold cell">2.&nbsp;</div>
                    <div class="rx-text cell-max">
                        <div><strong>Crocin Advance Tablet</strong></div>
                        <div class="rx-text generic">Paracetamol (500mg)</div>

                        <div class="rx-text"> 1 tablet when required for 5 Days </div>

                        <div class="rx-text">Instructions: After food</div>
                    </div>
                </div>
                <div class="row">
                    <div class="text-bold cell">3.&nbsp;</div>
                    <div class="rx-text cell-max">
                        <div><strong>Zivinix-C Chewable Tablet</strong></div>
                        <div class="rx-text generic">Vitamin C (400mg) + Zinc Sulfate (7.5mg)</div>

                        <div class="rx-text"> 1 tablet - 0 - 0 for 14 Days </div>

                    </div>
                </div>
            </div>
            <div style="height: 14px;"></div>
            <div class="text-bold">Investigations</div>
            <div class="one-line-height"></div>
            <div class="grid">
                <div class="row">
                    <div class="text-bold cell">1.&nbsp;</div>
                    <div class="rx-text cell-max"><strong>CxR P/A View</strong>
                        <div class="half-line-height"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="text-bold cell">2.&nbsp;</div>
                    <div class="rx-text cell-max"><strong>CBC, LFT, RFT, Urine routine &amp;
                            microscopy</strong><br>Components: Complete Blood Counts, Liver Funtion Tests, Renal
                        Function Tests, Urine routine &amp; microscopy<br>Instructions: Empty stomach sample is not
                        necessary.<div class="half-line-height"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="text-bold cell">3.&nbsp;</div>
                    <div class="rx-text cell-max"><strong>COVID-19 RT PCR</strong>
                        <div class="half-line-height"></div>
                    </div>
                </div>
            </div>
            <div class="one-line-height"></div>
            <div class="rx-text"><strong>Advice </strong>Review with reports </div>
            <br>
            <div class="signature">
                <div class="signature-container">
                    <div class="signature-name" style="font-family: 'Allison';">Anandibai G J</div>
                    <div class="signature-text">Dr. Anandibai G Joshi</div>
                    <div class="signature-text">MBBS, MD Medicine</div>
                    <div class="signature-text">MCI 110008</div>
                </div>
            </div>
            <br>
            <footer>
                <br>
                <div class="dotted-line"></div>
                <br>
                <div class="rx-text center-text">Prescription generate with STAT</div>
                <div class="rx-text center-text"><a href="" class="link-highlight">Terms &amp; Conditions</a> · <a
                        href="" class="link-highlight">Privacy Policy</a> </div>

                <br>
            </footer>
        </div>
    </div>
</template>
<script>
export default {
    mounted() {
        let t = '[{\u0022ID\u0022:\u0022000000000000000000000000\u0022,\u0022day\u0022:\u0022SUNDAY\u0022,\u0022selected\u0022:false,\u0022sessions\u0022:null},{\u0022ID\u0022:\u0022000000000000000000000000\u0022,\u0022day\u0022:\u0022MONDAY\u0022,\u0022selected\u0022:true,\u0022sessions\u0022:[{\u0022ID\u0022:\u0022000000000000000000000000\u0022,\u0022start_hour\u0022:8,\u0022start_min\u0022:0,\u0022end_hour\u0022:12,\u0022end_min\u0022:0}]},{\u0022ID\u0022:\u0022000000000000000000000000\u0022,\u0022day\u0022:\u0022TUESDAY\u0022,\u0022selected\u0022:true,\u0022sessions\u0022:[{\u0022ID\u0022:\u0022000000000000000000000000\u0022,\u0022start_hour\u0022:8,\u0022start_min\u0022:0,\u0022end_hour\u0022:12,\u0022end_min\u0022:0}]},{\u0022ID\u0022:\u0022000000000000000000000000\u0022,\u0022day\u0022:\u0022WEDNESDAY\u0022,\u0022selected\u0022:true,\u0022sessions\u0022:[{\u0022ID\u0022:\u0022000000000000000000000000\u0022,\u0022start_hour\u0022:8,\u0022start_min\u0022:0,\u0022end_hour\u0022:12,\u0022end_min\u0022:0}]},{\u0022ID\u0022:\u0022000000000000000000000000\u0022,\u0022day\u0022:\u0022THURSDAY\u0022,\u0022selected\u0022:true,\u0022sessions\u0022:[{\u0022ID\u0022:\u0022000000000000000000000000\u0022,\u0022start_hour\u0022:8,\u0022start_min\u0022:0,\u0022end_hour\u0022:12,\u0022end_min\u0022:0}]},{\u0022ID\u0022:\u0022000000000000000000000000\u0022,\u0022day\u0022:\u0022FRIDAY\u0022,\u0022selected\u0022:true,\u0022sessions\u0022:[{\u0022ID\u0022:\u0022000000000000000000000000\u0022,\u0022start_hour\u0022:8,\u0022start_min\u0022:0,\u0022end_hour\u0022:12,\u0022end_min\u0022:0}]},{\u0022ID\u0022:\u0022000000000000000000000000\u0022,\u0022day\u0022:\u0022SATURDAY\u0022,\u0022selected\u0022:false,\u0022sessions\u0022:null}]';
        let oneTimings = JSON.parse(t);
        let openNow = isOpen(oneTimings);
        let oneDisplayString = displayTimings(oneTimings);
        document.getElementById('oneTiming').innerHTML = oneDisplayString;
        for (let i = 0; i < oneTimings.length; i++) {
            let timingString = to12Hour(oneTimings[i].sessions);
            document.getElementById('one-' + oneTimings[i].day).innerHTML = to12Hour(oneTimings[i].sessions);
        }
        let oneModal = document.getElementById('one-timing-modal');
        let oneOpenButton = document.getElementById('one-open-button');
        oneOpenButton.onclick = function () {
            oneModal.style.display = "block";
        }
        window.onclick = function (event) {
            if (event.target == oneModal) {
                oneModal.style.display = "none";
            }
        }
        function hasTiming(timings) {
            let _hasTimings = false;
            for (let i = 0; i < timings.length; i++) {
                if (timings[i].selected) {
                    _hasTimings = true;
                    break;
                }
            }
            return _hasTimings;
        }
        function isOpen(timings) {
            let _isOpen = false;
            let _now = new Date();
            let _today = _now.getDay();
            if (!timings[_today].selected) {
                return false;
            }
            for (let i = 0; i < timings[_today].sessions[i]; i++) {
                let _timings = timings[_today].sessions[i];
                let _end = new Date();
                _now.setHours(_timings.end_hour);
                _now.setMinutes(_timings.end_minute);
                let _hasNotEnded = _now < _end;
                if (_hasNotEnded) {
                    _isOpen = true;
                    break;
                }
            }
            return _isOpen;
        }

        function nextOpenDay(timings) {
            let _openDays = [];
            for (let i = 0; i < timings.length; i++) {
                if (timings[i].selected) {
                    _openDays.push(i);
                }
            }
            if (_openDays.length == 0) {
                return -1;
            }
            let _today = new Date().getDay();
            for (let i = 1; i + _today < 7; i++) {
                if (_openDays.includes(_today + i)) {
                    return _today + i;
                }
            }
            return _openDays[0];
        }

        function displayTimings(timings) {
            let _hasTiming = hasTiming(timings);
            if (!_hasTiming) {
                return '';
            }
            let _isOpenToday = isOpen(timings);
            let now = new Date();
            let _today = now.getDay();
            if (_isOpenToday) {
                let displayString = 'Today: ';
                for (var i = 0; i < timings[_today].sessions.length; i++) {
                    now.setHours(timings[_today].sessions[i].start_hour);
                    now.setMinutes(timings[_today].sessions[i].start_min);
                    let startString = now.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
                    now.setHours(timings[_today].sessions[i].end_hour);
                    now.setMinutes(timings[_today].sessions[i].end_min);
                    let endString = now.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
                    displayString += startString + ' - ' + endString + '  ';
                }
                return displayString;
            }
            let _nextOpenDay = nextOpenDay(timings);
            if (_nextOpenDay != -1) {
                let displayString = 'Next: ';
                var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
                displayString += days[_nextOpenDay] + ' ';
                now.setHours(timings[_nextOpenDay].sessions[0].start_hour);
                now.setMinutes(timings[_nextOpenDay].sessions[0].start_min);
                let startString = now.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
                displayString += startString;
                return displayString;
            }
            return 'Call for appointments';
        }
        function to12Hour(sessions) {
            let _timings = ' ';
            let now = new Date();
            if (!sessions) {
                return 'Closed';
            }
            for (let i = 0; i < sessions.length; i++) {
                now.setHours(sessions[i].start_hour);
                now.setMinutes(sessions[i].start_min);
                let startString = now.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
                now.setHours(sessions[i].end_hour);
                now.setMinutes(sessions[i].end_min);
                let endString = now.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
                _timings += startString + ' - ' + endString;
                if (i < sessions.length - 1) {
                    _timings += '\n';
                }
            }
            return _timings;
        }
    }
}
</script>
<style scoped>
.mobile-navbar {
    display: flex;
    position: fixed;
    bottom: 0;
    gap: 5px;
    padding-bottom: 30px;
    right: 0;
    left: 0;
    display: block;
    text-align: center;
}

.button, .button * {
    overflow-y: hidden;
}
.button {
    background-color: #1652F0;
    padding: 10px;
    border-radius: 0;
    color: white;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    transition: all 250ms;
    border: 0;
    user-select: none;
    font-size: 16px;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-top: 5px;
}
.button:hover {
    box-shadow: rgba(44, 187, 99, .35) 0 -25px 18px -14px inset, rgba(44, 187, 99, .25) 0 1px 2px, rgba(44, 187, 99, .25) 0 2px 4px, rgba(44, 187, 99, .25) 0 4px 8px, rgba(44, 187, 99, .25) 0 8px 16px, rgba(44, 187, 99, .25) 0 16px 32px;
    transform: scale(1.05);
}
.rx-date {
    font-weight: normal;
    float: right;
}
.icon,
.link-highlight {
    color: rgb(11, 98, 241) !important;
}
.signature {
    text-align: right;
}
.signature-container {
    display: inline-block;
    text-align: center;
    margin-right: 24px;
}
.signature-text {
    font-size: 13px;
    font-family: 'Zodiak-Extrabold';
    color: #253D63FF;
    line-height: 1.2;
    overflow-y: hidden;
}
.signature-name {
    font-size: 14px;
    line-height: 3.5;
}

.drug {
    gap: 12px;
}
.vitals {
    display: flex;
    flex-wrap: wrap;
    max-width: 400px;
}
.vital {
    width: 50%;
}

.bold {
    font-weight: bold;
}

.bold-Atkinson Hyperlegible {
    font-weight: bold;
    font-family: 'Atkinson Hyperlegible';
}

body {
    background-color: #e7f0ed;
    font-size: 16px;
    padding: 4px;
    color: #4e4e4e;
    line-height: 1;
}


.alert-box {
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
    background-color: #ffeeba;
    margin-bottom: 4px;
    padding: 8px;
}

.invoice {
    font-family: 'Atkinson Hyperlegible';
    font-weight: bold;
}

.invoice-instructions {
    font-family: 'Atkinson Hyperlegible';
    line-height: 1.2;
}


.content, .content-center {
    max-width: 650px;
    margin: 0 auto;
    background-color: rgb(255, 255, 255);
    padding: 9px;
    position: relative;
    text-align: left;
    border: 2px solid grey;
    font-family: "Atkinson Hyperlegible", sans-serif;
}

a {
    text-decoration: none;
}

.title, .appointment-title {
    font-family: 'Zodiak-Extrabold';
    line-height: 1.2;
    overflow-y: hidden;
    color: #253D63FF;
}

.appointment-title {
    font-size: 2rem;
}

.appointment-subtitle{
    font-size: 1.2rem;
}

.rx-bold, .appointment-subtitle {
    font-family: 'Zodiak-Extrabold';
    line-height: 1.2;
    overflow-y: hidden;
    color: #253D63FF;
    font-size: 14px;
}

.rx-text {
    font-family: 'Atkinson Hyperlegible';
    line-height: 1.2;
    overflow-y: hidden;
    font-size: 14px;
    color: #4e4e4e;
    text-decoration: none;
}

.text-bold {
    font-family: 'Atkinson Hyperlegible';
    font-weight: bold;
    line-height: 1.2;
    overflow-y: hidden;
    font-size: 14px;
}

.closed {
    color: red;
}



.link-highlight {
    color: #4267B2;
    white-space: pre;
}

.one-line-height {
    height: 8px;
}

.two-line-height {
    height: 16px;
}

.three-line-height {
    height: 24px;
}

.one-line-height+.one-line-height,
.half-line-height+.half-line-height,
.one-line-height+.half-line-height,
.half-line-height+.one-line-height {
    display: none;
}

.half-line-height {
    height: 4px;
}

.desktop,
.desktop-inline {
    display: none;
}

.desktop-inline {
    overflow-y: hidden;
}

.mobile {
    display: block;
}

.mobile-inline {
    display: inline;
}

.timings-holder {
    display: inline-block;
    margin-left: 6px;
    margin-bottom: -4px;
    cursor: pointer;
}

table {
    border: none;
    border-spacing: 5px;
    margin: 0 auto;
}

.work-table tr{
    background-color: white;
}

.work-table td {
    border: none;
    padding: 0 !important;
    margin: 0 !important;
}

td {
    border: none;
    overflow-y: hidden;
}

th {
    text-align: left;
    overflow-y: hidden;
    background-color: #4e4e4ead;
    color: white;
    font-weight: bold;
}

th.rx-text {
    color: white;
}

.td-50 {
    width: 55%;
}

.bill {
    width: 100%;
    border-collapse: collapse;
}

.bill th,
.bill td {
    padding: 4px;
}

.top-align {
    vertical-align: text-top;
}

.center-text {
    text-align: center;
}

.bill tr:nth-child(odd) {
    background-color: rgba(125, 125, 125, 0.2);
}

.grey {
    color: #717171;
}

.payment-button {
    border: 0;
    font-family: 'Atkinson Hyperlegible';
    font-size: 16px;
    background: rgb(255, 216, 20);
    padding: 8px 32px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    width: 99%;
    transition: 0.3s;
    position: relative;
    z-index: 10;
}

.payment-button:hover {
    background: rgb(255, 185, 20);
}

.payment-contianer {
    text-align: center;
}

/* The Modal (background) */
.modal {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 650px;
    /* Could be more or less, depending on screen size */
}

.dotted-line {
    border-bottom: 2px dotted;
    margin-top: 4px;
    margin-bottom: 8px;
}

.grid {
    display: grid;
    gap: 7px 7px;
    margin-left: 14px;
}

.row {
    display: flex;
    width: 100%;
}

.cell {
    min-width: 16px;
}

.cell-max {
    flex-grow: 1;
}

#uploading{
    display: none;
    position: absolute;
    height: 100%;
    width:  100%;
    background-color: rgb(255 197 61 / 91%);
    top: 0;
    left: 0;
    text-align: center;
    padding-top: 45vh;
}

.uploading-text{
    color: rgb(71, 71, 71);
    font-weight: bold;
    font-family: 'Atkinson Hyperlegible', sans-serif;
}

.uploading-sub-text{
    font-weight: bold;
    font-family: 'Atkinson Hyperlegible', sans-serif;
}

.grey {
    color: grey;
}

.generic{
    font-family: 'Zodiak-Italic';
    font-weight: bold;
    font-size: 13px;
}

.watermark {
        padding-left: 14px;
        padding-right: 27px;
        background: url('../assets/prescription_background.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }

@media only screen and (min-width: 768px) {
    .desktop {
        display: block;
    }

    .desktop-flex{
        display: flex;
        justify-content: center;
    }

    .header {
        padding: 5px 10px 0 10px;
    }

    .desktop-inline {
        display: inline-block;
    }

    .watermark {
        /* padding-left: 14px; */
        padding-right: 27px;
        background: url('../assets/prescription_background.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }

    .mobile,
    .mobile-inline {
        display: none;
    }

    /* .content {
        margin-top: 28px;
        margin-right: 28px;
    } */

    .content-center {
        margin-top: 28px;
    }
}

</style>