<template>
  <div v-if="!showRx && showInvoice">
    <CompletePayment />
  </div>
  <div v-if="prescription">
    <table class="rx-container" v-if="showRx">
      <thead class="rx-header">
        <tr>
          <th class="rx-header-cell">
            <div class="header-info">
              <RxHeader :prescription="prescription" />
            </div>
          </th>
        </tr>
      </thead>
      <tfoot class="rx-footer">
        <tr>
          <td class="rx-footer-cell">
            <div>
              <RxFooter :prescription="prescription" />
            </div>
          </td>
        </tr>
      </tfoot>
      <tbody class="rx-content">
        <tr>
          <td class="rx-content-cell" id="watermark-area">
            <div class="watermark-img-hodler">
            <img class="watermark" :src="watermarkUrl">
          </div>
            <div class="main">
              <RxContent :rx="prescription" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="rx-container" v-if="showInvoice">
      <thead class="rx-header">
        <tr>
          <th class="rx-header-cell">
            <div class="header-info">
              <RxHeader :prescription="prescription" />
            </div>
          </th>
        </tr>
      </thead>
      <tfoot class="rx-footer">
        <tr>
          <td class="rx-footer-cell">
            <div>
              <RxFooter :prescription="prescription" />
            </div>
          </td>
        </tr>
      </tfoot>
      <tbody class="rx-content">
        <tr>
          <td class="rx-content-cell" id="watermark-area">
            <div class="watermark-img-hodler">
            <img class="watermark" :src="watermarkUrl">
          </div>
            <div class="main">
              <InvoiceBody :rx="prescription" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="print-footer">
      <RxFooter :prescription="prescription" />
    </div>
    <RxMenu :rx="prescription"/>
  </div>
</template>
<style scoped>
.print-footer {
  display: none;
}
.rx-container {
  width: 100%;
  max-width: 650px;
  min-height: 1000px;
  height: 100%;
  margin: 0 auto;
  margin-bottom: 5px;
  background-color: white;
  box-shadow:
    0 1px 1px hsl(0deg 0% 0% / 0.075),
    0 2px 2px hsl(0deg 0% 0% / 0.075),
    0 4px 4px hsl(0deg 0% 0% / 0.075),
    0 8px 8px hsl(0deg 0% 0% / 0.075),
    0 16px 16px hsl(0deg 0% 0% / 0.075);
  border-collapse: collapse;
}


#watermark-area {
  z-index: 2;
  position: relative;
}
.main {
  z-index: 4;
}

.watermark-img-hodler {
  position: absolute;
  height: 100%;
}

.watermark {
  position: sticky;
  z-index: 1;
  width: 100%;
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  opacity: 0.1;
  left: 0;
  right: 0;
  max-width: 650px;
  padding: 50px 0;
  top: 0;
}

.rx-content-cell {
  vertical-align: top;
  text-align: left;
  padding: 0 16px 16px 16px;
}

@media only screen and (max-width: 768px) {
  .rx-content-cell {
    padding: 8px;
  }
}
</style>

<style>
@media print {
  
  .hide-print {
    display: none !important;
  }
  @page {
    size: A4 portrait;
    margin: 0;
  }

  body,
  html {
    height: auto;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
    page-break-after: avoid;
    page-break-before: avoid;
  }
  

  .rx-container {
    box-shadow: none !important;
    max-width: 100% !important;
  }
  
  #app,
  .content {
    padding: 0 !important;
    background-color: white !important;
    height: 100%;
  }

  .rx-container {
    box-shadow: none !important;
    max-width: 100% !important;
    width: 100% !important;
  }
 

  .print-footer {
    display: block !important;
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .rx-content {
    position: relative;
  }
  .watermark-img-hodler {
    position: relative;
  }
  .watermark {
    position: fixed !important;
    width: 100% !important;
    max-width: auto !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
  thead {
    display: table-header-group !important;
  }
  tfoot {
    display: table-footer-group;
    visibility: hidden;
  }
}
</style>
<script>
import RxHeader from "@/components/prescription/RxHeader.vue"
import RxFooter from "@/components/prescription/RxFooter.vue"
import RxContent from "@/components/prescription/content/RxContent.vue"
import InvoiceBody from "@/components/prescription/invoice/InvoiceBody.vue"
import CompletePayment from "@/components/prescription/invoice/CompletePayment.vue"
import RxMenu from "@/components/prescription/menu/RxMenu.vue"

export default {
  components: {
    RxHeader,
    RxFooter,
    RxContent,
    InvoiceBody,
    CompletePayment,
    RxMenu
  },
  async created() {
    var url_string = window.location.href; 
    var u = new URL(url_string);
    var pdfKey = u.searchParams.get("pdf-key");


    const url = this.baseUrl + '/rx-json'
    // const url = "http://localhost:8080/rx-json"
    let requestOptions = {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        "id": this.id,
        "password": this.password,
        "pdf-key": pdfKey
      })
    }

    let response = await fetch(url, requestOptions)

    if (response.status > 300) {
      let url = '/rx/' + this.id
      localStorage.setItem("wrong", this.password);
      this.$router.push(url)
      return
    }

    localStorage.removeItem("wrong");
    this.prescription = await response.json()
    

    if(this.prescription.charges){
     if(this.prescription.charges.length > 0){
      this.showInvoice = true
      if (!this.prescription.order.paymentComplete  && this.prescription.doctor.country_code.includes("91")) {
        this.showRx = false
      }
      if(pdfKey){
        this.showRx = true
      }
     }
    }

    // set the logo url
    if (this.prescription.doctor.membership.pro_status > 1) {
      let logoUrl = this.prescription.doctor.customizations.logo_url
      if (logoUrl) {
        this.watermarkUrl = logoUrl
      }
      
    }
  },
  mounted(){
    let app = document.getElementById('app')
        if (app) {
          app.style.backgroundColor = "#e7f0ed"
          app.style.padding = "5px";
          app.style.height = "auto";
          app.style.minHeight = "100%";
        }
  },
  data() {
    return {
      prescription: null,
      watermarkUrl: require("../../assets/rx_bg.png"),
      watermarkOpacity: 1,
      showRx: true,
      showInvoice: false,
    }
  },
  computed: {
    baseUrl: {
      get() {
        return this.$store.state.baseUrl
      }
    },
    id() {
      return this.$route.params.id
    },
    password() {
      let p = this.$route.params.password
      let parts = p.split('?')
      return parts[0]
    },
  },
}
</script>