<template>
  <div class="about" style="max-width: 750px; margin: 0 auto; text-align: left; line-height 1.5; padding: 16px;">
    <h1>About</h1>
    <p>Founded in 2019 by Dr. Suhrt K R and Dr. Sankalpa L amidst the COVID-19 pandemic, STAT has quickly become a leading healthcare technology startup. Born as a bootstrap venture in Bangalore, India, our mission is to simplify healthcare through cutting-edge solutions.
</p>
<br>
<p>Focused on the needs of healthcare practitioners, STAT provides intuitive practice management and prescription software. We empower doctors with efficient tools, recognizing the crucial role of technology in modern healthcare.</p>
<br>
<p>Join us on this transformative journey at the intersection of healthcare and technology. Welcome to STAT – where healthcare meets innovation!</p>
  </div>
</template>





