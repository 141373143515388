<template>
    <div class="faq" @click="showHide">
        <div class="question">{{ qa.q }}<span v-if="expanded" class="material-symbols-outlined">expand_less</span><span
                v-else class="material-symbols-outlined">expand_more</span></div>
            <div class="answer" v-if="expanded">{{ qa.a }}</div>
    </div>
</template>
<script>
export default {
    props: ['qa'],
    data() {
        return {
            expanded: false
        }
    },
    methods: {
        showHide() {
            this.expanded = !this.expanded
        }
    }
}
</script>
<style scoped>
.faq {
    margin-top: 2rem;
    max-width: 500px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 2rem;
    border-bottom: 1px dotted black;
}

.answer {
    cursor: pointer;
}
.question {
    font-weight: bold;
    color: #759ef6;
    position: relative;
    cursor: pointer;
}

.material-symbols-outlined {
    display: inline-block;
    font-size: 24px;
    line-height: 1;
    position: absolute;
    right: 0;
}
@media screen and (max-width: 600px) {
    .faq {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}
</style>