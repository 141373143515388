<template>
    <div v-if="paid" class="payed">
        Payment Complete
    </div>
    <div v-else class="pending">
        <button class="payment-button" @click="pay()" v-if="paymentOptionAvaiable">Complete payment & View Prescription</button>
    </div>
</template>
<script>
export default {
    props: ['rx'],
    mounted() {
        // if (this.isMobile) {
        //     let razorpayScript = document.createElement('script')
        //     razorpayScript.setAttribute('src', 'https://checkout.razorpay.com/v1/checkout.js')
        //     document.head.appendChild(razorpayScript)
        //     // eslint-disable-next-line
        //     var rzp1 = new Razorpay(this.razorpayOptions);
        //     rzp1.on('payment.failed', function (response) {
        //         let errorCorde = response.error.code;
        //         let errorDescription = response.error.description;
        //         alert("Payment Failed. Reason: " + errorDescription)
        //     });
        //     document.getElementById('rzp-button1').onclick = function (e) {
        //         rzp1.open();
        //         e.preventDefault();
        //     }
        // }
    },
    methods: {
        async pay(){
            let requestOptions = {
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    "rxId": this.rx.prescription_id
                })
            }
            let url = this.baseUrl + '/phonepe/order'
            let respone = await fetch(url, requestOptions)
            let body = await respone.json()
            let paymentUrl = body.redirect_url
            if(paymentUrl){
                window.location.href = paymentUrl
            }
        }
    },
    computed: {
        paid() {
            if(this.rx.order.paymentComplete){
                return true
            }
            return false
        },
        baseUrl: {
            get() {
                return this.$store.state.baseUrl
            }
        },
        paymentOptionAvaiable(){
            return this.rx.doctor.country_code.includes("91")
        }
        // ptEmail() {
        //     if (this.rx.patient.email) {
        //         return this.rx.patient.email
        //     }
        //     return "payments@stat.org.in"
        // },
        // razorpayOptions() {
        //     return {
        //         "key": "rzp_test_gXZ4ZjHAeFZa2O",
        //         "amount": this.rx.order.amount,
        //         "currency": "INR",
        //         "name": this.rx.doctor.name,
        //         "description": "Payment towards invoice no: " + this.rx.prescription_id,
        //         "order_id": this.rx.order.order_id,
        //         "prefill": {
        //             "name": this.rx.patient.name,
        //             "email": this.ptEmail,
        //             "contact": this.rx.patient.phone_number
        //         },
        //         "theme": {
        //             "color": "#253D63"
        //         },
        //         "handler": function (response) {
        //             let paymentId = response.razorpay_payment_id
        //             let orderId = response.razorpay_order_id
        //             let signature = response.razorpay_signature
        //             let password = ""
        //         },

        //     }
        // }
    }

}
</script>
<style scoped>
.payed {
    width: 100%;
    text-align: center;
    background-color: rgb(0, 90, 0);
    color: white;
    font-weight: bold;
    margin-top: 3rem;
    padding: 16px;
}

.payment-button {
    border: 0;
    margin-top: 3rem;
    padding: 16px;
    font-size: 16px;
    font-weight: bold;
    background: rgb(255, 216, 20);
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    width: 99%;
    transition: 0.3s;
    position: relative;
    z-index: 10;
    font-family: "Atkinson Hyperlegible", sans-serif;
    color: #253D63FF;
}

.payment-button:hover {
    background: rgb(255, 179, 0);
}
</style>