<template>
    <div class="plans">
        <div style="height: 2rem"></div>
        <h1>Go Pro</h1>
        <div style="height: 2rem"></div>
        <p class="go-pro-info">Stat free plan includes everything your require to start a digital practice (patient central, prescriptions,
            invoices and more) and is a great way to <a href="">get started</a>. The following plans allow you to do
            more and grow your practice.</p>
        <div style="height: 6rem"></div>
        <div class="coupon" @click="editCoupon" v-if="!isFetching && !showStripe">Coupon Code <span
                class="code">COCCI</span> applied</div>
        <div class="plan-details" v-if="!isFetching && showStripe">
            <stripe-pricing-table pricing-table-id="prctbl_1NcOblSC3hgcbVhX9ThElaJY"
                publishable-key="pk_live_51NcO0ZSC3hgcbVhX7FuB4inwKyQrOZ6JvbePgUrI6lzUREcdQkGvcttDZofnrqZD87neoPH40oyDw3WnFT3HcwQ000jgLWrS90">
            </stripe-pricing-table>
        </div>
        <div class="plan-details" v-if="!isFetching && !showStripe">
            <div class="planC">
                <div class="heading">
                    <div class="line1">
                        <h2>Plan C</h2>
                        <div @click="showCModal">Learn More</div>
                        <CustomModal :show="showC" v-on:send="close">
                            <template v-slot:content>
                                <p v-for="point in planDetails.planC.points" v-bind:key="point" class="learn-more-content">
                                    {{ point }}</p>
                            </template>
                        </CustomModal>
                    </div>
                    <div class="line2">Desktop + Custom Rx + Digital Signature</div>
                    <div class="popular">Most Popular</div>
                </div>
                <DurationSelection :plan="planC" planName="Plan C"></DurationSelection>
            </div>
            <div class="planB">
                <div class="heading">
                    <div class="line1">
                        <h2>Plan B</h2>
                        <div @click="showBModal">Learn More</div>
                        <CustomModal :show="showB" v-on:send="close">
                            <template v-slot:content>
                                <p v-for="point in planDetails.planB.points" v-bind:key="point" class="learn-more-content">
                                    {{ point }}</p>
                            </template>
                        </CustomModal>
                    </div>
                    <div class="line2">Custom Rx + Digital Signature</div>
                </div>
                <DurationSelection :plan="planB" planName="Plan B"></DurationSelection>
            </div>
            <div class="planA">
                <div class="heading">
                    <div class="line1">
                        <h2>Plan A</h2>
                        <div @click="showAModal">Learn More</div>
                        <CustomModal :show="showA" v-on:send="close">
                            <template v-slot:content>
                                <p v-for="point in planDetails.planA.points" v-bind:key="point" class="learn-more-content">
                                    {{ point }}</p>
                            </template>
                        </CustomModal>
                    </div>
                    <div class="line2">Digital Signature</div>
                </div>
                <DurationSelection :plan="planA" planName="Plan A"></DurationSelection>
            </div>
        </div>
        <div style="height: 6rem"></div>
        <h2>Frequently Asked Questions</h2>
        <div v-for="faq in faqs" :key="faq">
            <FAQ :qa="faq"></FAQ>
        </div>
        <div style="height: 6rem"></div>
        <h2>Our Experts Are Here to Help</h2>
        <p>Response timings 9am to 5pm IST, Monday to Saturday</p>
        <a :href="'tel:' + phoneNumber" class="contact" target="_blank">Phone {{ phoneNumber }}</a>
        <a :href="'mailto:' + email + '?subjet=Web%20Purchase'" class="contact" target="_blank">Email {{ email }}</a>
        <a :href="'https://wa.me/' + whatsApp" class="contact" target="_blank">WhatsApp {{ whatsApp }}</a>
        <div style="height: 6rem"></div>
        <CustomFooter></CustomFooter>
    </div>
</template>
<script>
import CustomFooter from '@/components/CFooter.vue';
import DurationSelection from '@/components/DurationSelection.vue';
import CustomModal from '@/components/CustomModal.vue';
import FAQ from '@/components/FAQ.vue';
import planDetails from '@/json/plan-details.json';

export default {
    data() {
        return {
            planA: null,
            planB: null,
            planC: null,
            planDetails: planDetails,
            showA: false,
            showB: false,
            showC: false,
            isFetching: true,
            country: "India",
            showStripe: false,
            faqs: [
                {
                    q: "Can I change my plan as per my requirement?",
                    a: "You can upgrade your plan at anytime and your old purchase will be refunded on a pro rata basis. However, you cannot downgrade your plan."
                },
                {
                    q: "Can I cancel my subscripiton?",
                    a: "We are unable to offer refunds on cancellation or otherwise, once a plan is purchased. This is because we provision resources for the plan duration immediately on confirmation of purchase."
                },
                {
                    q: "Are EMI options available? How do I access them?",
                    a: "Yes we offer EMI options, to avail them select the EMI option in the page after you click purchase button. EMI charges may apply depending on your bank."
                }
            ]
        }
    },
    components: {
        CustomFooter,
        DurationSelection,
        FAQ,
        CustomModal
    },
    async created() {
        let countryResp = await fetch('https://api.country.is/')
        let countryData = await countryResp.json()
        this.country = countryData.country
        if (this.country != "IN") {
            let recaptchaScript = document.createElement('script')
            recaptchaScript.setAttribute('src', 'https://js.stripe.com/v3/pricing-table.js')
            document.head.appendChild(recaptchaScript)
            this.showStripe = true
            this.isFetching = false
            setTimeout(function () {
                document.getElementById('1-year-tab').click()
            }, 2000)
            return
        }
        let url = this.baseUrl + "/plans";
        let requestOptions = {
            method: 'GET',
            headers: { "Content-Type": "application/json" }
        }
        let respone = await fetch(url, requestOptions)
        if (respone.ok) {
            let data = await respone.json()
            for (let i = 0; i < data.plans.length; i++) {
                let plan = data.plans[i]
                switch (plan.name) {
                    case "Plan A":
                        this.planA = plan
                        break
                    case "Plan B":
                        this.planB = plan
                        break
                    case "Plan C":
                        this.planC = plan
                        break
                }
            }
            this.isFetching = false
        }
    },
    mounted() {
        this.$store.commit('SET_PLAN', "Plan C")
        this.$store.commit('SET_DURATION', 12)
    },
    computed: {
        phoneNumber: {
            get() {
                return "918971830061"
            }
        },
        email: {
            get() {
                return this.$store.state.email
            }
        },
        whatsApp: {
            get() {
                return this.$store.state.whatsApp
            }
        },
        baseUrl: {
            get() {
                return this.$store.state.baseUrl
            }
        },
    },
    methods: {
        showAModal() {
            this.showA = true
        },
        showBModal() {
            this.showB = true
        },
        showCModal() {
            this.showC = true
        },
        close() {
            this.showA = false
            this.showB = false
            this.showC = false
        },
        editCoupon() {
            let coupon = prompt("Enter coupon code")
            if (coupon != null) {
                alert('Invalid coupon code')
            }
        },
    }
}
</script>
<style scoped>
stripe-pricing-table {
    width: 100%;
}

.line1 {
    display: flex;
    align-items: baseline;
    gap: 0.5rem;
}

.line2 {
    font-weight: bold;
    font-size: 14px;
}

.popular {
    position: absolute;
    top: 19px;
    right: -8px;
    margin: 8px;
    font-size: 14px;
    background-color: #ffde7a;
    padding: 2px 4px;
    font-weight: bold;
    color: #907d44;
}

.line1 div {
    font-weight: bold;
    color: #0b54f1;
    font-size: 15px;
    cursor: pointer;
}

.plan-details {
    display: flex;
    padding: 2rem 4rem;
    gap: 0.5rem;
}

.planA,
.planB,
.planC {
    flex-grow: 1;
    flex-basis: 0;
    text-align: left;
    border: 2px solid black;
    min-width: 300px;
}

h2 {
    font-family: 'Zodiak-Regular';
}

.planA .heading {
    background-color: #fdfde9;
    padding: 1rem;
}

.planB .heading {
    background-color: #e1f0eb;
    padding: 1rem;

}

.planC .heading {
    background-color: #e4e7f6;
    padding: 1rem;
    position: relative;
}

h1 {
    font-family: 'Zodiak-Regular', serif;
    font-weight: 600;
    font-size: 3rem;
    line-height: 1.2;
    margin: auto;
}

a {
    font-weight: bold;
    color: #0b54f1;
    text-decoration: none;
}

.coupon {
    border: 1px solid #5a5a5a;
    display: inline-block;
    padding: 10px 20px;
    color: #5a5a5a;
}

.code {
    font-weight: bold;
    color: #6a6a6a;
}

.contact {
    border: 2px solid #0b54f1;
    display: block;
    color: #0b54f1;
    width: 300px;
    margin: 0 auto;
    margin-top: 0.5rem;
    padding: 1rem;
}

.learn-more-content {
    font-weight: normal;
    margin-bottom: 0.5rem;
}

.go-pro-info {
    padding: 0 10px;
}

@media screen and (max-width: 600px) {
    .plan-details {
        padding: 1rem;
        overflow-x: scroll;
    }
}
</style>