<template>
    <div>
        <img src="../../../assets/rx-black.png" alt="rx" class="rx-img" />
        <div class="drug-holder" v-if="hasDrugs">
            <div v-for="(drug, index) in drugs" v-bind:key="drug.id" class="drug">
                <div class="index">{{ index + 1 }}. </div>
                <div class="drug-details">
                    <div class="drug-line1">
                        <div class="brand_name">{{ drug.brand_name }} <span class="generic_name">{{ drug.generic_name }}</span></div>
                    </div>
                    <div v-if="drug.custom" class="custom">{{ custom(drug) }} </div>
                    <div v-else class="not-custom">
                        <div class="frequency-duration">
                            {{ frequency(drug) }} &nbsp;{{ duration(drug) }}&nbsp;<span class="instructions" v-if="drug.instructions"><span class="instruction-label">Instructions</span> {{ drug.instructions }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div style="height: 1.5rem;"></div>
        </div>
    </div>
</template>
<style scoped>
.rx-img {
    height: 1.5rem;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
}
.drug {
    display: flex;
    break-inside: avoid;
    margin-bottom: 0.5rem;
}
.index {
    text-align: right;
    width: 2.5rem;
    font-weight: bold;
    white-space: pre;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 2.5rem;
}
.brand_name {
    font-weight: bold;
}

.generic_name {
    font-family: 'Zodiak-Italic';
    font-weight: 700;
    font-size: 13px
}
.instruction-label{
    font-weight: 700;
    font-size: 13px;
    color: #5a5a5a;
    text-transform: capitalize;
}

.drug-line1 {
    display: flex;
    column-gap: 7px;
    align-items: baseline;
}

@media only screen and (max-width: 768px) {
    .drug-line1 {
        flex-direction: column;
    }
    .instructions {
        display: block;
    }
    .instruction-label {
        font-weight: normal;
        font-size: 16px;
        color: #3a3a3a;
    }
    .instruction-label::after {
        content: ":";
    }
    .generic_name {
        display: block;
    }
}
</style>
<script>
export default {
    props: ['drugs'],
    computed: {
        hasDrugs(){
            if(this.drugs){
                if(this.drugs.length > 0 ){
                    return true
                }
            }
            return false
        },
       
    },
    methods: {
        frequency(drug) {
            if (drug.duration_unit == 'STAT') {
                return '' //include quantity in duration
            }

            let quantityString = '' + drug.quantity

            if (drug.dose && drug.dose != '-1') {
                quantityString = drug.dose + ' ' + drug.dose_unit
            }

            let frequencyString = ''

            switch (drug.frequency) {
                case "OdMorning":
                    frequencyString = `${quantityString} - 0 - 0`
                    break;
                case "OdNight":
                    frequencyString = `0 - 0 - ${quantityString}`
                    break;
                case "BD":
                    frequencyString = `${quantityString} - 0 - ${quantityString}`
                    break;
                case "TID":
                    frequencyString = `${quantityString} - ${quantityString} - ${quantityString}`
                    break;
                case "QID":
                    frequencyString = `${quantityString} - ${quantityString} - ${quantityString} - ${quantityString}`
                    break;
                case "SOS":
                    frequencyString = `${quantityString} - when required (SOS)`
                    break;
                default:
                    frequencyString = ''
                    break;
            }
            return frequencyString
        },
        duration(drug) {
            switch (drug.durationUnit) {
                case "STAT":
                    return `${drug.quantity} STAT - take immediately`
                case "ToCont":
                    return "to continue"
                default:
                    return `for ${drug.duration} ${drug.duration_unit}`
            }
        },
        custom(drug){
            return drug.custom
        } 
    },

}
</script>