/* eslint-disable */
<template>
    <div class="testimonials full-width">
        <div class="testimonial">
            <div class="inner">
               <blockquote>STAT is easy to use and has made my practice more efficent. Plus the app is beautiful.</blockquote>
               <div class="doc-info">
                <img src="https://dncx2j936xcxf.cloudfront.net/static/dist/assets/images/testimonial-page/neet2022-rank-3.webp" alt="doctor image"/>
                <div>
                    <div class="doctor-name">Dr. Adarsha Nayak</div>
                <div class="qualifications">MBBS, MD Internal Medicine</div>
                </div>
               </div>
            </div>
        </div>
        <div class="testimonial">
            <div class="inner">
               <blockquote>I manage patients from multiple offline clinics through STAT. Additionally, I can offer teleconsultations when needed. As the digital prescription automatically collects payment from online patients. I always get paid.</blockquote>
               <div class="doc-info">
                <img src="../assets/1.jpg" alt="doctor image" class="doc-img"/>
                <div>
                    <div class="doctor-name">Dr. K T Dharam Kumar</div>
                <div class="qualifications">MBBS, MD & DNB Dermatology, Cosmetology</div>
                </div>
               </div>
            </div>
        </div>
        <div class="testimonial">
            <div class="inner">
               <blockquote>STAT helps me go through a child's medical records from anywhere. It's especially useful when I get calls in the middle of the night from panicky parents.</blockquote>
               <div class="doc-info">
                <img src="../assets/2.jpg" alt="doctor image" class="doc-img"/>
                <div>
                    <div class="doctor-name">Dr. Jagdish Channappa</div>
                <div class="qualifications">MBBS, MD Pediatrics</div>
                </div>
               </div>
            </div>
        </div>
        <div class="testimonial">
            <div class="inner">
               <blockquote>STAT's digital signature gave me the confidence to issue digital prescripitons. The signature is displayed only when contents of the prescription are exactly the same as what I wrote</blockquote>
               <div class="doc-info">
                <img src="../assets/3.jpg" alt="doctor image" class="doc-img"/>
                <div>
                    <div class="doctor-name">Dr. Roopa T</div>
                <div class="qualifications">MBBS, MD - General Medicine, DNB - Cardiology</div>
                </div>
               </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.doc-info {
    display: flex;
    position: absolute;
    bottom: 0;
    padding-bottom: 18px;
}
.doc-info img{
    height: 50px;
    width: 50px;
    margin-right: 10px;
}
.doctor-name{
   font-weight: bold;
   color: #5a5a5a;
}

.qualifications{
    font-family: 'Zodiak-Bold', serif;
    font-size: 16px;
    color: #5a5a5a;
    line-height: 0.8;
}

.testimonials {
    overflow-x: scroll;
    background: white !important;
    padding: 0 4rem;
    margin-top: 12rem;
    margin-bottom: 8rem;
    flex-direction: row;
    white-space: nowrap;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    cursor: grab;
}

.testimonials::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}
.active {
     cursor: grabbing !important;
     cursor: -webkit-grabbing !important;
}

.testimonial {
    width: 500px;
    height: 280px;
    border: 2px solid black;
    /* border-radius: 5px; */
    margin-right: 1rem;
    display: inline-block;
    vertical-align: middle;
}

.inner {
    margin: 2px;
    width: 492px;
    height: 272px;
    /* border: 2px dotted black; */
    white-space: normal;
    padding: 20px;
    text-align: left;
    font-size: 20px;
    position: relative;
}
blockquote:before {
  content: open-quote;
  margin-right: 0.5rem;
}

blockquote:after {
  content: close-quote;
  margin-left: 0rem;
}

blockquote:before,
blockquote:after {
  display: inline-block;
  vertical-align: bottom;
  position: relative;
  font-size: 35px;
  line-height: 1rem;
  font-family: 'Zodiak-Extrabold';
  color: #ffbf00;
}
.doc-img{
    border-radius: 50px;
}

@media screen and (max-width: 600px) {
  .testimonials {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 0 18px;
  }
  .testimonial {
    max-width: 80%;
  }
  .inner {
    max-width: 100%;
    width: auto;
    padding: 8px;
  }
  blockquote{
    font-size: 1rem;
  }
  .doctor-name{
    font-size: 1rem;
  }
  .qualifications {
    font-size: 14px;
    line-height: 1;
  }
}
</style>
<script>
export default {
    name: "UserReviews",
    mounted() {
        const slider = document.querySelector('.testimonials');
        let isDown = false;
        let startX;
        let scrollLeft;

        slider.addEventListener('mousedown', (e) => {
            isDown = true;
            slider.classList.add('active');
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener('mouseleave', () => {
            isDown = false;
            slider.classList.remove('active');
        });
        slider.addEventListener('mouseup', () => {
            isDown = false;
            slider.classList.remove('active');
        });
        slider.addEventListener('mousemove', (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
        });
    }
}
</script>