<template>
    <div class="login-view">
        <div class="login-content">

            <h1>Login/SignUp</h1>
            <div class="mobile-input">
                <vue-tel-input v-model="phone" :inputOptions=options :autoFormat="false"
                    @validate="onValid"></vue-tel-input>
            </div>
            <button class="genOTP" @click="next" :disabled=!valid id="sign-in-button">
                <span v-if="loading">Processing</span>
                <span v-else>NEXT</span>
            </button>
        </div>

    </div>
</template>
<script>
import { VueTelInput } from 'vue-tel-input';
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "@/firebase"

export default {
    components: {
        VueTelInput,
    },
    mounted() {
        this.$store.commit('SET_BASE_URL', 'https://api.stat.org.in')
        this.initCaptch()
        let gThis = this; // eslint-disable-line 
        document.getElementById('tel-input').addEventListener('keypress', function (e) {
            if (e.key == 'Enter') {
                e.preventDefault()
                gThis.next()
            }
        })
    },
    data() {
        return {
            "phone": "",
            "valid": false,
            "options": {
                autofocus: true,
                placeholder: "Phone number",
                id: "tel-input",
            },
            "loading": false
        }
    },
    computed: {
        mobile: {
            get() {
                return this.$store.state.mobile
            },
            set(value) {
                this.$store.commit('SET_MOBILE', value)
            }
        },
        countryCode: {
            get() {
                return this.$store.state.countryCode
            },
            set(value) {
                this.$store.commit('SET_COUNTRY_CODE', value)
            }
        },
        baseUrl: {
            get() {
                return this.$store.state.baseUrl
            }
        }
    },
    methods: {
        async next() {
            if (!this.valid || this.loading) {
                alert("Enter a valid number")
                return
            }
            this.loading = true
            this.onSignInSubmit()
        },
        onValid(validObj) {
            this.valid = validObj.valid
            if (this.valid) {
                this.mobile = validObj.number
            }
        },
        initCaptch() {
            let gThis = this //eslint-disable-line
            let button = document.getElementById("sign-in-button");
            window.recaptchaVerifier = new RecaptchaVerifier( button, {
                'size': 'invisible',
                'callback': (response) => {
                    // reCAPTCHA solved, allow signInWithPhoneNumber.
                    gThis.onSignInSubmit();
                }
            }, auth);
        },
        onSignInSubmit() {
            const phoneNumber = this.mobile;
            const appVerifier = window.recaptchaVerifier;
            let gThis = this //eslint-disable-line
            signInWithPhoneNumber( auth, phoneNumber, appVerifier)
                .then((confirmationResult) => {
                    gThis.loading = false;
                    // SMS sent. Prompt user to type the code from the message, then sign the
                    // user in with confirmationResult.confirm(code).
                    window.confirmationResult = confirmationResult;
                    gThis.$router.push({ path: 'otp'})
                }).catch((error) => {
                    console.log(error)
                    gThis.loading = false;
                    window.recaptchaVerifier.render().then(function (widgetId) {
                         // eslint-disable-next-line 
                        grecaptcha.reset(widgetId);
                    });
                    let toRetry = confirm("Unable to send code, press ok to retry or refresh this page after some time")
                    if (toRetry == true) {
                        gThis.initCaptch()
                    }
                });
        },
    }
}
</script>
<style scoped>
.login-content {
    width: 100%;
}

.login-view {
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
}

h1 {
    font-family: 'Zodiak-Regular', serif;
    font-weight: 600;
    font-size: 3rem;
    line-height: 1.2;
    margin: auto;
}

.vue-tel-input {
    width: 500px;
    height: 60px;
    border: 2px solid black;
    margin: 2rem auto;
}



.genOTP {
    border: none;
    background-color: #0b54f1;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: 1rem 2rem;
    cursor: pointer;
}

.genOTP:disabled {
    background: grey;
}
</style>
<style>
#tel-input {
    font-size: 18px !important;
}
</style>