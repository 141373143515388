<template>
    <div class="main">
        <p><strong>Select date & time </strong>of visit at {{ location.name }}</p>

        <div class="spacer"></div>

        <a href="https://maps.google.com/?q={{ location.latitude }},{{ location.longitude }}" target="_blank"
            class="rx-text">
            <p>{{ location.address }}
                <span class="link-highlight"> Get&nbsp;Directions&nbsp;<span class="icon">directions</span></span>
            </p>
        </a>

        <div class="spacer"></div>

        <div v-if="location.phone_number">
            <a href="tel:{{ location.phone_number }}" class="phone_number">
                <p>
                    <span class="icon">phone</span>&nbsp;&nbsp;&nbsp;&nbsp;{{ location.phone_number }}
                </p>
            </a>
        </div>

        <div class="spacer"></div>
        <div class="spacer"></div>

        <div class="day-holder">
            <div v-for="day in dates" v-bind:key="day">
                <div v-if="isOpen(day)" class="day-selector" @click="selectDate($event, day)">
                    <div class="line1">{{ getShortDay(day) }}</div>
                    <div class="line2">{{ getDateLine(day) }}</div>
                </div>
            </div>

        </div>
        <div class="spacer"></div>
        <div v-if="availableSessions">
            <div v-for="session in availableSessions" v-bind:key="session" class="session-selector"
                @click="selectSession($event, session)">
                {{ displayTime(session) }}
            </div>
        </div>
        <div v-if="selectedSession" class="active-book-button" @click="warningAlert">
            Book Appointment
        </div>
        <div v-else class="disabled-book-button">
            Book Appointment
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            selectedDate: null,
            availableSessions: null,
            selectedSession: null,
        }
    },
    mounted() {
        let firstAvailableDate = document.getElementsByClassName("day-selector")[0]
        if (firstAvailableDate) {
            firstAvailableDate.click()
        }
    },
    computed: {
        location: {
            get() {
                return this.$store.state.appointmentLocation
            },
            set(value) {
                this.$store.commit('SET_APPOINTMENT_LOCATION', value)
            }
        },
        dates() {
            let date = new Date()
            let dates = []
            for (let i = 0; i < 30; i++) {
                date.setDate(date.getDate() + 1)
                dates.push(new Date(date))
            }
            return dates
        }
    },
    methods: {
        isOpen(date) {
            let i = date.getDay()
            return this.location.timings[i].selected
        },
        getShortDay(date) {
            let shortDaysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
            return shortDaysOfWeek[date.getDay()];
        },
        getDateLine(date) {
            let d = date.getDate()
            let shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            let shortMonth = shortMonths[date.getMonth()];
            return d + " " + shortMonth
        },
        selectDate(event, date) {
            //set the date
            this.selectedDate = date;

            //set available sessions
            let i = date.getDay()
            this.availableSessions = this.location.timings[i].sessions

            //reset sesssions
            this.selectedSession = null;
            //remove sesion highlighting
            let boxes1 = document.querySelectorAll(".session-selector");
            for (let box of boxes1) {
                box.classList.remove("selected-session")
            }

            //css highlighting
            let boxes = document.querySelectorAll(".day-selector");
            for (let box of boxes) {
                box.classList.remove("selected-day")
            }
            event.currentTarget.classList.add("selected-day")

            if (this.availableSessions.length == 1) {
                setTimeout(() => {
                    let firstAvailableSession = document.getElementsByClassName("session-selector")[0]
                    firstAvailableSession.click()
                }, 1)
            }
        },
        selectSession(event, session) {
            this.selectedSession = session;
            let boxes = document.querySelectorAll(".session-selector");
            for (let box of boxes) {
                box.classList.remove("selected-session")
            }
            event.currentTarget.classList.add("selected-session")
        },
        displayTime(session) {
            let startTime = this.getTimeString(session.start_hour, session.start_min)
            let endTime = this.getTimeString(session.end_hour, session.end_min)
            return startTime + "  - " + endTime
        },
        getTimeString(hours, minutes) {
            let ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0' + minutes : minutes;
            let padding = ''
            if (hours < 10) {
                padding = ' '
            }
            let strTime = padding + hours + ':' + minutes + ' ' + ampm;
            return strTime;
        },
        warningAlert(){
            alert("Select a time slot")
        }
    }
}
</script>
<style scoped>
.main {
    text-align: left;
    padding: 18px;
}

.spacer {
    height: 1rem;
}

a {
    text-decoration: none;
    position: relative;
}

.icon {
    font-size: 1.1rem;
    position: absolute;
    bottom: 3px;
}

.day-holder {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    overflow-x: auto;
    padding-bottom: 4px;
    cursor: pointer;
}

.day-holder::-webkit-scrollbar {
    height: 8px;
}

.day-holder::-webkit-scrollbar-track {
    background-color: lightgrey;
}

.day-holder::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: #ffbf00;
}

.day-selector {
    width: 88px;
    text-align: center;
    border: 2px solid #0b62f1;
    margin-right: 4px;
    padding: 4px;
}

.line1 {
    font-weight: bold;
    color: #0b62f1;
}

.line2 {
    color: #0b62f1;
}

.selected-day {
    background: black;
    border: 2px solid black;
}

.selected-day div {
    color: white !important;
}

.session-selector {
    border: 2px solid #0b62f1;
    text-align: center;
    padding: 8px;
    width: 200px;
    white-space: pre;
    margin-bottom: 8px;
    cursor: pointer;
}

.selected-session {
    border: 2px solid black;
    color: white;
    background-color: black;
}

.active-book-button, .disabled-book-button {
    display: block;
    bottom: 1rem;
    left: 1rem;
    right: 1rem;
    padding: 16px;
    text-align: center;
    position: fixed;
    font-weight: bold;
    color: white;
}
.active-book-button {
    background-color: #0b62f1;
    cursor: pointer;
}
.disabled-book-button{
    background-color: lightgray;
}
</style>

