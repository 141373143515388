<template>
    <div class="row1">
        <div> <!-- doctor info -->
            <div class="name custom-color">{{ name }}</div>
            <div class="qualifications custom-color">{{ qualifications }}</div>
        </div>
        <!-- work 0 -->
        <div>
            <a :href="googleMapUrl" class="address" target="_blank">
                <div class="work-name custom-color">{{ work.name }}
                    <span class="link-highlight custom-color"> Directions&nbsp;<span
                            class="icon">directions</span></span>
                </div>
            </a>
            <div v-if="work.phone_number">
                <a :href="phoneUrl" class="phone"><span class="icon">phone</span> {{ work.phone_number }}</a>
            </div>
            <div class="time">
                <DisplayTimings :sessiontimings="work.timings"></DisplayTimings>
            </div>
        </div>
    </div>
    <div class="row2">
        <!--work 1-->
        <div>
            <a :href="googleMapUrl1" class="address" target="_blank">
                <div class="work-name custom-color">{{ work1.name }}
                <span class="link-highlight custom-color"> Directions&nbsp;<span
                        class="icon">directions</span></span></div>
            </a>
            <div v-if="work1.phone_number">
                <a :href="phoneUrl1" class="phone"><span class="icon">phone</span> {{ work1.phone_number }}</a>
            </div>
            <div class="time">
                <DisplayTimings :sessiontimings="work1.timings"></DisplayTimings>
            </div>
        </div>
        <!--work 2-->
        <div>
            <a :href="googleMapUrl2" class="address" target="_blank">
                <div class="work-name custom-color">{{ work2.name }}
                <span class="link-highlight custom-color"> Directions&nbsp;<span
                        class="icon">directions</span></span></div>
            </a>
            <div v-if="work2.phone_number">
                <a :href="phoneUrl2" class="phone"><span class="icon">phone</span> {{ work2.phone_number }}</a>
            </div>
            <div class="time">
                <DisplayTimings :sessiontimings="work2.timings"></DisplayTimings>
            </div>
        </div>
    </div>
</template>
<script>
import DisplayTimings from './DisplayTiming.vue'

export default {
    props: ["doctor"],
    components: {
        DisplayTimings
    },
    data() {
        return {
            work: this.doctor.work[0],
            work1: this.doctor.work[1],
            work2: this.doctor.work[2],
            name: this.doctor.is_doctor ? "Dr. " + this.doctor.name : this.doctor.name,
            qualifications: this.doctor.qualifications,
        }
    },
    computed: {
        googleMapUrl() {
            return "https://maps.google.com/?q=" + this.work.latitude + "," + this.work.longitude
        },
        phoneUrl() {
            return "tel:" + this.work.phone_number
        },
        googleMapUrl1() {
            return "https://maps.google.com/?q=" + this.work1.latitude + "," + this.work1.longitude
        },
        phoneUrl1() {
            return "tel:" + this.work1.phone_number
        },
        googleMapUrl2() {
            return "https://maps.google.com/?q=" + this.work2.latitude + "," + this.work2.longitude
        },
        phoneUrl2() {
            return "tel:" + this.work2.phone_number
        }
    }
}
</script>
<style scoped>
.flex {
    display: flex;
    margin-top: 7px;
    column-gap: 7px;
}

.time {
    font-weight: normal;
}

.local-spacer {
    height: 6px;
}

.local-spacer-1 {
    height: 6px;
}


.row1,
.row2 {
    width: 100%;
    padding: 16px;
    margin: 0;
    text-align: left;
    line-height: 1.2;
    width: 100%;
    columns: 2;
}
.row1 div, .row2 div {
    break-inside: avoid;
}
.row2 {
    border-bottom: 2px dotted #3a3a3a;
    padding-bottom: 12px;
    padding-top: 0;
}
a {
    text-decoration: none;
    color: #3a3a3a;
    font-weight: normal;
}



.time-and-phone {
    flex-direction: column;
    row-gap: 3px;
    display: flex;
    column-gap: 16px;
}

.work-one {
    margin-bottom: 12px;
}

.work-name {
    font-weight: bold;
    color: #3a3a3a;
}
.link-highlight {
    font-weight: normal;
}

.name {
    font-size: 32px;
    line-height: 1;
    margin-bottom: 4px;
}

@media only screen and (max-width: 768px) {
    .row1, .row2 {
        display: flex;
        columns: 1;
        flex-direction: column;
        row-gap: 7px;
    }

    .row1, .row2 {
        padding-bottom: 7px;
    }

    .mobile-hide {
        display: none;
    }
}</style>