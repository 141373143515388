<template>
    <Signature :rx="rx"></Signature>
    <UnverifiedFooter v-if="footerType=='unverified'"></UnverifiedFooter>    
    <ImageFooter :disclaimer="disclaimer" :imageUrl='footerImageUrl' v-else-if="footerType=='image'"></ImageFooter>
    <StandardFooter v-else ></StandardFooter>
</template>
<script>
import ImageFooter from "./footers/imageFooter.vue"
import Signature from "./RxSignature.vue"
import UnverifiedFooter from "./footers/unverifiedFooter.vue"
import StandardFooter from "./footers/standardFooter.vue"

export default {
    props: ['prescription'],
    components: {
        ImageFooter,
        Signature,
        UnverifiedFooter,
        StandardFooter
    },
   computed: {
    footerType(){
        if (!this.prescription.doctor.is_verified){
            return "unverified"
        }
        else if (this.prescription.doctor.customizations.footer_url && this.prescription.doctor.membership.pro_status > 1){
            return "image"
        }
        return "standard"
    },
    disclaimer(){
        if(this.prescription.doctor.membership.pro_status > 1){
            return this.prescription.doctor.customizations.disclaimer
        }
        return ""
    },
    footerImageUrl(){
        if(this.prescription.doctor.membership.pro_status > 1){
            return this.prescription.doctor.customizations.footer_url
        }
        return ""
    },
    rx(){
        return this.prescription
   }
   },
}
</script>