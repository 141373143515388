<template>
    <div class="plan-detail">
        <div class="heading full-width" :style="{ 'background-color': plan.bgColor }">
            <button class="back" @click="back"><span class="material-symbols-outlined">arrow_back_ios</span>All
                Plans</button>
            <h1>{{ plan.name }}</h1>
            <p class="subheading">{{ plan.subTitle }}</p>
        </div>
        <div class="main-text">
            <table class="points" cellspacing="0" cellpadding="0">
                <tr v-for="point in plan.points" v-bind:key="point" class="row">
                    <td class="icon-cell"><span class="material-symbols-outlined icon">done</span></td>
                    <td class="point">{{ point }}</td>
                </tr>
            </table>
            <div class="divider">
                <div class="coupon">Coupon applied: <span class="coupon-code">COCCI</span><button class="edit-button"
                        @click="editCoupon"><span class="material-symbols-outlined edit">edit</span></button></div>
            </div>
            <div style="height: 2rem"></div>
            <p><strong>Purchase Summary</strong></p>
            <table class="summary">
                <tr>
                    <td>Duration</td>
                    <td class="col2">{{ duration }} months</td>
                </tr>
                <tr>
                    <td>Price</td>
                    <td class="col2">₹{{ actualPrice }}</td>
                </tr>
                <tr>
                    <td>Coupon Discount</td>
                    <td class="col2 discount">- ₹{{ discount }}</td>
                </tr>
            </table>
            <div style="height:2rem"></div>
            <hr />
            <table class="summary">
                <tr style="vertical-align: middle">
                    <td><strong>{{ plan.name }}</strong><br />for {{ duration }} months </td>
                    <td class="col2 final">₹{{ price }}</td>
                </tr>
            </table>
            <div v-if="processing" class="loader-holder">
                <Loader></Loader>
            </div>
            <button v-else class="buy" @click="purchase">Proceed to payment</button>
            <div class="terms">You must be a healthcare professional and by making the payment you agree to our <a
                    href="https://fanatical-mine-990.notion.site/Terms-e70d4998772c440e85cb7ddff4b64c59"
                    target="_blank">T&C</a></div>
            <div style="height: 2rem;"></div>
        </div>
    </div>
</template>
<style scoped>
.terms {
    font-size: 14px;
    text-align: center;
}

.terms>a {
    text-decoration: none;
    color: grey;
    font-weight: bold;
}

.buy {
    margin: 2rem 0;
    width: 100%;
    padding: 1rem;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    background-color: #0b54f1;
    color: white;
    cursor: pointer;
}

.loader-holder {
    margin: 2rem 0;
    padding: 1rem;
}

.final {
    font-weight: bold;
    font-size: 1.5rem;
}

.discount {
    color: green;
}

.col2 {
    text-align: right;
}

.main-text {
    max-width: 350px;
    margin: 0 auto;
    text-align: left;
}

.coupon {
    text-align: left;
    margin: 2rem auto;
    position: relative;
}

.coupon-code {
    background-color: green;
    font-weight: bold;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
}

.divider {
    border: 2px dotted grey;
    border-left: none;
    border-right: none;
}

table {
    width: 100%;
}

table,
tr,
td {
    margin: 2rem auto;
    border: none;
    border-collapse: collapse;
}

tr {
    text-align: left;
    vertical-align: top;
}

td {
    padding-top: 1rem !important;
}

.icon-cell {
    padding-right: 1rem;
}

.icon {
    color: green;
    font-size: 1.5rem !important;
    font-weight: bold !important;
}

h1 {
    font-family: 'Zodiak-Regular', serif;
    font-weight: 600;
    font-size: 3rem;
    line-height: 1.2;
    margin: auto;
}

.heading {
    padding: 2rem 0;
    position: relative;
}

.subheading {
    margin: 1rem;
    font-weight: bold;
}

.back {
    font-size: 1rem;
    font-weight: bold;
    color: blue;
    background: transparent;
    border: none;
    position: absolute;
    top: 1rem;
    left: 2.5rem;
    cursor: pointer;
}

.material-symbols-outlined {
    font-size: 12px;
    font-weight: bold;
    ;
}

.edit-button {
    border: none;
    background-color: transparent;
    position: absolute;
    padding: 8px;
    top: -8px;
    margin-left: 3px;
    cursor: pointer;
}

.edit {
    font-size: 1.2rem !important;
    color: grey;
}

.summary {
    margin: 0
}
</style>
<script>
import Loader from '@/components/LoaderComponent.vue';

export default {
    props: ['plan'],
    data() {
        return {
            processing: false,
        }
    },
    components: {
        Loader
    },
    computed: {
        planName: {
            get() {
                return this.$store.state.plan
            }
        },
        duration: {
            get() {
                return this.$store.state.duration
            }
        },
        price: {
            get() {
                return this.$store.state.price
            }
        },
        actualPrice: {
            get() {
                return this.$store.state.actualPrice
            }
        },
        discount: {
            get() {
                return this.actualPrice - this.price
            }
        },
        token: {
            get() {
                return this.$store.state.accessToken
            }
        },
        mobile: {
            get() {
                return this.$store.state.mobile
            }
        },
        countryCode: {
            get() {
                return this.$store.state.countryCode
            }
        },
        baseUrl: {
            get() {
                return this.$store.state.baseUrl
            }
        },
    },
    // mounted() {
    //     let razorpayScript = document.createElement('script')
    //     razorpayScript.setAttribute('src', 'https://checkout.razorpay.com/v1/checkout.js')
    //     document.head.appendChild(razorpayScript)
    // },
    methods: {
        editCoupon() {
            let coupon = prompt("Enter coupon code")
            if (coupon != null) {
                alert('Invalid coupon code')
            }
        },
        back() {
            this.$router.push({ path: '/plans' })
        },
        async purchase(){
            this.processing = true

            //get phonepe payment url
            let options = {
                method: 'POST',
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": this.token
                 },
                body: JSON.stringify({
                    "plan": this.planName,
                    "duration": this.duration
                })
            }
            let reqUrl = this.baseUrl + '/phonepe/generate-order'
            let response = await fetch(reqUrl, options)
            if (response.ok) {
                let data = await response.json()
                let payment_url = data['payment_url']
                if(payment_url){
                    window.location.replace(payment_url);
                    //user is redirected to home after payment
                }
                return
            } 
            alert("Unable to create payment link contact support or download the STAT app and the in app payment mechanism")
        }
        // async purchase() {
        //     this.processing = true;

        //     let requestOptions = {
        //         method: 'POST',
        //         headers: { "Content-Type": "application/json" },
        //         body: JSON.stringify({
        //             "refresh_token": this.token,
        //             "amount": this.price * 100,
        //             "plan": this.planName,
        //             "duration": this.duration,
        //         })
        //     }
        //     let url = this.baseUrl + '/create-plan-order'
        //     let respone = await fetch(url, requestOptions)

        //     if (respone.ok) {
        //         let body = await respone.json();
        //         let order_id = body.order_id

        //         let m = this.countryCode + this.mobile
        //         m = m.replaceAll("+", '')

        //         //eslint-disable-next-line
        //         let gThis = this;

        //         var options = {
        //             "key": "rzp_test_gXZ4ZjHAeFZa2O", // Enter the Key ID generated from the Dashboard
        //             "amount": this.price,
        //             "currency": "INR",
        //             "name": "STAT",
        //             "description": this.planName + " purchase",
        //             "order_id": order_id,
        //             "handler": function (response) {
        //                 let u = gThis.baseUrl + '/go-pro'
        //                 let opts = {
        //                     method: 'POST',
        //                     headers: { "Content-Type": "application/json" },
        //                     body: JSON.stringify({
        //                         "order_id": response.razorpay_order_id,
        //                         "payment_id": response.razorpay_payment_id,
        //                         "signature": response.razorpay_signature,
        //                     })
        //                 }
        //                 fetch(u, opts).then((resp) => resp.json()).then(() => {
        //                     gThis.$router.push({'path': '/home'})
        //                 })
        //             },
        //             "prefill": {
        //                 "email": "purchase@stat.org.in",
        //                 "contact": m
        //             },
        //             "notes": {
        //                 "plan": this.planName,
        //                 "plan_duration": this.duration,
        //                 "token": this.token
        //             },
        //         };

        //         //eslint-disable-next-line
        //         var rzp1 = new Razorpay(options);
        //         rzp1.on('payment.failed', function (response) {
        //             gThis.processing = false;
        //             alert("payment failed: " + response.console.error.description)
        //             // alert(response.error.code);
        //             // alert(response.error.description);
        //             // alert(response.error.source);
        //             // alert(response.error.step);
        //             // alert(response.error.reason);
        //             // alert(response.error.metadata.order_id);
        //             // alert(response.error.metadata.payment_id);
        //         });
        //         rzp1.open()
        //     }
        // }
    }
}
</script>
