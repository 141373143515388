<template>
    <img :src="imageUrl" class="letter-head-img" />
</template>
<script>
export default {
    props: ['imageUrl'],
}
</script>
<style scoped>
.letter-head-img {
    margin: 0;
    padding: 0;
    width: 100%;
}
</style>