<template>
    <div v-if="ready" class="appointment-container">
        <p>Select where your want to see <span class="name"><span v-if="doctor.is_doctor">Dr.&nbsp;</span>{{ doctor.name }}</span> <span class="qualifications">{{ doctor.qualifications }}</span></p>
        <div v-for="work in doctor.work" v-bind:key="work.work_id" class="location-holder" @click="setLocation(work)">
            <div class="location-name">{{ work.name }}</div>
            <div class="location-address">{{ work.address }}</div>
            <table>
                <tr v-for="day in work.timings" v-bind:key="day.day">
                    <td v-if="day.selected">{{ displayDay(day.day) }}</td>
                    <td v-if="day.selected">
                        <div v-for="session in day.sessions" v-bind:key="session.start_hour" class="session-holder">
                            {{ displayTime(session) }}
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <div v-else class="spinner-container">
        <Spinner></Spinner>
    </div>
</template>
<script>
import Spinner from "@/components/SpinnerComponent.vue"

export default {
    components: {
        Spinner
    },
    data() {
        return {
            ready: false,
            id: "",
        }
    },
    mounted() {
        this.id = this.$route.query.id
        // document.getElementsByTagName("nav")[0].style.display = "none"
        this.getLocations()
    },
    computed: {
        doctor: {
            get() {
                return this.$store.state.user
            },
            set(value) {
                this.$store.commit('SET_USER', value)
            }
        },
        location: {
            get() {
                return this.$store.state.appointmentLocation
            },
            set(value) {
                this.$store.commit('SET_APPOINTMENT_LOCATION', value)
            }
        },
        baseUrl: {
            get() {
                return this.$store.state.baseUrl
            }
        }
    },
    methods: {
        setLocation(location){
            this.location = location
            this.$router.push('/select-slot')
        },
        async getLocations() {
            let requestOptions = {
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    "password": "Radiant1!",
                    "id": this.id,
                })
            }
            let url = this.baseUrl + '/get-availablity'
            let respone = await fetch(url, requestOptions)
            if (respone.ok) {
                this.doctor = await respone.json()
                if (this.doctor.work.length == 1) {
                    this.setLocation(this.doctor.work[0])
                }
                //TODO: set logo to custom if needed
                this.ready = true
            } else {
                console.log(respone)
            }
        },
        displayDay(day){
            let lowerCase = day.toLowerCase()
            return day.substring(0, 1) + lowerCase.substring(1,)
        },
        displayTime(session) {
            let startTime = this.getTimeString(session.start_hour, session.start_min)
            let endTime = this.getTimeString(session.end_hour, session.end_min)
            return startTime + " - " + endTime
        },
        getTimeString(hours, minutes){
            let ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0'+minutes : minutes;
            let padding = ''
            if (hours < 10){
                padding = ' '
            }
            let strTime = padding + hours + ':' + minutes + ' ' + ampm;
            return strTime;
        }
    }
}
</script>
<style scoped>
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.appointment-container {
    text-align: left;
    max-width: 1200px;
    margin: auto 0;
    padding: 1rem;
}

.qualifications {
 color: grey;
}



.location-holder{
    border: 2px solid #0b62f1;
    padding: 0.5rem;
    cursor: pointer;
    margin-top: 1rem;
}
.location-name{
    font-weight: bold;
    color: #0b62f1;
}
.session-holder {
    white-space: pre;
}
</style>
