<template>
    <div class="terms">
        <strong>Cancellation & Refund Policy</strong>
    <p>At STAT, we strive to provide you with the best experience and ensure that you have a clear understanding of our subscription terms. Due to the nature of our specialized content and tools, we do not offer cancellations for subscription purchases. We encourage you to thoroughly explore our product and evaluate its suitability for your needs before making a purchase. If you have any questions or concerns about our product, please reach out to our customer support team before proceeding with your subscription.
</p>
<br>
<br>
<strong>Subscription Cancellation by STAT</strong>
<p>STAT reserves the right to cancel a subscription if it is determined that the user who made the purchase is not a registered healthcare practitioner in the specified region for the relevant medical condition. We take compliance seriously and aim to ensure that our product is accessed by qualified professionals who can derive the most benefit from it.
</p>
<br>
<br>
<strong>Refund for Upgrade</strong>
<p>When you decide to upgrade your subscription plan, we calculate the usage of your current subscription on a pro rata basis. This means that we assess the period of time you've been subscribed to your current plan and calculate the proportional usage up to the point of the upgrade request. The pro rata usage is then determined by dividing the days used on your current plan by the total days in the billing cycle.
</p>
<p>Upon calculating the pro rata usage, we apply the remaining unused portion of your current subscription amount as a discount towards the charges of your upgraded plan. This ensures that you receive fair value for your existing subscription while transitioning to a more suitable plan.
</p>
<p><strong>Example</strong> Let's say you have a monthly subscription plan that you've been using for 15 days out of a 30-day billing cycle. If you decide to upgrade to a higher-tier plan that costs more, we would calculate your usage as 50% (15 days / 30 days). The remaining 50% of your current subscription amount will be applied as a discount towards the charges of your upgraded plan.</p>
<br>
<p>Refunds are done only in rare instances such as double payment, multiple payments against the same order_id etc. The refund processed will be completed in 7 to 10 working days to process.</p>
<a href="https://stat-org-in.atlassian.net/servicedesk/customer/portal/3" style="margin: 1rem 0; padding: 20px 10px; color: white; backgroundColor: blue; display: inline-block;
    text-align: center;
    text-decoration: none;">Support & Help</a>

    </div>
</template>
<style scoped>
.terms {
    text-align: left;
    line-height: 1.4;
    margin-top: 1rem;
}
h1 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
}

h2 {
    margin-top: 0.5rem;
    font-size: 1.2rem;
}
ul {
    margin-left: 40px;
}
</style>