<template>
    <div v-if="hasInvs">
        <div class="heading">Investigations</div>
        <div v-for="(inv, index) in invs" v-bind:key="inv.id" class="inv">
            <div class="index">{{ index + 1 }}. &nbsp;</div>
            <div class="inv-details">
                <div class="inv-name">{{ inv.name }}</div>
                <div class="inv-comp" v-if="inv.components">Components: {{ inv.components }}</div>
                <div class="inv-instructions" v-if="inv.instructions">Instructions: {{ inv.instructions }}</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['invs'],
    computed: {
        hasInvs() {
            if (this.invs){
                if(this.invs.length > 0){
                    return true
                }
            }
            return false
        }
    }
}
</script>
<style scoped>
.heading {
    font-weight: bold;
    margin-bottom: 0.5rem;
}
.inv {
    display: flex;
    break-inside: avoid;
    margin-bottom: 0.5rem;
}
.inv:last-of-type {
    margin-bottom: 2rem;
}
.index {
    text-align: right;
    width: 2.5rem;
    font-weight: bold;
    white-space: pre;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 2.5rem;
}
.inv-name {
    font-weight: bold;
}
</style>