<template>
    <div class="holder">
        <button class="btn" @click="navigation">BUY NOW</button>
    </div>
</template>
<script>
export default {
    methods: {
        navigation() {
            let selectedPlan = this.$store.state.plan;
            switch (selectedPlan) {
                case "Plan A":
                    this.$store.commit('SET_TO_PLAN_INT', 1)
                    this.$router.push({ path: '/plans/planA' })
                    break
                case "Plan B":
                    this.$store.commit('SET_TO_PLAN_INT', 2)
                    this.$router.push({ path: '/plans/planB' })
                    break
                case "Plan C":
                    this.$store.commit('SET_TO_PLAN_INT', 3)
                    this.$router.push({ path: '/plans/planC' })
                    break
            }
        }
    }
}
</script>
<style scoped>
.holder {
    width: 100%;
    text-align: center;
    padding: 1rem;
}

.btn {
    width: 100%;
    height: 3rem;
    border: none;
    font-weight: bold;
    color: white;
    background-color: #0b54f1;
    cursor: pointer;
}
</style>
