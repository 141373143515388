<template>
    <div class="password-page">
        <div class="password-holder">
            <p>Enter password to view prescription</p>
            <input type="password" v-model="password" autofocus class="password" id="passwordField"
                v-on:keyup.enter="submit" />
            <div class="row2">
                <p id="error">Wrong Password</p>
                <div class="buttons">
                    <button class="show" @click="show">{{ showText }}</button>
                    <button class="submit" @click="submit">Submit</button>
                </div>
            </div>
        </div>
        <p class="hint"><span class="highlight">Hint: </span>Password should be included in the message sent to you. If not
            ask your doctor.</p>
    </div>
</template>
<style scoped>
.row2 {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.highlight {
    font-weight: bold;
    color: #ffbf00;
}

.hint {
    color: black;
    margin-top: 1rem;
    position: absolute;
    bottom: 1.5rem;
}

.buttons {
    text-align: right;
    margin-top: 1rem;
}

button {
    padding: 0.5rem 1rem;
    margin-left: 0.5rem;
    cursor: pointer;
}

.submit {
    font-weight: bold;
    color: white;
    background-color: #0b62f1;
    border: none;
    border-radius: 5px;
}

.show {
    border: none;
    border-radius: 5px;
    background-color: rgb(202, 202, 202);
}

.password-page {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.password-holder {
    background-color: #e2e2e2;
    padding: 1.5rem;
    padding-bottom: 1rem;
    max-width: 450px;
    border-radius: 3px;
    width: 100%;
}

.password {
    margin-top: 1rem;
    border: none;
    font: inherit;
    padding: 0.4rem 1rem;
    border-radius: 0.2rem;
    font-size: 1.2rem;
    background: #fff3d1;
    width: 100%;
    border-radius: 5px;
    border: 2px solid #0b62f1;
}

input[type=password]:focus {
    border: 2px solid #ffbf00;
    outline: none;
}

p {
    text-align: left;
    margin-top: 0.25rem;
    ;
}

#error {
    color: red;
    visibility: hidden;

}

.error,
input[type=password]:focus .error {
    border: 2px solid red;
}

@media screen and (max-width: 600px) {
    .password-holder {
        padding: 1rem;
        margin: 1rem;
        max-width: calc(100% - 2rem);
        margin-top: -2rem;
    }

    .hint {
        padding: 1rem;
        padding-bottom: 0;
    }
}
</style>
<script>
export default {
    data() {
        return {
            id: this.$route.params.id,
            password: "",
            showText: "Show",
        }
    },
    created(){
      let app = document.getElementById('app')
      if(app) {
        app.style.height = "100%";
      } 
    },
    mounted() {
        let wrong = localStorage.getItem("wrong")
        if (wrong) {
            this.password = wrong
            this.addError()
        }
    },
    methods: {
        submit() {
            let url = '/rx/' + this.id + '/' + this.password
            this.$router.push(url)
        },
        show() {
            if (this.showText == "Show") {
                document.getElementById("passwordField").type = "text"
                this.showText = "Hide"
            } else {
                document.getElementById("passwordField").type = "password"
                this.showText = "Show"

            }
        },
        addError() {
            document.getElementById("error").style.visibility = "visible"
            document.getElementById("passwordField").classList.add("error")
        },
        removeError() {
            document.getElementById("error").style.visibility = "hidden"
            document.getElementById("passwordField").classList.remove("error")
        }
    },
    watch: {
        password(newP, oldP) {
            if (oldP.length > 0) {
                this.removeError()
            }
        }
    }
}
</script>