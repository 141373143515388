<template>
    <div class="contact-us">
        <div class="center-holder">
        <h1>Work With STAT</h1>
        <p>Founded by a physician entrepreneur, STAT is a diverse team of including engineers, doctors, designers, sales, and marketing, with a product loved Doctors. A profitable startup with a sustainable and scalable business model.</p>
        <div style="height: 1rem"></div>
        <p>Mail your CV or Share your LinkedIn profile to get started</p>
        <div style="height: 1rem"></div>
        <a :href="'mailto:hr@stat.org.in'  + '?subjet=Web%20Purchase'" class="contact" target="_blank">Email hr@stat.org.in</a>
    </div>
    </div>
    <CFooter></CFooter>
</template>
<style scoped>
h1 {
    font-family: 'Zodiak-Regular', serif;
}
.contact-us{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}
a {
    font-weight: bold;
    color: #0b54f1;
    text-decoration: none;
}
.contact {
    border: 2px solid #0b54f1;
    display: block;
    color: #0b54f1;
    width: 300px;
    margin: 0 auto;
    margin-top: 0.5rem;
    padding: 1rem;
}
p, div {
    padding: 0 18px;
}
</style>
<script>
import CFooter from '../components/CFooter.vue';

export default {
    components: {
        CFooter
    },
    computed: {
        phoneNumber: {
            get() {
                let p = this.$store.state.phoneNumber
                return p.replace(/\s/g, "");
            }
        },
        email: {
            get() {
                return this.$store.state.email
            }
        },
        whatsApp: {
            get() {
                return this.$store.state.whatsApp
            }
        }
    },
}
</script>