<template>
  <div class="home">
    <div class="above-fold ">
      <div class="hero">
        <TwoColumn :col1Width="50" :col2Width="50" :rowHeight="100">
          <template v-slot:col-1>
            <h1 class="hero-text">Manage&nbsp;your&nbsp;practice,<br>Effortlessly.</h1>
            <a class="cta v1" href="https://stat.page.link/bZqj">Start Free</a>
            <div class="scroll-foward" id="scroll"><img src="../assets/scroll.gif" alt="scroll" id="scroll-img"/><span>Scroll to know more · Patients · Prescription · Reports · Billing
</span></div>
          </template>
          <template v-slot:col-2> <CarouselComponent></CarouselComponent>           
</template>
        </TwoColumn>
      </div>
    </div>
    <div class="spacer spacer2"></div>
    <div class="spacer spacer2"></div>
    <div class="spacer spacer2"></div>
    <h2 class="img-subtitle"><strong>Patient Central</strong> Centralize all patient information, invoices and contact methods.</h2>
    <a class="cta2 v1" href="/plans">Explore Plans<span class="icon icon-size">navigate_next</span></a>
    <div class="img-container">
    <img src="../assets/screenshot-1.svg" class="display-img"/>
    </div>
    <div class="spacer"></div>
    <div class="spacer"></div>
    <div class="spacer"></div>
    <h2 class="img-subtitle"><strong>Smart Prescriptions</strong> - The most powerful prescription ever. Secured by digital signature.<br/>Beautiful digital prescriptions with google maps direction to your clinic, timings that get updated in real time & contact hyperlinks. <strong>Try it below</strong></h2>
    <a class="cta2 v1" href="/plans">Explore Plans<span class="icon icon-size">navigate_next</span></a>
    <div style="height: 2rem"></div>
    <div id="live-rx">    <LivePrescription></LivePrescription></div>
    <p class="note">+ customize letterhead, footer, background & more.</p>
    <div class="spacer"></div>
    <div class="spacer"></div>
    <div class="spacer"></div>
    <h2 class="img-subtitle"><strong>Reports Archive</strong> An archive linked to patient central, with seprate upload access for doctors & patients. Review new reports and contact patients directly from the archive</h2>
    <a class="cta2" href="/plans">Explore Plans<span class="icon icon-size">navigate_next</span></a>
    <div class="img-container">
    <img src="../assets/screenshot-2.svg" class="display-img" id="reports-img"/>
     </div>  
    <div class="spacer"></div>
    <div class="spacer"></div>
    <div class="spacer"></div>  
    <h2 class="img-subtitle"><strong>Invoices</strong> Professional medical invoices, with payment gateway integration for UPI, Netbanking, Debit & Credit Card, and other payment methods. <span style="color:red">Online payments are avialable in India Only</span></h2>
    <a class="cta2" href="/plans">Explore Plans<span class="icon icon-size">navigate_next</span></a>
    <img src="../assets/screenshot-3.png" class="display-img-2"/>
    <div class="spacer"></div>
    <div class="spacer"></div>
    <div class="spacer"></div>
    <YellowDivider content="What makes STAT special?"></YellowDivider>
    <WhyStat></WhyStat>
    <div class="spacer"></div>
    <UserReviews></UserReviews>
    <div class="spacer"></div>
    <h2 class="last">STAT is available on all your devices<br>Get Started, with the FREE plan</h2>
    <div class="spacer"></div>
    <div class="flex">
      <img class="all-device" src="../assets/all-devices.svg">
      <div class="app-links-last v1">
        <a href="https://apps.apple.com/in/app/stat-prescription-maker/id1526714795"><img src="../assets/apple_store.svg" alt="app store link"></a><br>
        <a href="https://play.google.com/store/apps/details?id=com.milieu.prescription"><img src="../assets/google_play.svg" alt="google play link"/></a><br>
        <a href="/desktop"><button class="desktop-button"><span class="material-symbols-outlined">computer</span><span class="text">Desktop app</span></button></a>
      </div>
    </div>
    <CustomFooter></CustomFooter>
  </div>
</template>

<script>
import TwoColumn from '@/components/TwoColumn.vue';
import YellowDivider from '@/components/YellowDivider.vue';
import WhyStat from '@/components/WhyStat.vue';
import UserReviews from '@/components/UserReviews.vue';
import CustomFooter from '@/components/CFooter.vue';
import LivePrescription from '@/components/LivePrescripiton.vue';
import CarouselComponent from '@/components/CarouselComponent.vue';

export default {
  components: {
    TwoColumn,
    YellowDivider,
    WhyStat,
    UserReviews,
    CustomFooter,
    LivePrescription,
    CarouselComponent
  },
}
</script>
<style scoped>
.flex {
  display: flex;
  justify-content: center;
}

.hero-img {
  width: 100%;
  max-width: 1200px;
  visibility: hidden;
}
#hero{
  width: 100%;
}
.spacer {
  height: 4rem;
}
.last {
  font-family: 'Zodiak-Regular', serif;
  text-align: center;
}
.app-links-last{
  padding: 0 20px;
  text-align: center;
}
.app-links-last a img, .app-links-last button{
  margin-top: 5px;
}

.desktop-button {
  height: 46px;
    width: 140px;
    border: none;
    font-weight: bold;
    color: #5a5a5a;
    text-align: left;
    padding: 8px;
    position: relative;
    cursor: pointer;
}

.desktop-button .text {
  margin-left: 4px;
    margin-top: 14px;
    line-height: 0;
    display: inline-block;
    position: absolute;
    font-size: 14px;
    font-family: 'Zodiak-Regular';
}

.home {
  height: 100%;
}

.above-fold {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100vw;
    margin-left: 50%;
    transform: translateX(-50%);
}

.hero {
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin: auto;
}

.hero-text {
  font-family: 'Zodiak-Regular', serif;
  font-weight: 600;
  font-size: 3rem;
  line-height: 1.2;
  text-align: left;
  margin: auto;
}

.cta {
  margin-top: 18px;
  padding: 18px 36px;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  background-color: #ffbf00;
  color: black;
  border-radius: 3px;
  display: block;
  background-position: center;
  transition: background 0.8s;
  width: 200px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
}

.cta2 {
  text-decoration: none;
  border: none;
  font-size: 1.5rem;
  font-weight: bold;
  color: rgb(11, 98, 241);
  cursor: pointer;
  margin-bottom: 1rem;
  display: block;
}


.cta:hover,
.header-button:hover {
  background: #f6cb4b radial-gradient(circle, transparent 1%, #f6cb4b 1%) center/15000%;
}

.note {
  font-family: 'Zodiak-Italic';
  font-weight: bold;
}

.cta:active, .cta2:active,
.header-button:active {
  background-color: #fdd96d;
  background-size: 100%;
  transition: background 0s;
}

.scroll-foward {
  margin-bottom: 18px;
  display: flex;
  position: absolute;
  bottom: 0;
}

.scroll-foward img {
  width: 60px;
  margin-left: -19px;
}

.scroll-foward span {
  margin-top: 24px;
  font-size: 0.9rem;
  font-weight: bold;
}

#scroll{
  -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    transition: all .5s ease;
}

.display-img, .display-img-2{
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
  max-width: 1200px;
}

.display-img-2{
  max-width: 650px;
  border: 2px solid black;
}

.img-title{
  font-family: 'Zodiak-Regular', serif;
  font-weight: 600;
  font-size: 3rem;
  line-height: 1.2;
}

.img-subtitle{
  font-size: 1.5rem;
  line-height: 1.2;
  font-weight: normal;
  max-width: 750px;
  margin: 0 auto;
}

.icon-size{
  vertical-align: middle;
  font-size: 1.5rem;
}

.all-device{
  width: 100%;
  max-width: 500px;
}


@media screen and (max-width: 997px) {
  .all-device {
    width: 50%;
  }
 .spacer2{
  height: 0rem !important;
 }
    .cta {
      margin: 0 auto;
      margin-top: 18px;
    }
    .hero-text{
      text-align: center;
    }
    #live-rx  {
      margin-left: 9px;
    margin-right: 9px;
    }
    .display-img-2{
      width: calc(100vw - 18px)!important;
      transform: none;
      translate: none;
      box-sizing: border-box;
      margin-left: 9px;
      margin-right: 9px;

    }
    .img-subtitle {
      margin-left: 18px;
    margin-right: 18px;
    font-size: 1rem;
    }
    .cta2 {
      font-size: 1rem;
    }
    .note {
      font-size: 0.8rem;
    }
    .display-img{
      width: auto;
    margin-left: 18px;
    transform: none;
    white-space: nowrap;   
    }
    .img-container {
      overflow-x: scroll;
    }

    #reports-img {
      height: 500px;
    }
    .hero-text {
      font-size: 2rem;
    }

    .last {
      font-size: 1rem;
    }
    #hero, .hero-img {
      display: none;
    }
    .scroll-foward {
      display: none;
    }
    .above-fold {
      height: 50%;
    }

}
</style>