<template>
    <ImageHeader :imageUrl="doctor.customizations.letter_head_url" v-if="letterHeadType == 'image'"></ImageHeader>
    <LogoHeader :doctor="doctor" v-else-if="letterHeadType=='logo'"></LogoHeader>
    <Work3Header :doctor = "doctor" v-else-if="letterHeadType=='work-3'"></Work3Header>
    <Work2Header :doctor = "doctor" v-else-if="letterHeadType=='work-2'"></Work2Header>
    <Work1Header :doctor = "doctor" v-else></Work1Header>
    <div class="spacing"></div>
</template>
<script>
import ImageHeader from './headers/ImageHeader.vue'
import LogoHeader from './headers/LogoHeader.vue'
import Work1Header from './headers/Work1Header.vue'
import Work2Header from './headers/Work2Header.vue'
import Work3Header from './headers/Work3Header.vue'

export default {
    components: {
        ImageHeader,
        LogoHeader,
        Work1Header,
        Work2Header,
        Work3Header
    },
    props: ["prescription"],
    computed: {
        doctor(){
            return this.prescription.doctor
        },
        letterHeadType(){
            if(this.doctor.customizations.letter_head_url && this.prescription.doctor.membership.pro_status > 1){
                return "image"
            }

            if(this.doctor.customizations.logo_url && this.prescription.doctor.membership.pro_status > 1){
                return "logo"
            }
            
            let len = this.doctor.work.length
            
            if(len == 3){
                return "work-3"
            }

            if(len == 2){
                return "work-2"
            }

            return "work-1"
        }
    },
    mounted(){
        if(this.doctor.membership.pro_status > 1){
            if(this.doctor.customizations.color){
                let hexCode = '#' + this.doctor.customizations.color.join('')
                let reg=/^#([0-9a-f]{3}){1,2}$/i;
                if (reg.test(hexCode)){
                    let toApplyCustomColor = document.getElementsByClassName('custom-color')
                    if(toApplyCustomColor){
                        for (let i = 0; i < toApplyCustomColor.length; i++){
                            toApplyCustomColor[i].style.color = hexCode
                        }
                    }
                    let toApplyCustomColorIcon = document.getElementsByClassName('icon')
                    if(toApplyCustomColorIcon){
                        for (let i = 0; i < toApplyCustomColorIcon.length; i++){
                            toApplyCustomColorIcon[i].style.color = hexCode
                        }
                    }
                }
            }
        }
    }
}
</script>
<style scoped>
.spacing {
    height: 1rem;
}
</style>