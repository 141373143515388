<template>
    <div class="alert-box" @click="handleScoll">
        <div class="heading">Medical Invoice</div>
        <div class="invoice-instructions"><span class="warn">Complete payment to view
                prescription. </span>If you have paid by cash ask your doctor to mark the invoice as payed.</div>
    </div>
</template>
<style>
.heading {
    font-weight: bold;
}

.alert-box {
    margin: 0 auto;
    max-width: 650px;
    text-align: left;
    padding: 1rem;
    background-color: rgb(255, 208, 0);
    margin-bottom: 5px;
    cursor: pointer;
}

.warn {
    font-weight: bold;
    color: #ff3939;
}
</style>
<script>
export default {
    methods: {
        handleScoll() {
            let btn = document.getElementById('rzp-button1')
            let bounds = btn.getBoundingClientRect().bottom + 20;
            document.body.scrollTo({
                top: bounds,
                behavior: "smooth"
            })
        }
    }
}
</script>