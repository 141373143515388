<template>
    <div class="desktop-app">
        <div class="spacer"></div>
        <div class="spacer"></div>
        <div class="spacer"></div>
        <div class="spacer"></div>
        <div class="spacer"></div>
        <div class="spacer"></div>
        <h1 id="heading">Install STAT Desktop</h1>
        <p>Requires pro subscription <a class="cta2" href="/plans">Explore Plans<span class="icon icon-size">navigate_next</span></a></p>
        <div class="spacer"></div>
        <div class="spacer"></div>
        <div class="spacer"></div>
        <div class="spacer"></div>
        <div class="spacer"></div>
        <div class="download-links">
            <a href = "https://statreleases.blob.core.windows.net/stat-desktop-windows/stat_installer.exe" download class="download-link">Windows</a>
            <a href = "https://statreleases.blob.core.windows.net/stat-desktop-mac/STAT_installer.dmg" download class="download-link">MacOS</a>
        </div>
        <div class="spacer"></div>
        <div class="spacer"></div>
        <div class="spacer"></div>
        <div class="spacer"></div>
        <div class="spacer"></div>
        <h2>Requirements</h2>
        <div class="spacer"></div>
        <div class="requirements">
            <div class="windows-requirements">
                <p><strong>Windows</strong></p>
                
                <p class="req">Windows 64bit</p>
                <p class="req">Windows 10 or later</p>
                <p class="req">40 MB free disk space</p>
                <p class="req">Webcam & Microphone for Video Calls</p>
                <p class="req">Whatsapp Desktop for smooth integration</p>
            </div>
            <div class="mac-requirements">
                <p><strong>Mac OS</strong></p>
                <p class="req">Intel or M1 Mac</p>
                <p class="req">150 MB free disk space</p>
                <p class="req">MacOS 11 (Big Sur) or later</p>
                <p class="req">Webcam & Microphone for Video Calls</p>
                <p class="req">Whatsapp Desktop for smooth integration</p>

            </div>
        </div>
        <div class="spacer"></div>
        <div class="spacer"></div>
        <div class="spacer"></div>
        <div class="spacer"></div>
        <div class="spacer"></div>
        <h2>Permissions</h2>
        <div class="spacer"></div>
        <p class="req">Internet</p>
        <p class="req">Webcam & Microphone for Video Calls</p>
        <p class="req">Storage for caching objects for fast accesss</p>
        <div class="spacer"></div>
        <div class="spacer"></div>
        <div class="spacer"></div>
        <div class="spacer"></div>
        <div class="spacer"></div>
        <a href="https://stat-org-in.atlassian.net/servicedesk/customer/portal/3" style="border-radius: 5px; background-color: blue; color: white; padding: 10px 100px;">Help</a>
        <div class="spacer"></div>
        <div class="spacer"></div>
        <div class="spacer"></div>
    </div>
    <CustomFooter></CustomFooter>
</template>
<script>
import CustomFooter from '@/components/CFooter.vue';

export default {
    mounted(){
        let url = location.href
        if (url.includes('update')) {
            document.getElementById('heading').innerHTML = 'Update STAT Desktop'
        }
    },
    components: {
        CustomFooter
    },
    computed: {
        phoneNumber: {
            get() {
                
                let p = this.$store.state.phoneNumber
                return p.replace(/\s/g, "");
            }
        },
        email: {
            get() {
                return this.$store.state.email
            }
        },
        whatsApp: {
            get() {
                return this.$store.state.whatsApp
            }
        }
    },
}
</script>
<style scoped>

.desktop-app{
    height: 100%;
}
.spacer {
    height: 1rem;
}
h1, h2 {
    font-family: 'Zodiak-Regular', serif;
  font-weight: 600;
  line-height: 1.2;
}
h1 {
    font-size: 3rem;
}
a {
    text-decoration: none;
    font-weight: bold;
    color: #0b54f1;
}
.icon-size{
    font-size: 1.3rem;
    vertical-align: middle;
}

.download-link { 
    padding: 1rem;
    background-color: #0b54f1;
    color: white;
    border-radius: 5px;
}
.download-link:first-child {
    margin-right: 2rem;
}
.requirements {
    display: flex;
    justify-content: space-around;
    max-width: 1200px;
    width: 100%;
    column-gap: 2rem;
}
.windows-requirements {
    text-align: right;
    min-width: 50%;
}
.mac-requirements {
    text-align: left;
    min-width: 50%;
}
.contact-us{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}
.contact {
    border: 2px solid #0b54f1;
    display: block;
    color: #0b54f1;
    width: 300px;
    margin: 0 auto;
    margin-top: 0.5rem;
    padding: 1rem;
}
</style>