<template>
    <PlanDetail :plan="planDetails.planB"></PlanDetail>
</template>
<script>
import PlanDetail from '@/components/PlanDetail.vue';
import planDetails from '@/json/plan-details.json'

export default {
    data() {
        return {
            planDetails: planDetails
        }
    },
    components: {
        PlanDetail
    },

}
</script>