<template>
        <div class="basic-info">{{ patient.name }} · {{ patient.age }} · {{ patient.sex }}</div>
        <div class="contact-info" v-if="email || phone || address">
            <div v-if="phone"><strong>Phone</strong> {{ phone }}</div>
            <div v-if="email"><strong>Email</strong> {{ email }}</div>
            <div v-if="address"><strong>Address</strong> {{ address }}</div>
        </div>
        <div class="past-history" v-if="comorbidities || allergies || pastSx">
            <div v-if="comorbidities"><strong>Comorbidities</strong> {{ comorbidities }}</div>
            <div v-if="pastSx"><strong>Past Surgeries</strong> {{ pastSx }}</div>
            <div v-if="allergies" class="allergies"><strong>Allergies</strong> {{ allergies }}</div>
        </div>
</template>
<script>
export default {
    props: ["patient", 'doctor'],
    computed: {
        isPro(){
            return this.doctor.membership.pro_status > 1
        },
        email(){

            if(this.doctor.customizations.email && this.isPro){
                return this.patient.email
            }
            return ""
        },
        phone(){
            if(this.doctor.customizations.phone_number && this.isPro){
                return this.patient.phone_number
            }
            return ""
        },
        address(){

            if(this.doctor.customizations.address && this.isPro){
                return this.patient.address
            }
            return ""
        },
        comorbidities(){
            if(this.doctor.customizations.comorbidities && this.isPro){
                if(this.patient.other){
                    return this.patient.comorbidities.join(', ') + ', ' + this.patient.other
                }
                return this.patient.comorbidities.join(', ') 
            }
            return ""
        },
        pastSx(){
            if(this.doctor.customizations.pastSurgeries && this.isPro){
                return this.patient.pastSurgeries
            }
            return ""
        },
        allergies(){
            if(this.doctor.customizations.allergies && this.isPro){
                return this.patient.allergies
            }
            return ""
        },
    }
}
</script>
<style scoped>
.contact-info {
    margin-bottom: 16px;
}
strong {
    color: #5a5a5a;
}
.basic-info {
    font-weight: bold;
    margin-bottom: 16px;
}
.local-spacer {
    height: 16px;
}
.past-history {
    margin-bottom: 16px;
}

.allergies {
    color: black;
    background-color: #ffbf00;
    display: inline-block;
    padding-right: 8px;
    padding-left: 2px;
}
.allergies strong {
    color: black;
}
</style>