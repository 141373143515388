<template>
        <div class="holder">
            <h2 class="how">{{content}}</h2>
        </div>
</template>
<script>
export default {
    props: {
        content: String
    }
}
</script>>
<style>
.holder {
    max-width: 1200px;
    margin: 0 auto;
}
.how {
    font-family: 'Zodiak-Regular', serif;
    font-size: 2rem;
    padding: 2rem 0;
    display: hidden;
}
@media screen and (max-width: 600px) {
 .how {
    font-size: 1.5rem;
    padding-bottom: 1rem;
 }
}
/* .inactive {
    color: #8a8a8a;
} */
</style>