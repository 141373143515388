<template>
    <div class="rx-menu hide-print">
        <input type="file" id="file_upload" accept="image/*, .pdf" multiple hidden @change="handleImageUpload">
        <button class="upload-report" @click="clickFileUpload()">Upload&nbsp;Report</button>
        <div class="content-mobile print-div" @click="printRx">🖨️</div>
        <button class="print" @click="printRx"><span
                class="content-desktop">Print</span></button>
    </div>
    <div class="uploading" v-if="uploading">
        <Spinner />
        <div class="uploading-text">Compressing & Uploading</div>
    </div>
</template>
<style scoped>
.uploading-text {
    color: white;
    text-align: center;
    position: fixed;
    margin-top: 100px;
}
.uploading {
    position: fixed;
    z-index: 10;
    background-color: #000000d1;
    top: 0;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.rx-menu {
    position: fixed;
    top: 5px;
    display: flex;
    flex-direction: column;
    row-gap: 3.5px;
    align-items: center;
    left: 0;
    right: 0;
}

.rx-menu button {
    border: none;
    background-color: transparent;
    border: none;
    color: blue;
    text-align: left;
    font-size: 16px;
    cursor: pointer;
    margin-left: 807px;
    width: 150px;
    padding: 3.5px 0;
    text-decoration: underline;
    text-decoration-color: #ffd814;
    text-decoration-thickness: 4px;
    font-family: 'zodiac-bold';
    font-weight: bold;
}

button:hover {
    font-weight: bold;
}

.content-mobile {
    display: none;
}

@media only screen and (max-width: 768px) {
    .content-mobile {
        display: inline-block;
    }

    .content-desktop {
        display: none;
    }

    .rx-menu {
        top: auto;
        bottom: 14px;
        display: flex;
        justify-content: center;
        width: 100%;
        left: 0;
        right: 0;
        column-gap: 3.5px;
        z-index: 4;
        flex-direction: row;
    }

    .rx-menu button, .print-div {
        padding: 14px 21px;
        font-size: 14px;
        color: blue;
        background-color: #e7f0ed;
        border-radius: 5px;
        font-weight: bold;
        cursor: pointer;
        height: 50px;
        border: 1px solid blue;
        text-decoration: none;
        font-family: 'Atkinson Hyperlegible', sans-serif;
    }

    .print {
        font-size: 18px !important;
    }
}
</style>
<script>
import Spinner from '@/components/SpinnerComponent.vue'

export default {
    props: ['rx'],
    data(){
        return {
            uploading: false,
        }
    },
    components: {
        Spinner
    },
    computed: {
        baseUrl: {
            get() {
                return this.$store.state.baseUrl
            }
        }
    },
    mounted() {
        let script = document.createElement('script')
        script.setAttribute('src', 'https://cdn.jsdelivr.net/npm/browser-image-compression@2.0.0/dist/browser-image-compression.js')
        document.head.appendChild(script)

    },
    methods: {
        printRx() {
            window.print()
        },
        clickFileUpload() {
            document.getElementById("file_upload").click();
        },
        async handleImageUpload(mainEvent) {
            this.uploading = true;
            //fetch upload urls
            let domainName = this.baseUrl;
            let requestUrl = domainName + "/report-upload-urls";
            // eslint-disable-next-line
            let globaltThis = this
            fetch(requestUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "password": "Radiant1!",
                    "number": mainEvent.target.files.length,
                    "patientId": globaltThis.rx.patient_id
                })
            }).then(function (res) {
                if (res.status == 200) {
                    return res.json();
                } else {
                    return Promise.reject("server")
                }
            }).then(function (data) {
                let reportIds = Object.keys(data.urls)

                const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true
                }

                let reportToSave = {
                    "doctorId": globaltThis.rx.doctor_id,
                    "patientId": globaltThis.rx.patient_id,
                    "prescriptionId": globaltThis.rx.prescription_id,
                    "reports": []
                }

                for (let i = 0; i < mainEvent.target.files.length; i++) {

                    let reportFile = mainEvent.target.files[i];
                    let reportId = reportIds[i];
                    let reportUrl = data.urls[reportId];
                    let format = 0

                    if (reportFile.name.includes(".pdf")) {
                        var xhr = new XMLHttpRequest()
                        xhr.open("PUT", reportUrl)
                        xhr.setRequestHeader("x-ms-blob-type", "BlockBlob")
                        xhr.setRequestHeader("Content-Type", reportFile.type)
                        xhr.send(reportFile)
                        format = 1
                    } else {
                        // eslint-disable-next-line 
                        imageCompression(reportFile, options).then((compressedFile) => {
                            var xhr = new XMLHttpRequest()
                            xhr.open("PUT", reportUrl)
                            xhr.setRequestHeader("x-ms-blob-type", "BlockBlob")
                            xhr.setRequestHeader("Content-Type", reportFile.type)
                            xhr.send(compressedFile)
                        })
                    }

                    reportToSave.reports.push({
                        "id": reportId,
                        "format": format,
                        "url": reportUrl
                    })
                }
                //upload reportIds to server
                fetch(domainName + "/save-reports", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(reportToSave)
                }).then(function (res) {
                    if (res.status == 200) {
                        return res.json();
                    } else {
                        return Promise.reject("server")
                    }
                }).then(function (data) {
                    console.log(data);
                }).catch(function (err) {
                    console.log(err);
                })

            }).catch(function (err) {
                console.log(err);
            })
            this.uploading = false;
            alert("Reports uploaded successfully!");
        }
    }
}
</script>