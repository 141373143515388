<template>
    <div class="row" :style="{'height': rowHeightPercent}">
        <div class="col-1" :style="{'width':col1Percent}">
            <slot name="col-1"></slot>
        </div>
        <div class="col-2" :style="{'width':col2Percent}">
            <slot name="col-2"></slot>
        </div>
    </div>
</template>


<script>
export default {
  props: {
    col1Width: {
        type: Number,
        required: true
    },
    col2Width: {
        type: Number,
        required: true
    },
    rowHeight: {
        type: Number,
        required: true
    }
  },
  computed: {
    isMobile() {
   if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
     return true
   } else {
     return false
   }
 },
    col1Percent(){
        if (this.isMobile){
            return "100%"
        }
        return this.col1Width + "%";
    },
    col2Percent(){
           if (this.isMobile){
            return "100%"
        }
        return this.col2Width + "%";
    },
    rowHeightPercent(){
        return this.rowHeight + "%";
    }
  }
}
</script>

<style scoped>
.row {
    width: 100%;
    display: flex;
    padding: 18px;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}
.col-1, .col-2 {
    text-align: left;
    padding: 20px;
}

@media screen and (max-width: 997px) {
    .row {
        flex-direction: column;
        padding: 8px;
    }

    .col-1, .col-2 {
        width: 100% !important;
    }
}


</style>
