<template>
    <div class="terms">
<h1 id="terms">Terms</h1>
<h2 id="stat-terms-of-service">STAT <strong>Terms of Service</strong></h2>
<p><strong>PLEASE READ THIS AGREEMENT CAREFULLY BEFORE USING THIS SERVICE.</strong></p>
<p><strong>BY USING THE SERVICE OR CLICKING AGREE CUSTOMER IS AGREEING TO BE BOUND BY THIS AGREEMENT. IF CUSTOMER IS AGREEING TO THIS AGREEMENT ON BEHALF OF OR FOR THE BENEFIT OF THEIR EMPLOYER, THEN CUSTOMER REPRESENTS AND WARRANTS THAT THEY HAVE THE NECESSARY AUTHORITY TO AGREE TO THIS AGREEMENT ON THEIR EMPLOYER'S BEHALF. PLEASE REVIEW SECTION 10 CLOSELY, AS IT CONTAINS A BINDING ARBITRATION PROCESS FOR RESOLVING DISPUTES INSTEAD OF USING THE COURT PROCESS.</strong></p>
<p>Veisalgia Healthcare LLP, on behalf of itself and its affiliates/group companies under the brand &quot;Stat&quot; (<strong>“Stat”</strong>), is the author and publisher of the internet resource <a href="http://www.stat.org.in">www.stat.org.in</a> and the mobile application ‘Stat'.</p>
<p>This agreement is between <strong>Stat</strong>, and the customer agreeing to these terms<strong>(Customer)</strong>, and covers all services provided to Customer.</p>
<h1 id="1-stat-software-services">1) STAT SOFTWARE SERVICES</h1>
<p>This agreement provides Customer access and use of Stat's subscription services, as specified on the applicable electronic or written order or subscription agreement between the parties<strong>(Customer Agreement)</strong>.</p>
<ul>
<li><strong>Policies.</strong>Customer understands that use of the Service is also governed by Stat's <a href="/privacy">Privacy Policy</a>, as they may be modified over time.</li>
</ul>
<h1 id="2-use-of-services">2) USE OF SERVICES</h1>
<h2 id="a-stat-responsibilities"><strong>a. Stat Responsibilities</strong></h2>
<ul>
<li><strong>Support.</strong>Stat shall use commercially reasonable efforts to provide customer support for the Service.</li>
</ul>
<h2 id="b-customer-responsibilities"><strong>b. Customer Responsibilities</strong></h2>
<ul>
<li><strong>Access by Employees and Contractors.</strong>Customer will not make the Service available to anyone other than its employees and contractors solely to access the Service for the benefit of Customer in compliance with the terms of this agreement and the Customer Agreement. Customer is responsible for the compliance with this agreement by its employees and contractors.</li>
<li><strong>Restrictions and Responsibilities.</strong>Customer may not (i) sell, resell, rent or lease the Service, use the Service beyond its internal operations or reverse engineer the Service; (ii) use the Service to store or transmit infringing, unsolicited marketing emails, libelous, or otherwise unlawful or tortious material, or to store or transmit material in violation of third-party rights (including without limitation any privacy rights); (iii) interfere with or disrupt the integrity or performance of the Service; (iv) attempt to gain unauthorized access to the Service or its related systems or networks; (v) modify, copy the Service, or create derivative works based on the Service or any part, feature, function or user interface thereof; (vi) except to the extent permitted by applicable law, disassemble, reverse engineer, or decompile the Service or remove or modify any proprietary marking or restrictive legends in the Service; (vii) use the Service in violation of any law, including without limitation, HIPAA, Telephone Consumer Protection Act and any spam laws (for example, CAN SPAM); or (vii) access the Service to build a competitive product or service. Customer is solely responsible for Customer Information (defined below), must use commercially reasonable efforts to prevent unauthorized access to the Service, must notify Stat promptly of any such unauthorized access, and may use the Service only in accordance with its user guide and applicable law.</li>
<li><strong>Customer Information.</strong>All data, information, images, documentation, and files entered or uploaded by Customer to the Service remains the sole property of Customer, as between Stat and Customer<strong>(Customer Information)</strong>, subject to the other terms of this agreement. Customer grants Stat a non-exclusive, royalty-free license to modify, store, transmit, and otherwise use the Customer Information for purposes of Stat performing under this agreement. Notwithstanding the foregoing, if Customer's access to the Services is suspended for non-payment of fees in accordance with Section 3(d), Stat will have no obligation to provide access to Customer Information to Customer via the Service until Customer remedies such non-payment as provided in this agreement.</li>
<li><strong>Accuracy of Information Provided by Customer</strong>Customer represents and warrants to Stat that all Customer Information, Content (each as defined below) and other material provided under Customer's account, by Customer or on its behalf, is true, correct and accurate. If Customer learns that any Customer Information or Content provided to Stat as part of the Service is not true, correct or accurate, Customer must immediately notify Stat by phone and in writing of this fact, and provide the true, correct and accurate information to Stat. Stat relies on Customer representations regarding the truth, accuracy and compliance with laws of Customer Information and Content.<strong>STAT IS NOT LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY CUSTOMER'S FAILURE TO COMPLY WITH THIS PARAGRAPH, IRRESPECTIVE OF ANY ACT OR OMISSION ON THE PART OF STAT.</strong></li>
<li><strong>Aggregation Services and De-identified Data.</strong>Stat may use protected health information to provide you with data aggregation services and to create de-identified data. Stat shall solely own all right, title and interest, in any de-identified data it creates from protected health information. Stat and its affiliates may use and disclose, during and after this agreement, all aggregate, anonymized information and de-identified data for purposes of enhancing the Service, technical support and other business purposes, all in compliance with the HIPAA Privacy Standards, including without limitation the limited data set and de-identification of information regulations.</li>
<li><strong>Electronic Prescriptions.</strong>If Customer uses the Service for Electronic Prescriptions, the Customer and each Prescribing Provider agrees: (a) to only prescribe on their own behalf and not give away password or credentials to another person to prescribe for them; and (b) to take the same responsibility as Customer would take when transmuting paper or phone prescriptions.</li>
<li><strong>Meaningful Use.</strong>Customer and providers intending to attest for Meaningful Use agree to follow the processes and procedures recommended in Stat's Meaningful Use training such that Stat's tracking and reports function appropriately.</li>
</ul>
<h2 id="c-additional-terms---applicable-to-stat-patient-center"><strong>c. Additional Terms - Applicable to Stat Patient Center</strong></h2>
<ul>
<li><strong>Content.</strong>Customers may upload or submit content, files and information to the Service (Content). As between Stat and Customer, all Content belongs to Customer, and Customer hereby grants Stat a non-exclusive irrevocable, perpetual, royalty-free license to display, store, distribute, share, modify, and otherwise use such Content for purposes providing the Service under this agreement, including, without limitation, a license to syndicate the Content to third party publisher sites as required to provide you the applicable Service.</li>
<li><strong>Warranties.</strong>Customer represents and warrants to Stat that:<ul>
<li>Any Content submitted to the Service does not violate any copyright, trade secret, privacy or other third party right;</li>
<li>It will not submit any Content that is untrue, defamatory, harmful to any person, or violates HIPAA Privacy Rules, or laws on patient privacy; and</li>
<li>All patient testimonials submitted by Customer are accurate, have the patient&#39;s consent, and comply with ethical guidelines of professional medical associations as well as state and local medical and private practice boards and governing bodies.</li>
</ul>
</li>
<li><strong>Reviews &amp; Opinions.Stat</strong> does not endorse, validate as accurate, or necessarily agree with any of the reviews, links, and user-generated content from users or Customers on the Service. Stat reserves the right to refuse to publish any patient review provided by Customer.<ul>
<li>The Service may attempt to send automated or human-based alerts when reviews are provided on third party websites, but Stat does not guarantee the accuracy, completeness, or timeliness of such alerts.</li>
</ul>
</li>
<li><strong>Advertisements.Stat</strong> reserves the right to place advertisements or messages from third parties on free claimed listings web pages as well as free versions of the Service. Such advertisements or messages from third parties may be visible to users as well as Customer.</li>
</ul>
<h2 id="d-additional-terms---applicable-to-stat-telehealth"><strong>d. Additional Terms - Applicable to Stat Telehealth</strong></h2>
<ul>
<li><strong>Telehealth Medical Services. </strong>Stat Telehealth is designed to facilitate Customer's delivery of Telehealth Medical Services. “Telehealth Medical Services” include, without limitation, the delivery of medical care by Customer to a patient physically located at another site through the use of advanced telecommunications technology that allows providers to remotely see and hear the patient in real time.</li>
<li><strong>Customer's Responsibilities.</strong>Customer is, and will remain, solely responsible for: (i) the provision of Telehealth Medical Services and all other professional medical services and aspects relating to Customer&#39;s practice of medicine (for the avoidance of doubt, Telehealth Medical Services shall be performed by Customer for appropriate visits as determined in Customer's, or its provider's, as applicable, sole professional judgment); (ii) documenting the Telehealth Medical Services in Customer's clinical records; (iii) billing and collecting for Telehealth Medical Services; (iv) providing notice to and/or obtaining consent from any third-parties relating to the provision of Telehealth Medical Services through Stat Telehealth; (v) ensuring Stat Telehealth is used in accordance with applicable instructions, training materials and other online material that may be made available by Stat from time to time; (vi) obtaining and maintaining both the functionality and security of all information technology software solutions and related services necessary to connect to, access or otherwise use Stat Telehealth; and (vii) complying with applicable laws, rules, regulations and standards imposed by government health care programs and other payors, licensing agencies and applicable accreditation bodies, including, without limitation, with respect to the provision of Telehealth Medical Services.</li>
</ul>
<h1 id="3-payment-terms">3) PAYMENT TERMS</h1>
<h2 id="a-payment"><strong>a. Payment</strong></h2>
<p>Customer must pay all fees as specified on the Customer Agreement and related services as incurred as specified on the pricing page. Unless otherwise stated in the Customer Agreement, invoiced charges are due upon receipt. Customer is responsible for providing complete and accurate billing and contact information to Stat and notifying Stat of any changes to such information.</p>
<h2 id="b-suspension-of-service-for-non-payment">b. Suspension of Service for Non Payment</h2>
<p>Stat may suspend or terminate the Service, or both, if Customer has not paid amounts owed to Stat when due. In advance of any suspension or termination, Stat will make commercially reasonable efforts to send a minimum five-day (5-day) notice of payment default to Customer prior to suspension or termination (Customer is responsible updating its contact information with Stat and notifying Stat of any changes to such information).</p>
<h2 id="c-fee-changes">c. Fee Changes</h2>
<p>All fees may be changed with thirty (30) days advance email notice to Customer. Customer is responsible for keeping its updated email address on file with Stat.</p>
<h1 id="4-warrantyservice-level-agreementdisclaimers">4) WARRANTY/SERVICE LEVEL AGREEMENT/DISCLAIMERS</h1>
<h2 id="a-availability"><strong>a. Availability</strong></h2>
<p>Stat will make commercially reasonable efforts to maintain uptime of ninety-nine percent (99%) excluding any scheduled downtime</p>
<h2 id="b-mutual-compliance-with-laws"><strong>b. Mutual Compliance with Laws</strong></h2>
<p>Each party represents and warrants to the other party that it will comply with all applicable laws regarding its performance under this agreement.</p>
<h2 id="c-no-medical-advice-provided-by-stat"><strong>c. NO MEDICAL ADVICE PROVIDED BY STAT</strong></h2>
<p>The Service does not provide medical advice, provide medical or diagnostic services, or prescribe medication. Use of the Service is not a substitute for the professional judgment of health care providers in diagnosing and treating patients. Customer agrees that it is solely responsible for verifying the accuracy of patient information (including, without limitation, obtaining all applicable patients&#39; medical and medication history and allergies), obtaining patient's consent to use the Service (including, without limitation, the Patient Portal), and for all of its decisions or actions with respect to the medical care, treatment, and well-being of its patients, including without limitation, all of Customer's acts or omissions. Any use or reliance by Customer upon the Service will not diminish that responsibility. Customer assumes all risks associated with Customer's clinical use of the Service for the treatment of patients. Neither Stat nor its licensors assume any liability or responsibility for damage or injury (including death) to Customer, a patient, other persons, or tangible property arising from any use of the Service.</p>
<h2 id="d-customers-compliance-with-medical-retention-laws-and-patient-records-access"><strong>d. CUSTOMER'S COMPLIANCE WITH MEDICAL RETENTION LAWS AND PATIENT RECORDS ACCESS</strong></h2>
<p>Customer is responsible for understanding and complying with all state and federal laws related to retention of medical records, patient access to information, and patient authorization to release data. Customer agrees that it will obtain any necessary patient consent prior to using the Service (including, without limitation, the Patient Portal) and will apply settings to exclude information from availability in the Patient Portal as necessary to comply with state or federal law.</p>
<h2 id="e-disclaimers"><strong>e. DISCLAIMERS</strong></h2>
<p>STAT DISCLAIMS ALL WARRANTIES, INCLUDING, WITHOUT LIMITATION, ANY WARRANTY THAT THE SERVICE WILL BE UNINTERRUPTED, ERROR-FREE, OR WITHOUT DELAY, AND THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. WHILE STAT TAKES REASONABLE PHYSICAL, TECHNICAL, AND ADMINISTRATIVE MEASURES TO SECURE THE SERVICE, STAT DOES NOT GUARANTEE THAT THE SERVICE CANNOT BE COMPROMISED. STAT DISCLAIMS ANY WARRANTY REGARDING ANY PERCENTAGE OF COLLECTION OF CLAIMS FOR CUSTOMER.</p>
<h1 id="5-mutual-confidentiality">5) MUTUAL CONFIDENTIALITY</h1>
<h2 id="a-definition-of-confidential-information"><strong>a. Definition of Confidential Information</strong></h2>
<p>Confidential Information means all non-public information disclosed by a party<strong>(Discloser)</strong>to the other party<strong>(Recipient)</strong>, whether orally or in writing, that is designated as confidential or that reasonably should be understood to be confidential given the nature of the information and the circumstances of disclosure<strong>(Confidential Information)</strong>. Stat's Confidential Information includes, without limitation, the non-public portions of the Service.</p>
<h2 id="b-protection-of-confidential-information"><strong>b. Protection of Confidential Information</strong></h2>
<p>The Recipient must use the same degree of care that it uses to protect the confidentiality of its own confidential information (but in no event less than reasonable care) not to disclose or use any Confidential Information of the Discloser for any purpose outside the scope of this agreement. The Recipient must make commercially reasonable efforts to limit access to Confidential Information of Discloser to those of its employees, contractors, and clients (as the case may be) who need such access for purposes consistent with this agreement and who have signed confidentiality agreements with Recipient no less restrictive than the confidentiality terms of this agreement. The Recipient may disclose Confidential Information (i) to the extent required by law or legal process; (ii) to its legal or financial advisors, provided that such advisors are bound by a duty of confidentiality that includes use and disclosure restrictions; and (iii) as required under applicable securities regulations. In addition, each Party may disclose the terms and conditions of this agreement on a confidential basis to current and prospective investors, acquirers, lenders, and their respective legal and financial advisors in connection with due diligence activities.</p>
<h2 id="c-exclusions"><strong>c. Exclusions</strong></h2>
<p>Confidential Information excludes information that: is or becomes generally known to the public without breach of any obligation owed to Discloser; was known to the Recipient prior to its disclosure by the Discloser without breach of any obligation owed to the Discloser; is received from a third party without breach of any obligation owed to Discloser; or was independently developed by the Recipient without use or access to the Confidential Information.</p>
<h1 id="6-proprietary-rights">6) PROPRIETARY RIGHTS</h1>
<h2 id="a-reservation-of-rights-by-stat"><strong>a. Reservation of Rights by Stat</strong></h2>
<p>The software, workflow processes, user interface, designs, know-how and other technologies provided by Stat as part of the Service are the proprietary property of Stat and itslicensors, and all right, title and interest in and to such items, including all associated intellectual property rights, remain only with Stat. Stat reserves all rights unless expressly granted in this agreement.</p>
<h1 id="7-limits-on-liability">7) LIMITS ON LIABILITY.</h1>
<h2 id="a-no-indirect-damage-stat-is-not-liable-for-any-indirect-special-or-consequential-damages-including-without-limitation-costs-of-delay-loss-of-data-or-information-lost-profits-or-revenues-or-loss-of-anticipated-cost-savings-arising-under-or-related-to-this-agreement-even-if-advised-of-the-possibility-of-such-loss-or-damage"><strong>a. No Indirect Damage. Stat is not liable for any indirect, special, or consequential damages (including, without limitation, costs of delay, loss of data or information, lost profits or revenues or loss of anticipated cost savings) arising under or related to this agreement, even if advised of the possibility of such loss or damage.</strong></h2>
<p><strong>b. Limit. Stat total liability for all damages arising under or related to this agreement (in contract, tort, or otherwise) does not exceed the actual amount paid by Customer within the six-month (6-month) period preceding the event which gave rise to the claim. Any claim by Customer against Stat must be brought within twelve (12) months of the event which gave rise to the claim, and if it is not brought within such time period then such claim is expressly waived by Customer.</strong></p>
<h1 id="8-term-termination-and-return-of-data">8) TERM, TERMINATION, AND RETURN OF DATA</h1>
<h2 id="a-term"><strong>a. Term</strong></h2>
<p>The applicable Services will continue for the duration specified in the Customer Agreement and will be automatically extended for additional consecutive terms unless either party provides notice of termination of no less than ten (10) days before the end of the initial term or the then-applicable renewal term. This agreement continues until the Customer Agreement and all Services are terminated.</p>
<h2 id="b-termination-for-material-breach"><strong>b. Termination for Material Breach</strong></h2>
<p>Either party may terminate this agreement and the Customer Agreement if the other party material breaches any term of the agreement or Customer Agreement and does not cure the breach within thirty (30) days of written receipt of notice of breach.</p>
<h2 id="c-return-of-data"><strong>c. Return of Data</strong></h2>
<p>Stat will have no obligation to provide Customer Information to Customer upon termination of this agreement. Notwithstanding the foregoing, Stat will retain Customer Information for sixty (60) days from such termination and Stat may provide Customer access to such information upon Customer's request. </p>
<h2 id="d-customer-actions-upon-termination"><strong>d. Customer Actions upon Termination</strong></h2>
<p>Upon termination, Customer must pay any unpaid fees and destroy all Stat's property in Customer's possession. Customer, upon Stat's request, will confirm in writing that it has complied with this requirement.</p>
<h2 id="e-suspension-or-termination-of-service-for-violation-of-law-or-the-agreement"><strong>e. Suspension or Termination of Service for Violation of Law or the Agreement</strong></h2>
<p>Stat may immediately suspend or terminate the Service and remove applicable Customer Information or Content if it in good faith believes that, as part of using the Service, Customer may have violated any applicable law or any term of this agreement. Stat may try to contact Customer in advance, but it is not required to do so.</p>
<h1 id="9-indemnity">9) INDEMNITY</h1>
<h2 id="a-general-indemnity"><strong>a. General Indemnity</strong></h2>
<p>To the extent allowed by applicable law, Customer must indemnify, defend (at Stat's option), and hold harmless Stat against all third-party claims (including, without limitation, by governmental agencies), demands, damages, costs, penalties, fines, and expenses (including reasonable attorneys' fees and costs) arising out of or related to:</p>
<ul>
<li>the use of the Service by Customer;</li>
<li>Customer's breach of any term in this agreement;</li>
<li>any unauthorized use, access, or distribution of the Service by Customer; or</li>
<li>violation of any individual's privacy rights related to information submitted under Customer's account, or fraudulent, invalid, duplicate, incomplete, unauthorized, or misleading information submitted under Customer's account or by Customer.</li>
</ul>
<h1 id="10-governing-law-and-arbitration">10) GOVERNING LAW AND ARBITRATION</h1>
<h2 id="a-governing-law"><strong>a. Governing Law</strong></h2>
<p>This agreement and any action related thereto is governed by laws of India (without regard to conflicts of law principles).</p>
<h2 id="b-general-arbitration-process"><strong>b. General Arbitration Process</strong></h2>
<p>Any dispute or claim that may arise between the parties relating in any way to or arising out of this agreement, Customer's use of or access to the Services (Claim), must be resolved exclusively through final and binding arbitration (rather than in court) under the Arbitration and Conciliation Act, 1996. Any judgment on the award rendered by the arbitrator is final and may be entered in any court of competent jurisdiction. The seat of such arbitration shall be Bangalore. All proceedings of such arbitration, including, without limitation, any awards, shall be in the English language. The award shall be final and binding on the parties to the dispute.</p>
<h2 id="c-jurisdiction">c. Jurisdiction</h2>
<p>Notwithstanding Sections 10(b), the courts at Bengaluru shall have exclusive jurisdiction over any disputes arising out of or in relation to this Agreement, your use of the Website or the Services or the information to which it gives access.</p>
<h2 id="d-equitable-relief">d. Equitable Relief</h2>
<p>Notwithstanding anything above, Stat may seek and obtain injunctive and equitable relief in any court of competent jurisdiction without restriction or required process in this agreement.</p>
<h2 id="f-prohibition-of-class-and-representative-actions"><strong>f. PROHIBITION OF CLASS AND REPRESENTATIVE ACTIONS</strong></h2>
<p>EACH PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL PARTY BASIS, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION OR PROCEEDING. THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN MORE THAN ONE PARTY&#39;S CLAIMS, AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED, CLASS OR REPRESENTATIVE PROCEEDING. IF THIS CLASS ACTION AND REPRESENTATIVE ACTION WAIVER PROVISION IS HELD TO BE UNENFORCEABLE, THEN SECTIONS 10.b SHALL ALSO BE UNENFORCEABLE.</p>
<h1 id="11-other-terms">11) OTHER TERMS</h1>
<h2 id="a-no-solicit-or-hire-clause"><strong>a. No Solicit or Hire Clause</strong></h2>
<p>Customer acknowledges that Stat invests considerable time and expense in the training of its employees and independent subcontractors in the services to be provided under this agreement. Customer agrees that for the full term of this agreement, and for one (1) year after its termination Customer will not solicit or employ in any capacity, whether as a direct employee, independent contractor, or representative of another company providing similar services to Customer as Stat, any person employed by Stat at any time during the term of this agreement whose duties involve providing the Services, whether for Customer or other Stat customers.</p>
<h2 id="b-consent-to-electronic-notice-communications-and-transactions"><strong>b. Consent to Electronic Notice, Communications and Transactions</strong></h2>
<p>For purposes of messages and notices about the Service (including, without limitation, collections and payments issues), Stat may send email notices to the email address associated with Customer&#39;s account or provide in service notifications. For certain notices (e.g., notices regarding termination or material breaches), Stat may send notices to the postal address provided by Customer.<strong>Stat has no liability associated with Customer&#39;s failure to maintain accurate contact information within the Service or its failure to review any emails or in-service notices.</strong>Customer will have the ability to enter into agreements, authorizations, consents, and applications; make referrals; order lab tests; prescribe medications; or engage in other transactions electronically. CUSTOMER AGREES THAT ITS ELECTRONIC SUBMISSIONS VIA THE SERVICES IN CONNECTION WITH SUCH ACTIVITIES CONSTITUTE ITS AGREEMENT TO BE BOUND BY SUCH AGREEMENTS AND TRANSACTIONS AND APPLIES TO ALL RECORDS RELATING TO SUCH TRANSACTIONS. Customer represents and warrants that it has the authority to take such actions.</p>
<p>Customer further agrees that by registering for the Service, including any request forms or use of communications features, constitutes a request for Stat to send email, fax, phone call, or SMS communications related to the Service, including, but not limited to, upcoming appointments, special oﬀers, billing, and upcoming events. Stat is not responsible for any text messaging or data transmission fees. If Customer provides a cellular phone number and agrees to receive communications from Stat, Customer specifically authorizes Stat to send text messages or calls to such number. Customer represents and warrants it has the authority to grant such authorization. Customer is not required to consent to receive text messages or calls as a condition of using the Service and may opt out of such messages through the Services.</p>
<h2 id="c-entire-agreement-and-changes"><strong>c. Entire Agreement and Changes</strong></h2>
<p>This agreement and the Customer Agreement constitute the entire agreement between the parties and supersede all prior or contemporaneous negotiations or agreements, whether oral or written, related to this subject matter. Customer is not relying on any representation concerning this subject matter, oral or written, not included in this agreement. No representation, promise, or inducement not included in this agreement is binding. No modification or waiver of any term of this agreement is effective unless signed by both parties. The Convention on Contracts for the International Sale of Goods does not apply. Notwithstanding the foregoing, Stat may modify this agreement by posting modified Terms of Service on the Stat website. Customer agrees that by continuing to use the Service after posting of the modified Terms of Service, Customer agrees to be bound by the changes.</p>
<h2 id="d-feedback"><strong>d. Feedback</strong></h2>
<p>If Customer provides feedback or suggestions about the Service, then Srar (and those it allows to use its technology) may use such information without obligation to Customer.</p>
<h2 id="e-beta-features"><strong>e. Beta Features</strong></h2>
<p>If Customer is invited to access any beta features of the Service or a Customer accesses any beta features of the Service, Customer acknowledges that: (a) such features have not been made commercially available by Stat; (b) such features may not operate properly, be in final form, or be fully functional; (c) such features may contain errors, design flaws, or other problems; (d) it may not be possible to make such features fully functional; (e) use of such features may result in unexpected results, corruption or loss of data, or other unpredictable damage or loss; (f) such features may change and may not become generally available; and (g) Stat is not obligated in any way to continue to provide or maintain such features for any purpose in providing the ongoing Service. These beta features are provided AS IS, with all faults. Customer assumes all risk arising from use of such features, including, without limitation, the risk of damage to Customer's computer system or the corruption or loss of data.</p>
<h2 id="f-no-assignment"><strong>f. No Assignment</strong></h2>
<p>Stat may assign or transfer this agreement or the Customer Agreement (or its rights and/or obligations thereunder) to any third party without consent. Customer may not assign or transfer this agreement or the Customer Agreement to a third party without the prior written consent of Stat, except that this agreement and the Customer Agreement may be assigned (without Stat's consent) as part of a merger, or sale of all or substantially all of the business or assets, of Customer.</p>
<h2 id="g-electronic-notice"><strong>g. Electronic Notice</strong></h2>
<p>For purposes of messages and notices about the Service (including without limitation, collections and payments issues), Stat may send email notices to the email addresses associated with Customer&#39;s account or provide in service notifications. For certain notices (e.g., notices regarding termination or material breaches), Stat may send notices to the postal address provided by Customer. Stat has no liability associated with Customer&#39;s failure to maintain accurate contact information within the Service or its failure to review any emails or in-service notices.</p>
<h2 id="h-independent-contractors-and-enforceability"><strong>h. Independent Contractors and Enforceability</strong></h2>
<p>The parties are independent contractors with respect to each other. If any term of this agreement is invalid or unenforceable, the other terms remain in effect.</p>
<h2 id="i-no-additional-terms"><strong>i. No Additional Terms</strong></h2>
<p>Stat rejects additional or conflicting terms of a form-purchasing document. If there is an inconsistency between this agreement and the Customer Agreement, the Customer Agreement prevails.</p>
<h2 id="j-survival-of-terms"><strong>j. Survival of Terms</strong></h2>
<p>All terms survive termination of this agreement that by their nature survive for a party to assert its rights and receive the protections of this agreement.</p>
<h2 id="k-customer-name"><strong>k. Customer Name</strong></h2>
<p>Stat may use Customer&#39;s name and logo in customer lists and related promotional materials describing Customer as a customer of Stat, which use must be in accordance with Customer's trademark guidelines and policies, if any, provided to Stat.</p>
</div>
</template>
<style scoped>
.terms {
    text-align: left;
    line-height: 1.4;
}
h1 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
}

h2 {
    margin-top: 0.5rem;
    font-size: 1.2rem;
}
ul {
    margin-left: 40px;
}
</style>