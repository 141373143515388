<template>
    <div class="flex-contianer">
        <div class="flex-item">
            <div class="number">1.  </div>
            <div class="content">
            <div class="item-heading">Simple</div>
            <div>Created by a team of doctors, desginers & engineers, STAT is the simplest and most effective way to manage your practice.</div>
        </div>
        </div>
        <div class="flex-item">
            <div class="number">2. </div>
            <div class="content">
            <div class="item-heading">Secure</div>
            <div>Data is encrypted and can be accessed only after OTP verification.</div>
            </div>
        </div>
        <div class="flex-item">
            <div class="number">3. </div>
            <div class="content">
            <div class="item-heading">Safe</div>
            <div>Encrypted data is automatically backed up to multiple data centers.</div>
            </div>
        </div>
        <div class="flex-item">
            <div class="number">4. </div>
            <div class="content">
            <div class="item-heading">Drug & Investigation Database</div>
            <div>STAT drug database of 2,50,000+ drugs and 5,000+ investigations makes writing orders a easy. Databases are updated on a daily basis.</div>
            </div>
        </div>
        <div class="flex-item">
            <div class="number">5. </div>
            <div class="content">
            <div class="item-heading">Interaction Checker</div>
            <div>Automatically check every prescription for drug-drug interaction. The interaction checker is updated on a weekly basis.</div>
            </div>
        </div>
        <div class="flex-item">
            <div class="number">6. </div>
            <div class="content">
            <div class="item-heading">Secure Prescriptions</div>
            <div>Beautiful, cryptographically secured and digitally signed (with your own signature) prescriptions that are tamper proof. </div>
            </div>
        </div>
        <div class="flex-item">
            <div class="number">7. </div>
            <div class="content">
            <div class="item-heading">Payment Collection</div>
            <div>Patients will be able to view prescriptions only after completing payment.</div>
            </div>
        </div>
        <div class="flex-item">
            <div class="number">8. </div>
            <div class="content">
            <div class="item-heading">Instant Withdrawls</div>
            <div>Withdraw money to your bank account with in minutes 24x7</div>
            </div>
        </div>
        <div class="flex-item">
            <div class="number">9. </div>
            <div class="content">
            <div class="item-heading">Go digital at your own pace</div>
            <div>STAT helps you transition your practice to the cloud at your own speed and for your own needs.</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped>
.flex-contianer {
    display: flex;
    margin-top: 4rem;
    flex-wrap: wrap;
    justify-content: center;
}

.flex-item {
    min-width: 200px;
    max-width: 400px;
    text-align: left;
    padding: 18px;
    display: flex;
    font-size: 20px;
}

.item-heading {
    font-family: 'Zodiak-Extrabold';
}

.number {
    font-family: 'Zodiak-Extrabold';
    margin-right: 4px;
}
@media screen and (max-width: 800px) {
  .flex-item {
    max-width: 100%;
  }

  .flex-contianer {
    justify-content: start;
    margin-top: 1rem;
  }
  
}


@media screen and (max-width: 600px) {
  .flex-contianer{
    margin: 0 18px;
  }
  .flex-item {
    font-size: 1rem;
  }
}

</style>