<template>
    <div class="pt-web">
    <p><strong>Select Profile</strong></p>
    <p class="explanation">There are more than one patients linked to this mobile number. Select the current patient,
        you can switch later.</p>
    <div style="height: 1rem"></div>
    <div v-for="(profile, index) in profiles" :key="index" class="profile-tile" @click="selectProfile(index)">
        <div class="profile-col1">
            <h2 style="color: #0B62F1">{{ profile.name }}</h2>
            <p><span style=" font-weight: normal">{{ profile.sex }}, {{ profile.age }}</span></p>
            <p v-if="profile.ht_wt_records" v-html="ht_wt(profile.ht_wt_records)"></p>
            <p v-if="profile.allergies"><span class="tempHeading">Allergies</span> {{ profile.allergies }}</p>
            <p v-if="profile.comorbidities"><span class="tempHeading">Medical Conditions</span>
                {{ coMorb(profile.comorbidities) }}</p>
            <p v-if="profile.pastSurgeries"><span class="tempHeading">Past Surgeries</span> {{ profile.pastSurgeries }}
            </p>
        </div>
        <div class="profile-col2">&rsaquo;</div>
    </div>
</div>
</template>
<style scoped>
h2 {
    text-transform: capitalize;
}

.tempHeading {
    color: grey;
    font-weight: bold;
}

.profile-tile {
    padding: 0.5rem 0;
    border-bottom: 2px dotted black;
    display: grid;
    grid-template-columns: auto 18px;
    column-gap: 0;
    row-gap: 0;
}

.profile-col2 {
    color: #0b62f1;
    font-size: 1.5rem;
    line-height: 1;
    text-align: right;
}
</style>
<script>

export default {
    name: 'ChooseProfile',
    computed: {
        profiles: {
            get() {
                return this.$store.state.patientProfiles
            }
        },
        patient: {
            get(){
                return this.$store.state.patient
            },
            set(val){
                this.$store.commit('SET_PATIENT', val)
            }
        },
        tokens: {
            get(){
                return this.$store.state.tokens
            }
        },
        access_token: {
            set(val){
                this.$store.commit('SET_ACCESS_TOKEN', val)
            }
        },
        refresh_token: {
            set(val){
                this.$store.commit('SET_REFRESH_TOKEN', val)
            }
        }
    },
    methods: {
        coMorb(list) {
            return list.join(', ')
        },
        ht_wt(record) {
            let lastRecord = record[record.length - 1]
            let ht = lastRecord.height + lastRecord.heightUnit
            let wt = lastRecord.weight + lastRecord.weightUnit
            return `<span style="font-weight: bold; color: grey">Height</span> ${ht}  <span style="font-weight: bold; color: grey">Weight</span> ${wt}`
        },
        selectProfile(index) {
            this.patient = structuredClone(this.profiles[index])
            this.access_token = structuredClone(this.tokens[index].access_token)
            this.refresh_token = structuredClone(this.tokens[index].refresh_token)
            this.$router.push('/patient-home')
        },
    }
}
</script>