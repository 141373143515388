<template>
    <div class="line-one">
        <PatientInfo :doctor="rx.doctor" :patient="rx.patient" />
        <div class="date">
            <RxDate :date="rx.date" />
        </div>
    </div>
    <RxHistory :rx="rx" />
    <RxExamination :rx="rx" />
    <RxDiagnois :diagnosis="rx.diagnosis" />
    <RxDrugs :drugs="rx.drugs" />
    <RxInvestigations :invs="rx.investigations" />
    <RxAdvice :advice="rx.advice" />
</template>
<script>
import RxDate from './RxDate.vue'
import PatientInfo from './PatientInfo.vue'
import RxHistory from './RxHistory.vue'
import RxExamination from './RxExamination.vue'
import RxDiagnois from './RxDiagnosis.vue'
import RxDrugs from './RxDrugs.vue'
import RxInvestigations from './RxInvestigations.vue'
import RxAdvice from './RxAdvice.vue'

export default {
    props: ['rx'],
    components: {
        RxDate,
        PatientInfo,
        RxHistory,
        RxExamination,
        RxDiagnois,
        RxDrugs,
        RxInvestigations,
        RxAdvice
    },
}
</script>
<style scoped>
.line-one {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
}
.date {
    margin-left: auto;
}
</style>