<template>
    <div v-if="show" class="modal" @click="close">
        <div  class="modal-content">
            <span class="close" @click="close">&times;</span>
            <slot name="content"></slot>
        </div>

    </div>
</template>
<script>
export default {
    props: ['show'],
    methods: {
      close(){
        this.$emit('send', 'close')
      }
    }
}
</script>
<style scoped>
.modal {
  position: fixed; /* Stay in place */
  z-index: 9; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */

}
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
  max-width: 500px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

</style>