<template>
    <div class="otp-view">
        <div class="otp-content">
            <h1>Enter OTP</h1>
            <div style="height:1rem"></div>
            <p>OTP was sent to {{ mobile }}<span
                    style="width: 1rem; display: inline-block;"></span><button class="edit" @click="back">Edit</button></p>
            <div style="height:3rem"></div>
            <v-otp-input
        ref="otpInput"
        v-model:value="otp"
        input-classes="otp-input"
        separator="  "
        :num-inputs="6"
        :should-auto-focus="true"
        input-type="letter-numeric"
        :conditionalClass="['one', 'two', 'three', 'four']"
        @on-complete="handleOnComplete"
        class="otp-input-container"
      />
      <div style="height:2rem"></div>
      <Spinner v-if="loading"></Spinner>
      <div v-else style="height: 10em;"></div>
        </div>
    </div>
</template>
<style>
.otp-input {
    height: 50px;
    width: 50px;
    margin-right: 16px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    border: 2px solid black;
    border-radius: 5px;
    line-height: 1.5;
}
</style>
<style scoped>

.otp-input-container {
    justify-content: center;
}
.submit {
    border: none;
    color: white;
    background-color: #0b54f1;
    font-weight: bold;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.otp-content {
    width: 100%;
}

.otp-view {
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;

}

h1 {
    font-family: 'Zodiak-Regular', serif;
    font-weight: 600;
    font-size: 3rem;
    line-height: 1.2;
    margin: auto;
}

.edit {
    border: none;
    background: white;
    font-size: 1rem;
    font-weight: bold;
    color: #0b54f1;
    cursor: pointer;
}

.resend {
    color: #7a7a7a99;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.otp {
    font-size: 1.5rem;
    border: none;
    border-bottom: 2px solid black;
    text-align: center;
    width: 200px;
}

.otp:focus {
    border: none;
    outline: none;
    border-bottom: 2px solid #0b54f1;
}

a {
    text-decoration: none;
    font-weight: bold;
}

.success {
    border: 1px solid green;
    display: inline-block;
    padding: 0.5rem;
    background-color: lightgreen;
    color: #0000006b;
}
</style>
<script>
import { signInWithPhoneNumber } from "firebase/auth";
import Spinner from "@/components/SpinnerComponent.vue"

export default {
    data() {
        return {
            otp: "",
            loading: false
        }
    },
    components: {
        Spinner
    },
    computed: {
        mobile: {
            get() {
                return this.$store.state.mobile
            }
        },
        countryCode: {
            get() {
                return this.$store.state.countryCode
            }
        },
        baseUrl: {
            get() {
                return this.$store.state.baseUrl
            }
        }
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
        async handleOnComplete(){
            this.loading = true
            await this.submit()
        },
        async submit() {
            let gThis = this // eslint-disable-line
            window.confirmationResult.confirm(this.otp).then((result) => {
                // User signed in successfully.
                const user = result.user;
                //get id token to verify
                user.getIdToken().then((token) => {
                    gThis.statSignIn(token)
                });
                gThis.loading = false;
            }).catch((error) => {
                alert("Unable to login: " + error)
                gThis.loading = false;
            });    
        },
        async statSignIn(token){
            let requestOptions = {
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    mobile_number: this.mobile,
                    token: token
                })
            }

            let respone = await fetch(this.baseUrl + '/login', requestOptions)
            let body = await respone.json();
            this.$store.commit('SET_ACCESS_TOKEN', body.access_token)
            this.$store.commit('SET_REFRESH_TOKEN', body.refresh_token)
            this.$store.commit('SET_USER_ID', body.user_id)
            this.$store.commit('SET_PRO_STATUS', body.pro_status)

            if (body.pro_status == 3) {
                this.$router.push({ path: '/home' })
                return
            }

            let toPlanInt = this.$store.state.toPlanInt
            if (body.pro_status == toPlanInt) {
                this.$router.push({ path: '/plans' })
                return
            }

            if (toPlanInt == 1) {
                this.$router.push({ path: '/plans/planA' })
                return
            }
            if (toPlanInt == 2) {
                this.$router.push({ path: '/plans/planB' })
                return
            }
            if (toPlanInt == 3) {
                this.$router.push({ path: '/plans/planC' })
                return
            }
        }
    }
}
</script>