<template>
    <div class="pt-web">
        <p>Enter phone number</p>
        <div style="height: 1rem"></div>
        <vue-tel-input v-model="phoneNumber" :autoFormat="true" :validCharactersOnly="true" auto
            :inputOptions="inputOptions" defaultCountry="IN" @validate="enableButton"
            mode="international"></vue-tel-input>
        <button class="fab" :disabled="isDisabled" @click="next">
            <div class="right-arrow"></div>
        </button>
    </div>
</template>
<script>
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

export default {
    data() {
        return {
            defaultCountry: 'IN',
            inputOptions: {
                id: 'phone-input',
                showDialCode: true,
            },
            isDisabled: true,
            phoneNumber: ''
        }
    },
    computed: {
        mobile: {
            get() {
                return this.$store.state.mobile
            },
            set(value) {
                this.$store.commit('SET_MOBILE', value)
            }
        },
    },
    components: {
        VueTelInput,
    },
    mounted() {
        this.focusInput()
        document.getElementsByTagName('nav')[0].style.display = 'none'
    },
    methods: {
        focusInput() {
            var input_field = document.getElementsByClassName('vti__input')
            input_field[0].type = 'tel'
            input_field[0].focus()
            input_field[0].click()
            input_field[0].addEventListener('keypress', (e) => {
                if (e.keyCode == 13) {
                    this.next()
                }
            })
        },
        enableButton(obj) {
            if (obj.valid) {
                this.isDisabled = !obj['valid']
                this.mobile = obj.number
            }

        },
        next() {
            if (!this.isDisabled) {
                this.$router.push('/pt-otp')
            }
        }
    }
}
</script>
<style>
#phone-input.vti__input {
    font-size: 1rem;
    padding: 0.8rem;
}
</style>
<style scoped>
.fab {
    height: 3rem;
    width: 3rem;
    margin-top: 1rem;
    border-radius: 2rem;
    background-color: #bdbdbd;
    cursor: pointer;
    border: none;
}

.fab:enabled {
    background-color: #0B62F1;
    -webkit-box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    box-shadow: 3px 3px 4px rgba(92, 92, 92, 0.712);
}

.right-arrow {
    display: block;
    margin: auto;
    width: 8px;
    height: 8px;
    border-top: 2px solid white;
    border-left: 2px solid white;
    transform: rotate(135deg);
}
</style>