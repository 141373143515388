<template>
    <div class="warning">Verify registration number & qualifications at <a :href="verificationLink" target="_blank">medical council website.</a></div>
</template>
<style scoped>
.warning {
    font-size: 16px;
    padding: 16px;
    width: 100%;
    border-top: 2px dotted #3a3a3a;
    margin-bottom: 4px;
    margin-top: 12px;
}
a {
    text-decoration: none;
    color: #253D63FF;
}
</style>
<script>

export default {
    computed: {
        verificationLink(){
            return 'https://www.google.com/search?q=verify+doctor+registration+number'
        }
    }
}
</script>