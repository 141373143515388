<template>
    <div :class="selected" @click="selectionClick">
        <div v-for="p in plan.options" class="duration" :key="p">
            <label class="container">
                <input type="radio" v-model="duration" :value="p.duration" :id="p.duration - p.full_price" />
                <div class="selection"></div>
                <div class="radio-text">{{ p.duration }} Months</div>
                <div class="pricing">
                    <div class="price">₹ {{ p.discounted_price }}</div>
                    <div class="actual-price">₹ {{ p.full_price }}</div>
                </div>
                <span class="checkmark"></span>
            </label>
        </div>
        <div v-if="plan.name == selectedPlan">
            <BuyNow :toPlanInt="planInt"></BuyNow>
        </div>
    </div>
</template>
<script>
import BuyNow from '@/components/BuyNow.vue';

export default {
    props: ['plan', 'planName'],
    components: {
        BuyNow
    },
    computed: {
        planInt: {
            get(){
                if(this.planName == 'Plan A'){
                    return 1
                }
                if(this.planName == 'Plan B'){
                    return 2
                }
                if(this.planName == 'Plan C'){
                    return 3
                }
                return 0
            }
        },
        duration: {
            get(){
                if (this.planName == this.selectedPlan){
                    return this.$store.state.duration
                }
                return 0            
            },
            set(value){
                this.$store.commit('SET_PLAN', this.planName)
                this.$store.commit('SET_DURATION', value)
                for( let i = 0; i < this.plan.options.length; i++ ){
                    let p = this.plan.options[i]
                    if (value == p.duration){
                        this.$store.commit('SET_PRICE', p.discounted_price)
                        this.$store.commit('SET_ACTUAL_PRICE', p.full_price)
                    }
                }
        }
        },
        selectedPlan: {
            get(){
                return this.$store.state.plan
            },
            set(value){
                this.$store.commit('SET_PLAN', value)
            }
        },
        selectedDuration: {
            get(){
                return this.$store.state.duration
            },
        },
        //to get the seleced class and make it blue background
        selected() {
            return {
                durations: true,
                selected: (this.planName == this.selectedPlan),
            }
        }
    },
}
</script>
<style scoped>
.durations {
    padding: 0;
}

.selected {
    background: #f5fdff;
}

.container {
    display: block;
    position: relative;
    padding-left: 3.2rem;
    padding-top: 2rem;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 5rem;
}

/* Hide the browser's default radio button */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}


/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 1.8rem;
    left: 1rem;
    height: 25px;
    width: 25px;
    background-color: white;
    border-radius: 50%;
    webkit-box-shadow: 0px 0px 3px 1px rgba(222, 222, 222, 1);
    -moz-box-shadow: 0px 0px 3px 1px rgba(222, 222, 222, 1);
    box-shadow: 0px 0px 3px 1px rgba(222, 222, 222, 1);
    z-index: 1;
}




/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked~.checkmark {
    border: 2px solid #0b54f1;
}

.container input:checked~.selection {
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.radio-text {
    z-index: 2;
    position: absolute;
}

.pricing {
    z-index: 2;
    position: absolute;
    right: 1rem;
    text-align: right;
    top: 1.5rem;
}

.actual-price {
    text-decoration: line-through;
    color: grey;
    font-weight: normal;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked~.checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
    top: 1.25px;
    left: 1.2px;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: #0b54f1;
}
</style>