<template>
    <div class="blog">
        <h1>About digital signatures</h1>
        <p>A digital signature is a cryptographic technique used to provide authentication, integrity, and non-repudiation for electronic documents, messages, or transactions in the digital world. It serves as a digital equivalent of a handwritten signature or a stamped seal in the physical world.</p>
<h3>Importance of Digital Signatures:</h3>
<ol>
<li>
<p><strong>Authentication</strong>: Digital signatures provide a way to verify the identity of the signer. The use of the private key ensures that only the legitimate signer can create a valid digital signature. This helps in confirming the origin of the document or message.</p>
</li>
<li>
<p><strong>Integrity</strong>: The hash value created from the original data ensures that the content has not been altered or tampered with during transmission. If any change is made to the original data, the hash value will change, rendering the signature invalid.</p>
</li>
<li>
<p><strong>Non-Repudiation</strong>: Non-repudiation means that the signer cannot deny having signed the document. Since the private key is required to create a valid digital signature, the signer cannot later claim that they didn't sign the document.</p>
</li>
<li>
<p><strong>Security</strong>: Digital signatures provide a higher level of security compared to traditional paper-based signatures. They are based on complex cryptographic algorithms that are difficult to forge or manipulate.</p>
</li>
<li>
<p><strong>Efficiency</strong>: Digital signatures streamline the signing process, eliminating the need for physical presence or shipping of documents. This is particularly important in today's global and digital business environment.</p>
</li>
<li>
<p><strong>Legal Validity</strong>: Many countries have recognized the legal validity of digital signatures through electronic signature laws and regulations. These laws provide a framework for the use of digital signatures in legal contracts and transactions.</p>
</li>
</ol>
<h3>How Digital Signatures Work:</h3>
<ol>
<li>
<p><strong>Hashing</strong>: A digital signature process typically starts by creating a hash (a fixed-length string of characters) of the original data using a cryptographic hash function. The hash function takes the input data and produces a unique hash value that represents the data.</p>
</li>
<li>
<p><strong>Private Key Encryption</strong>: The hash value is then encrypted using the private key of the signer. This encrypted hash value is the digital signature itself. The private key is a part of the asymmetric key pair (public-private key pair) that the signer possesses.</p>
</li>
<li>
<p><strong>Attaching to Document</strong>: The digital signature is attached to the electronic document, message, or transaction in a way that associates the signature with the content it is signing. This could be achieved through various means, such as embedding the signature within the document or attaching it as a separate file.</p>
</li>
</ol>
<p>Confused? Here is Khan academy video about digital signatures. <i>Disclaimer: STAT is in no way associated with Khan Academy or youtube, and Is not responsible for any ads displayed</i></p>
<br />
<iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/Aq3a-_O2NcI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
<br />
<p>In summary, digital signatures play a crucial role in ensuring the authenticity, integrity, and non-repudiation of electronic documents and transactions. They are fundamental to secure online communication, e-commerce, and various other digital processes where trust and security are paramount.</p>
    
    </div>
    <Footer></Footer>
</template>
<style>
iframe[src*=youtube] {
                    display: block;
                    margin: 0 auto;
                    max-width: 100%;
                    padding-bottom: 10px;
                }

.blog {
    text-align: left;
    max-width: 750px;
    margin: 0 auto;
    padding: 10px;
    padding-left: 25px;
}
.blog p {
    margin-bottom: 0.5rem;
    font-family: Georgia,Times,Times New Roman,serif;
    line-height: 1.5;
    color: #000;
    font-size: 20px;
}
p strong {
    color: #3a3a3a;
    font-family: "Atkinson Hyperlegible", sans-serif;
}
.blog h1 {
    margin: 2rem 0;
}

.blog h3  {
    margin-bottom: 0.5rem;
    margin-top: 1rem;
}
.blog .youtube {
    margin: 0 auto;
}
</style>
<script>
import Footer from '@/components/CFooter.vue'
export default {
    components : {
        Footer
    },
}
</script>
