<template>
    <div class="terms">
<h1 id="terms">Shipping policy</h1>
<p>STAT is an online tool used on your device, hence shipping & delivery are not applicable.</p>
</div>
</template>
<style scoped>
.terms {
    text-align: left;
    line-height: 1.4;
}
h1 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
}

h2 {
    margin-top: 0.5rem;
    font-size: 1.2rem;
}
ul {
    margin-left: 40px;
}
</style>