import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";


const firebaseConfig = {
    apiKey: "AIzaSyBxEkb2k7BaxwM2cSCcRmiiSKsbG3RBgE8",
    authDomain: "stat-v2.firebaseapp.com",
    projectId: "stat-v2",
    storageBucket: "stat-v2.appspot.com",
    messagingSenderId: "967822733960",
    appId: "1:967822733960:web:043548f36f93bf57f1a53d",
    measurementId: "G-3922S8R42Y"
  };

const fbApp = initializeApp(firebaseConfig)
const auth = getAuth(fbApp)
auth.languageCode = "en";
auth.settings.appVerificationDisabledForTesting = false;


export { auth }