<template>
    <div class="work1">
        <div class="name custom-color">{{ name }}</div>
        <div class="qualifications custom-color">{{ qualifications }}</div>
        <div class="local-spacer"></div>
        <div class="designation custom-color" v-if="work.designation">{{ work.designation }}</div>
        <a :href="googleMapUrl" class="address" target="_blank">{{ work.name }} - {{ work.address }}
            <span class="link-highlight custom-color"> Get&nbsp;Directions&nbsp;<span
                    class="icon">directions</span></span></a>
        <div class="local-spacer-1"></div>
        <div class="time-and-phone">
            <div v-if="work.phone_number">
                <a :href="phoneUrl" class="phone"><span class="icon">phone</span> {{ work.phone_number }}</a>
            </div>
            <div class="time">
                <DisplayTimings :sessiontimings="work.timings"></DisplayTimings>
            </div>
        </div>
    </div>
</template>
<script>
import DisplayTimings from './DisplayTiming.vue'

export default {
    props: ["doctor"],
    components: {
        DisplayTimings
    },
    data() {
        return {
            work: this.doctor.work[0],
            name: this.doctor.is_doctor ? "Dr. " + this.doctor.name : this.doctor.name,
            qualifications: this.doctor.qualifications,
        }
    },
    computed: {
        googleMapUrl(){
            return "https://maps.google.com/?q=" + this.work.latitude + "," + this.work.longitude
        },
        phoneUrl(){
            return "tel:" + this.work.phone_number
        }
    }
}
</script>
<style scoped>
.time {
    font-weight: normal;
}
.local-spacer {
    height: 12px;
}
.local-spacer-1 {
    height: 6px;
}

.designation {
    color: #5a5a5a;
    font-weight: normal;
    font-weight: bold;
}
.work1 {
    width: 100%;
    padding: 16px;
    padding-bottom: 12px;
    margin: 0;
    border-bottom: 2px dotted #3a3a3a;
    text-align: left;
    line-height: 1.2;
}
a {
    text-decoration: none;
    color: #3a3a3a;
    font-weight: normal;
}

.time-and-phone {
    display: flex;
    column-gap: 16px;
}
@media only screen and (max-width: 768px) {
    .work1 {
        padding: 8px;
    }
}
</style>