import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VOtpInput from "vue3-otp-input";

const app = createApp(App)
app.config.compilerOptions.isCustomElement = (tag) => tag.includes('stripe-pricing-table')


app.use(store).use(router).component('v-otp-input', VOtpInput).mount('#app')
