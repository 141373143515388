import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store'
import HomeView from '../views/HomeView.vue'
import PlanView from '../views/PlansView.vue'
import LoginView from '../views/LoginView.vue'
import OtpView from '../views/OtpView.vue'
import PlanAView from '../views/plan-detail-views/plan-a.vue'
import PlanBView from '../views/plan-detail-views/plan-b.vue'
import PlanCView from '../views/plan-detail-views/plan-c.vue'
import LoggedInView from '../views/LoggedInView.vue'
import NotFound from '../views/NotFoundView.vue'
import Contact from '../views/ContactView.vue'
import Careers from '../views/CareersView.vue'
import Desktop from '../views/DesktopAppView.vue'
import Appointment from '../views/appointment-views/LocationView.vue'
import AppointmentSlot from '../views/appointment-views/SlotView.vue'
import PhoneNumber from '../views/patinet-onboarding/PhonenumberView.vue'
import PtOtp from '../views/patinet-onboarding/OtpView.vue'
import SelectProfile from '../views/patinet-onboarding/SelectProfile.vue'
import PatientHome  from '../views/patient-views/PatientHome.vue'
import PatientContactDetails from '../views/patinet-onboarding/ContactDetails.vue'
import PatientComorbidities from '../views/patinet-onboarding/ComorbiditiesView.vue'
import Rx from '../views/prescription-views/RxView.vue'
import RxPassword from '../views/prescription-views/RxPasswordView.vue'
import TermsView from '../views/legal/TermsView.vue'
import RefundView from '../views/legal/RefundView.vue'
import PrivacyView from '../views/legal/PrivacyView.vue'
import ShippingView from '../views/legal/ShippingView.vue'
import AboutView from '../views/AboutView.vue'
import AboutDigitalSignature from '../views/blog/about-digital-sigature.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/rx/:id',
    name: 'Rx Password',
    component: RxPassword,
    meta: {
      hideNavbar: true,
     }
  },
  {
    path: '/rx/:id/:password',
    name: 'rx',
    component: Rx,
    meta: {
      hideNavbar: true,
     }
  },
  {
    path: '/medical-info',
    name: 'medical-info',
    component: PatientComorbidities,
  },
  {
    path: '/contact-information',
    name: 'Patient Contact Details',
    component: PatientContactDetails,
    meta: {
      requiresPatientLogin: true
    }
  },
  {
    path: '/patient-home',
    name: 'Patient Home',
    components: PatientHome,
    meta: {
      requiresPatientLogin: true
    }
  },
  {
    path: '/select-profile',
    name: 'Select Profile',
    component: SelectProfile,
    meta: {
      requiresPatientLogin: true
    }
  },
  {
    path: '/pt-otp',
    name: 'pt-otp',
    component: PtOtp
  },
  {
    path: '/phone-number',
    name: 'phonenumber',
    component: PhoneNumber
  },
  {
    path: '/select-slot',
    name: 'SelectSlot',
    component: AppointmentSlot
  },
  {
    path: '/book-appointment',
    name: 'BookAppointment',
    component: Appointment
  },
  {
    path: '/update',
    name: 'update',
    component: Desktop
  },
  {
    path: '/desktop',
    name: 'desktop',
    component: Desktop
  },
  {
    path: '/careers',
    name: 'careers',
    component: Careers
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/',
    name: 'landingPage',
    component: HomeView
  },
  {
    path: '/plans',
    name: 'plans',
    component: PlanView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/otp',
    name: 'otp',
    component: OtpView
  },
  {
    path: '/plans/planA',
    name: 'planA',
    component: PlanAView,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/plans/planB',
    name: 'planB',
    component: PlanBView,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/plans/planC',
    name: 'planC',
    component: PlanCView,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/home',
    name: 'home',
    component: LoggedInView,
    meta: {
      requiresLogin: true
    }
  },
  {
    path:'/terms',
    name: 'terms',
    component: TermsView,
  },
  {
    path: "/privacy",
    name: 'privacy',
    component: PrivacyView,
  },
  {
    path: "/refund",
    name: 'refund',
    component: RefundView,
  },
  {
    path: "/shipping",
    name: 'shipping',
    component: ShippingView,
  },
  {
    path: "/about",
    name: 'about',
    component: AboutView,
  },
  {
    // path: "*",
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
  }, 
  {
    path: "/blog/about-digital-signature",
    component: AboutDigitalSignature,
    name: "AboutDigitalSignature",
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresLogin)) {
    if(store.state.accessToken.length > 1){
      next()
    }else{
      next({path: '/login'})
    }
  } else if (to.matched.some(record => record.meta.requiresPatientLogin)){
    if(store.state.patient){
      next()
    }else{
      next({path: '/phone-number'})
    }
  }  
  else {
    next()
  }
})

export default router
