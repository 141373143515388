<template>
    <div id="carousel">
        <div id="slide-container">
            <div class="slide" data-slideIndex="0">
                <img width="1200" height="600" src="../assets/stat-mobile.png" alt="stat-mobile">
            </div>
            <div class="slide" data-slideIndex="1">
                <img width="1200" height="600" src="../assets/ipad.png" alt="stat-table-android-ipad">
            </div>
            <div class="slide" data-slideIndex="2">
                <img width="1200" height="600" src="../assets/desktop_pt.png" alt="stat-desktop" id="desktop-img">
            </div>
        </div>
        <div class="slide-indicators">
            <div class="slide-indicator active-icon"><span class="material-symbols-outlined icon">smartphone</span></div>
            <div class="slide-indicator"><span class="material-symbols-outlined icon">tablet_mac</span></div>
            <div class="slide-indicator"><span class="material-symbols-outlined icon">desktop_windows</span></div>
        </div>
    </div>
</template>
<script>
export default {
    mounted() {
        function autoplayCarousel() {
            const carouselEl = document.getElementById("carousel");
            const slideContainerEl = carouselEl.querySelector("#slide-container");
            const slideEl = carouselEl.querySelector(".slide");
            let slideWidth = slideEl.offsetWidth;
            // Add click handlers
            // document.querySelector("#back-button")
            //     .addEventListener("click", () => navigate("backward"));
            // document.querySelector("#forward-button")
            //     .addEventListener("click", () => navigate("forward"));
            document.querySelectorAll(".slide-indicator")
                .forEach((dot, index) => {
                    dot.addEventListener("click", () => navigate(index));
                    dot.addEventListener("mouseenter", () => clearInterval(autoplay));
                });
            // Add keyboard handlers
            document.addEventListener('keydown', (e) => {
                if (e.code === 'ArrowLeft') {
                    clearInterval(autoplay);
                    navigate("backward");
                } else if (e.code === 'ArrowRight') {
                    clearInterval(autoplay);
                    navigate("forward");
                }
            });
            // Add resize handler
            window.addEventListener('resize', () => {
                slideWidth = slideEl.offsetWidth;
            });
            // Autoplay
            const autoplay = setInterval(() => navigate("forward"), 3000);
            slideContainerEl.addEventListener("mouseenter", () => clearInterval(autoplay));
            // Slide transition
            const getNewScrollPosition = (arg) => {
                const gap = 10;
                const maxScrollLeft = slideContainerEl.scrollWidth - slideWidth;
                if (arg === "forward") {
                    const x = slideContainerEl.scrollLeft + slideWidth + gap;
                    return x <= maxScrollLeft ? x : 0;
                } else if (arg === "backward") {
                    const x = slideContainerEl.scrollLeft - slideWidth - gap;
                    return x >= 0 ? x : maxScrollLeft;
                } else if (typeof arg === "number") {
                    const x = arg * (slideWidth + gap);
                    return x;
                }
            }
            const navigate = (arg) => {
                slideContainerEl.scrollLeft = getNewScrollPosition(arg);
            }
            // Slide indicators
            const slideObserver = new IntersectionObserver((entries, observer) => {
                console.log(observer);
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const slideIndex = entry.target.dataset.slideindex;
                        carouselEl.querySelector('.slide-indicator.active-icon').classList.remove('active-icon');
                        carouselEl.querySelectorAll('.slide-indicator')[slideIndex].classList.add('active-icon');
                    }
                });
            }, { root: slideContainerEl, threshold: .1 });
            document.querySelectorAll('.slide').forEach((slide) => {
                slideObserver.observe(slide);
            });
        }
        autoplayCarousel();

    }
}
</script>
<style scoped>
.icon{
    font-size: 24px;
    color: grey;
}
#desktop-img {
    width: 100%;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

#carousel {
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    position: relative;
    height: 100%;
}

.slide-indicators {
    display: flex;
    justify-content: center;
    column-gap: 9px;
    margin-top: 18px;
}

.slide-indicator {
    height: 44px;
    width: 50px;
    display: flex;
    justify-items: center;
    cursor: pointer;
    /* border: 2px solid #0b54f1; */
    padding: 9px 11px;
    border-radius: 2px;
}

/* .slide-indicator:after {
    content: "";
    background-color: #878787;
    height: 10px;
    margin-top: 10px;
    width: 40px;
} */

.active-icon {
    border-bottom: 2px solid grey;
}

/* .active-icon .icon {
    color: #ffbf00;
} */

#slide-container {
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    align-items: center;
    height: 100%;
    gap: 10px;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

#slide-container::-webkit-scrollbar {
    display: none;
}

.slide {
    scroll-snap-align: center;
    position: relative;
    min-width: 100%;
    padding-top: 50%;
    height: 600px;
}

.slide img {
    height: 100%;
    width: auto;
    position: absolute;
    top: 0;
    left: 0;
    left: 50%;
    transform: translate(-50%, 0);
}

.arrow {
    color: #ffffff;
    height: 20px;
    width: 20px;
    background-color: #000000;
    position: absolute;
    padding: 10px;
    opacity: .3;
    cursor: pointer;
}

.arrow.back {
    left: 10px;
    top: 10px;
}

.arrow.forward {
    right: 10px;
    top: 10px;
}

.arrow:hover {
    opacity: 1;
}
@media screen and (max-width: 997px) {
    #carousel {
        display: none;
    }
}
</style>