<template>
    <div class="work2">
        <div class="name custom-color">{{ name }}</div>
        <div class="qualifications custom-color">{{ qualifications }}</div>
        <div class="local-spacer"></div>
        <div class="works-holder">
            <div class="work-one">
                <div class="designation custom-color">{{ work1.name }}</div>
                <a :href="googleMapUrl1" class="address" target="_blank">{{work1.designation}}<br/>{{ work1.address }} 
                    <span class="link-highlight custom-color"> Get&nbsp;Directions&nbsp;<span
                            class="icon">directions</span></span></a>
                <div class="local-spacer-1"></div>
                <div class="time-and-phone">
                    <div v-if="work1.phone_number">
                        <a :href="phoneUrl1" class="phone"><span class="icon">phone</span> {{ work1.phone_number }}</a>
                    </div>
                    <div class="time">
                        <DisplayTimings :sessiontimings="work1.timings"></DisplayTimings>
                    </div>
                </div>
            </div>
            <div class="work-two">
                <div class="designation custom-color">{{ work2.name }}</div>
                <a :href="googleMapUrl2" class="address" target="_blank">{{ work2.designation }}<br/>{{ work2.address }} 
                    <span class="link-highlight custom-color"> Get&nbsp;Directions&nbsp;<span
                            class="icon">directions</span></span></a>
                <div class="local-spacer-1"></div>
                <div class="time-and-phone">
                    <div v-if="work2.phone_number">
                        <a :href="phoneUrl2" class="phone"><span class="icon">phone</span> {{ work2.phone_number }}</a>
                    </div>
                    <div class="time">
                        <DisplayTimings :sessiontimings="work2.timings"></DisplayTimings>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DisplayTimings from './DisplayTiming.vue'

export default {
    props: ["doctor"],
    components: {
        DisplayTimings
    },
    data() {
        return {
            work1: this.doctor.work[0],
            work2: this.doctor.work[1],
            name: this.doctor.is_doctor ? "Dr. " + this.doctor.name : this.doctor.name,
            qualifications: this.doctor.qualifications,
        }
    },
    computed: {
        googleMapUrl1() {
            return "https://maps.google.com/?q=" + this.work1.latitude + "," + this.work1.longitude
        },
        phoneUrl1() {
            return "tel:" + this.work1.phone_number
        },
        googleMapUrl2() {
            return "https://maps.google.com/?q=" + this.work2.latitude + "," + this.work2.longitude
        },
        phoneUrl2() {
            return "tel:" + this.work2.phone_number
        }
    }
}
</script>
<style scoped>
.works-holder {
    display: flex;
    column-gap: 12px;
    row-gap: 12px;
}
.work-one, .work-two {
    flex: 1;
}
.time {
    font-weight: normal;
}

.local-spacer {
    height: 12px;
}

.local-spacer-1 {
    height: 6px;
}
/* 
.name {
    font-family: 'Zodiak-Bold';
    font-size: 24px;
    color: #253D63FF;
}

.qualifications {
    font-family: 'Zodiak-Bold';
    color: #253D63FF;
}

.designation {
    color: #253D63FF;
    font-weight: normal;
} */

.work2 {
    width: 100%;
    padding: 16px;
    padding-bottom: 12px;
    margin: 0;
    border-bottom: 2px dotted #3a3a3a;
    text-align: left;
    line-height: 1.2;
}

a {
    text-decoration: none;
    color: #3a3a3a;
    font-weight: normal;
}

.time-and-phone {
    display: flex;
    flex-direction: column;
    column-gap: 16px;
    row-gap: 3px;
}
@media only screen and (max-width: 600px) {
  .address, .phone, .time {
    font-size: 14px;
  }
}

@media only screen and (max-width: 400px) {
.time {
    font-size: 13px;
  }
}

@media only screen and (max-width: 400px) {
.time {
    font-size: 13px;
  }
}
@media only screen and (max-width: 768px) {
    .work2 {
        padding: 8px;
    }
}
</style>