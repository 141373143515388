import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";


export default createStore({
  state: {
    duration: 0,
    plan: '',
    price: 0,
    actualPrice: 0,
    phoneNumber: '+91 8971830061',
    email: 'support@stat.org.in',
    whatsApp: '918971830061',
    mobile: '',
    countryCode: '+91',
    proStatus: 0,
    userId: '',
    otp: '',
    accessToken: '',
    refreshToken: '',
    baseUrl: "https://api.stat.org.in",
    toPlanInt: 0,
    user: null,
    appointmentLocation: null,
    patientProfiles: null,
    patient: null,
    tokens: null,
  },
  getters: {
    
  },
  mutations: {
    SET_DURATION(state, duration){
      state.duration = duration
    },
    SET_PLAN(state, plan){
      state.plan = plan
    },
    SET_PRICE(state, price){
      state.price = price
    },
    SET_ACTUAL_PRICE(state, actualPrice){
      state.actualPrice = actualPrice
    },
    SET_MOBILE(state, mobile){
      state.mobile = mobile
    },
    SET_COUNTRY_CODE(state, countryCode){
      state.countryCode = countryCode
    },
    SET_PRO_STATUS(state, proStatus){
      state.proStatus = proStatus
    },
    SET_OTP(state, otp){
      state.otp = otp
    },
    SET_ACCESS_TOKEN(state, token){
      state.accessToken = token
    },
    SET_REFRESH_TOKEN(state, token){
      state.refreshToken = token
    },
    SET_USER_ID(state, userId){
      state.userId = userId
    },
    SET_TO_PLAN_INT(state, toPlanInt){
      state.toPlanInt = toPlanInt
    },
    SET_BASE_URL(state, baseUrl){
      state.baseUrl = baseUrl
    },
    SET_USER(state, user){
      state.user = user
    },
    SET_APPOINTMENT_LOCATION(state, location){
      state.appointmentLocation = location
    },
    SET_PATIENT_PROFILES(state, profiles){
      state.patientProfiles = profiles
    },
    SET_PATIENT(state, patient){
      if(!patient.comorbidities){
        patient.comorbidities = []
      }
      state.patient = patient
    },
    SET_TOKENS(state, tokens){
      state.tokens = tokens
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()],
})
