<template>
    <div v-if="advice" class="advice-block">
        <div class="heading">Advice</div>
        <div class="advice">{{ advice }}</div>
    </div>
</template>
<script>
export default {
    props: ['advice']
}
</script>
<style scoped>
.advice-block {
    display: flex;
    column-gap: 4px;
    align-items: baseline;
}
.heading {
    font-weight: bold;
}
</style>