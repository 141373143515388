<template>
    <div v-if="diagnosis" class="diagnosis"><span class="diagnosis-label">Diagnosis/Impression</span> {{ diagnosis }}</div>
</template>
<script>
export default {
    props: ['diagnosis']
}
</script>
<style>
.diagnosis {
    margin-bottom: 1rem;
}
.diagnosis-label {
    background-color: rgb(255, 216, 20);
    font-weight: bold;
    padding: 2px 4px;
    color: black;
}
</style>