<template>
  <nav v-if="!$route.meta.hideNavbar">
    <div class="left-align">
      <router-link to="/" id="logo-holder"><img alt="Stat logo" src="./assets/stat-logo.svg" id="logo" /></router-link>
      <div class="right-align top-padding">
        <router-link to="/plans">Plans</router-link>
        <router-link to="/careers">Careers</router-link>
        <router-link to="/contact">Contact</router-link>
        <router-link to="/about">About</router-link>

      </div>
    </div>
    <div class="right-align">
      <a href="https://apps.apple.com/in/app/stat-prescription-maker/id1526714795">iPhone</a>
      <a href="https://play.google.com/store/apps/details?id=com.milieu.prescription">Andorid</a>
      <a href="/desktop">Desktop</a>
    </div>
    <div class="menu-icon" @click="toggleMenu">
      <span class="material-symbols-outlined">{{ menuString }}</span>
    </div>
  </nav>
  <div :class="mobileMenuClass">
    <router-link to="/" @click="toggleMenu">Home</router-link>
    <router-link to="/about" @click="toggleMenu">Plans</router-link>
    <router-link to="/careers" @click="toggleMenu">Careers</router-link>
    <router-link to="/contact" @click="toggleMenu">Contact</router-link>
    <router-link to="/about">About</router-link>
  </div>
  <div id="content">
    <router-view />
  </div>

</template>
<script>
export default {
  data() {
    return {
      menuString: "menu",
      mobileMenuClass: "mobile-menu-hidden"
    };
  },
  methods: {
    toggleMenu: function () {
      if (this.menuString == "menu") {
        this.menuString = "close";
        this.mobileMenuClass = "mobile-menu";
      } else {
        this.menuString = "menu";
        this.mobileMenuClass = "mobile-menu-hidden";
      }
    },
    contact: function () {
      this.$router.push('/contact')
    }
  },
}
</script>
<style>

@font-face {
    font-family: 'Allison';
    src: url('./assets/allison.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'zodiac';
    src: url('./assets/fonts/Zodiak-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'zodiac-bold';
    src: url('./assets/fonts/Zodiak-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'zodiac';
    src: url('./assets/fonts/Zodiak-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'atkinson';
    src: url('./assets/fonts/Atkinson-Hyperlegible-Bold-102.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'atkinson';
    src: url('./assets/fonts/Atkinson-Hyperlegible-Regular-102.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Material Icons';
    src: url('./assets/fonts/MaterialIcons-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Allison';
    src: url('./assets/fonts/signature/allison.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Autograf';
    src: url('./assets/fonts/signature/autograf.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Hijrnotes';
    src: url('./assets/fonts/signature/hijrnotes.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Notera';
    src: url('./assets/fonts/signature/MrsSaintDelafield-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Reey';
    src: url('./assets/fonts/signature/reey.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Signaturica';
    src: url('./assets/fonts/signature/signaturica.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  overflow-x: hidden;
}

div, p, h1 {
  color: #3a3a3a;
}

#app {
  font-family: "Atkinson Hyperlegible", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000;
  margin: 0 auto;
  font-size: 18px;
  padding-top: 73px;
  height: 100%;
}

#content, .pt-web {
  max-width: 1200px;
  margin: auto;
  height: 100%;
}

.pt-web {
  text-align: left;
  padding: 1rem;
}

#logo-holder:hover {
  border: none;
}

#logo {
  height: 32px;
}

nav {
  padding: 18px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  top: 0;
  margin: 0 auto;
  background: white;
  z-index: 100;

}

nav a,
.mobile-menu a {
  color: #0b54f1;
  text-decoration: none;
  margin: 0 16px;
  font-size: 0.9rem;
  font-weight: bold;
}

nav a:hover {
  border-bottom: 2px solid #ffbf00;
}

nav a.router-link-exact-active {
  color: #000;
}

nav a.router-link-exact-active:hover {
  border: none;
}

.menu-icon,
.mobile-menu-hidden {
  display: none;
}

.material-symbols-outlined {
  font-size: 28px;
  font-variation-settings: "FILL" 0, "wght" 500, "GRAD" 0, "opsz" 48;
}

.left-align {
  display: flex;
}

.top-padding {
  padding-top: 8px;
}

.full-width {
  background: #ffbf00;
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
}

.header-button {
  height: 100%;
  padding: 8px;
  background-color: white;
  color: #0b54f1;
  font-weight: bold;
  border: 1px solid #0b54f1;
  border-radius: 4px;
  cursor: pointer;
}

.header-button:hover {
  background: #0b54f1 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%;
  color: white;
}
.header-button:active {
  background-color: #0b54f1;
  background-size: 100%;
  transition: background 0s;
}
.app-link {
  padding-top: 8px;
  height: 36px;
}

.app-link-ios {
  height: 36px;
}

.icon {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 12px;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    color: #4267B2;
    margin-bottom: -1px;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

.fab {
    height: 3rem;
    width: 3rem;
    margin-top: 1rem;
    border-radius: 2rem;
    background-color: #bdbdbd;
    cursor: pointer;
    border: none;
}

.fab:enabled {
    background-color: #0B62F1;
    -webkit-box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    box-shadow: 3px 3px 4px rgba(92, 92, 92, 0.712);
}

.right-arrow {
    display: block;
    margin: auto;
    width: 8px;
    height: 8px;
    border-top: 2px solid white;
    border-left: 2px solid white;
    transform: rotate(135deg);
}

/* for rx view */
.rx-container {
  font-size: 14px;
  font-family: 'atkinson' !important;
}

.rx-container a {
  color: #000 !important;
}


.rx-container .name {
  font-family: 'zodiac-bold';
  font-weight: bold;
  line-height: 1.2;
  color: #253D63FF;
  font-size: 16px;
}

.qualifications {
    font-family: 'zodiac-bold';
    color: #253D63FF;
    font-weight: bold;
}

.rx-container .link-highlight {
    color: #35528e;
}



@media screen and (max-width: 600px) {
  html, body {
    width: 100%;
    overflow-x: clip;
  }
  .menu-icon {
    display: block;
  }

  .right-align {
    display: none;
  }

  nav {
    padding: 8px;
  }

  #logo-holder {
    margin: 0;
  }

  #app {
    padding-top: 50px;
  }

  .full-width {
  background: transparent;
  width: 100%;
  margin-left: 0;
  transform: none;
}

  .mobile-menu {
    position: fixed;
    width: 100%;
    background: beige;
    height: 100%;
    padding-top: 18px;
  }

  .mobile-menu a {
    display: block;
    text-align: center;
    padding: 18px 0;
    border: 2px solid #0b54f1;
    margin-top: 2px;
    background: white;
  }
}
</style>
