<template>
    <div>
        <div v-if="e.has_vitals"><strong>Vitals</strong></div>
        <div class="vitals" v-if="e.has_vitals">
            <div v-if="e.pulse_rate || e.pulse_patter" class="no-break">
                <div v-if="e.pulse_rate"><span class="vital">Pulse Rate</span> {{ e.pulse_rate }} bpm</div>
                <div v-if="e.pulse_patter"><span class="vital">Pulse Pattern</span> {{ e.pulse_patter }}</div>
            </div>
            <div v-if="e.systolic_bp || e.diastolic_bp" class="no-break">
                <span class="vital">BP</span> {{ e.systolic_bp }}/{{ e.diastolic_bp }} mmHg
            </div>
            <div v-if="e.resp_rate || e.resp_pattern" class="no-break">
                <div v-if="e.resp_rate"><span class="vital">Resp Rate</span> {{ e.resp_rate }} cycles/min</div>
                <div v-if="e.resp_pattern"><span class="vital">Resp Pattern</span> {{ e.resp_pattern }}</div>
            </div>
            <div v-if="e.temp" class="no-break"><span class="vital">Temp</span> {{ e.temp }} {{ e.temp_unit }}</div>
            <div v-if="e.sp02" class="no-break"><span class="vital">SpO2</span> {{ e.sp02 }}%</div>
            <div v-if="e.rbs" class="no-break"><span class="vital">RBS</span> {{ e.rbs }} mg/dl</div>
        </div>
        <div v-if="ht"><strong>Height</strong> {{ ht }} <span class="rec-date">recorded on {{ htDate }}</span></div>
        <div v-if="wt"><strong>Weight</strong> {{ wt }} <span class="rec-date">recorded on {{ wtDate }}</span>
        </div>
        <div class="flex" v-if="e.examination">
            <div><strong>Examination </strong></div>
            <div>{{ e.examination }}</div>
        </div>
    </div>
</template>
<style scoped>
.rec-date {
    color: #7a7a7a;
}

.flex {
    display: flex;
    white-space: pre;
    margin-bottom: 16px;
}

.vitals {
    column-count: 2;
    margin-bottom: 16px;
}

.vital::after {
 content: ":";
}
.no-break {
    break-inside: avoid-column;
}
</style>
<script>
export default {
    props: ['rx'],
    data() {
        return {
            ht: null,
            wt: null,
            htDate: null,
            wtDate: null
        }
    },
    created() {

        if (this.rx.doctor.membership.pro_status < 2) {
            return
        }

        if (this.rx.doctor.customizations.htWt == false) {
            return
        }

        if (this.rx.patient.ht_wt_records < 1) {
            return
        }

        let toSort = this.rx.patient.ht_wt_records
        toSort.sort(function (a, b) {
            return a.time_stamp.T - b.time_stamp.T
        })
        for (let i = 0; i < toSort.length; i++) {
            let r = toSort[i]
            if (r.height) {
                this.ht = r.height + " " + r.heightUnit
                this.htDate = this.getDateFromUnixTimeStamp(r.time_stamp.T)
                break
            }
        }
        for (let i = 0; i < toSort.length; i++) {
            let r = toSort[i]
            if (r.weight) {
                this.wt = r.weight + " " + r.weightUnit
                this.wtDate = this.getDateFromUnixTimeStamp(r.time_stamp.T)
                break
            }
        }
    },
    computed: {
        e() {
            return this.rx.examination
        },
    },
    methods: {
        getDateFromUnixTimeStamp(time_stamp) {
            let d = new Date(time_stamp * 1000)
            let day = d.getDate()
            let month = d.getMonth()
            let year = d.getFullYear()
            let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            return day + ' ' + months[month] + ' ' + year
        }
    }
}
</script>