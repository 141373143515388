<template>
    <div class="signature-holder">
        <div class="signature">
        <div v-if="isSignUrl"><img id="sign-placeholder" class="sign-img" :src="signData" alt="verifiying signature"></div>
        <div v-else class="font-sign" :style="cssFontStyle">{{ name }}</div>
        <div class="stamp custom-color">
            {{ signName }}<br/>
            {{ qualifications }}<br/>
            {{ registration }}
        </div>
    </div>
    </div>
</template>
<script>
export default {
    props: ['rx'],
    data(){
        return {
            signData: ""
        }
    },
    computed: {
        isSignUrl(){
            if(this.rx.doctor.display_signature.signature_url){
                return true
            }
            return false
        },
        name(){
            if(this.rx.doctor.display_signature.signature_name){
                return this.rx.doctor.display_signature.signature_name
            }
            return this.rx.doctor.name
        },
        signName(){
            let name = ""
            if(this.rx.doctor.display_signature.signature_name){
                name = this.rx.doctor.display_signature.signature_name
            }else {
                name = this.rx.doctor.name
            }

            if(this.rx.doctor.is_doctor){
                return 'Dr. ' + name
            }
            return name
        },
        signFont(){
            if(this.rx.doctor.display_signature.signature_font){
                return this.rx.doctor.display_signature.signature_font
            }
            return 'Allison'
        },
        cssFontStyle(){
            return "font-family: " + this.signFont + ';'
        },
        qualifications(){
            return this.rx.doctor.qualifications
        },
        registration(){
            return this.rx.doctor.medical_council + ' ' + this.rx.doctor.reg_no
        },
    },
    methods: {
        async signUrl(){
            if(this.rx.doctor.membership.pro_status > 0){
                let url = this.rx.doctor.display_signature.signature_url
                if(url){
                    let resposne = await fetch(url)
                    let blob = await resposne.blob()
                    let txt = await blob.text()
                    this.signData = "data:image/png;base64, " + txt
                }
            }
        }
    },
    mounted(){
        this.signUrl()
    }
}
</script>
<style scoped>

#sign-placeholder{
    max-width: 100px;
}
.stamp {
    color: #253D63FF;
    font-family: 'zodiac-bold';
    line-height: 1.1;
    font-size: 13px;
    font-weight: bold;
}
.signature-holder {
    display: flex;
    justify-content: flex-end;
}
.signature {
    margin-right: 40px;
    text-align: center;
}
.font-sign{
    font-size: 24px;
}
</style>