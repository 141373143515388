<template>
    <table class="bill">
        <tr>
            <th></th>
            <th>Item</th>
            <th class="center-text">Qty</th>
            <th class="center-text">Rate</th>
            <th style="text-align: right;">Total</th>
        </tr>
        <tr v-for="(charge, index) in charges" v-bind:key="charge.id" class="charge">
            <td class="center-text bold">{{ index + 1 }}</td>
            <td class="bold">{{ charge.name }}</td>
            <td class="center-text">{{ charge.quantity }}</td>
            <td class="center-text">{{ charge.currency }}{{ charge.amount }}</td>
            <td style="text-align: right;">{{ charge.currency }}{{ charge.total }}</td>
        </tr>
        <tr>
            <td colspan="5" class="display-amount">Amount Payable
                {{ displayAmount }}
            </td>
        </tr>
    </table>
</template>
<script>
export default {
    props: ['rx'],
    computed: {
        charges() {
            return this.rx.charges
        },
        displayAmount() {
            return this.rx.order.displayAmount
        }
    }
}
</script>
<style scoped>
.bill {
    border: 0;
    margin: 0 auto;
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.bill th, .bill td {
    padding: 4px;
    vertical-align: top;
}

th {
    text-align: left;    vertical-align: top;
    overflow-y: hidden;
    background-color: #4e4e4ead;
    color: white;
    font-weight: bold;
}
.bill tr:nth-child(odd) {
    background-color: rgba(125, 125, 125, 0.2);
}
.center-text {
    text-align: center;
}

.bold {
    font-weight: bold;
}
.display-amount {
    text-align: right; 
    font-weight: bold; 
    color: #9E0000;
}

@media only screen and (max-width: 768px) {
.bill th, .bill td {
    padding: 2px;
}

.bill {
    font-size: 14px;
}

}

</style>