<template>
    <div class="flex" v-if="rx.complaints">
        <div><strong>Complaints </strong></div>
        <div>{{ rx.complaints }}</div>
    </div>
    <div class="flex" v-if="rx.history">
        <div class="local-title"><strong>History </strong></div>
        <div>{{ rx.history }}</div>
    </div>
</template>
<style scoped>
.flex {
    display: flex;
    white-space: pre;
    margin-bottom: 16px;
}
</style>
<script>
export default {
    props: ["rx"],
}
</script>