<template>
    <div class="pt-web">
        <p><strong>Enter details</strong></p>
        <p class="explanation">This helps your doctor to recognise you and organize your health records.</p>
        <div style="height: 2rem"></div>

        <p>Full Name</p>
        <input type="text" class="inputName" autofocus v-model="patient.name" placeholder="Rahul Sharma"
            @keyup.enter="shiftFocus" />
        <div style="height: 2rem"></div>

        <p>Date of Birth</p>
        <input 
        type="tel" 
        v-model="DOB" 
        placeholder="DD/MM/YYYY" 
        @blur="checkDate"
        @focus="removeError" 
        @keyup.backspace="clearDOB"
        @keyup.delete="clearDOB"
        ref="DOB" />
        
        <div style="height: 2rem"></div>
        <p>Gender</p>
        <div class="display: flex; flex-direction: row">
            <button @click="maleSelect" :class="malebutton" style="border-right: 0" id='selctorButton'>Male</button>
            <button @click="femaleSelect" :class="femalebutton" id='selctorButton2'>Female</button>
        </div>

        <div style="height: 2rem"></div>
        <p>Email</p>
        <input type="email" v-model="patient.email" placeholder="rsharma@gmail.com"
            @keyup.enter="shiftFocus2" />

            <div style="height: 2rem"></div>
        <p>Address</p>
        <textarea v-model="patient.address" placeholder="25 K.G. Marg, Jeevan Prakash Building, New Delhi. 110001"></textarea>  
        <br />
        <button class="fab" :disabled="isDisabled" @click="next">
            <div class="right-arrow"></div>
        </button>    
    </div>
</template>
<script>
export default {
    data() {
        return {
            DOB: "",
        }
    },
    computed: {
        patient: {
            get() {
                return this.$store.state.patient
            },
            set(val) {
                let newVal = structuredClone(val)
                this.$store.commit('SET_PATIENT', newVal)
            }
        },
        malebutton() {
            if (this.patient.sex == 'Male') {
                return 'active'
            }
            return ''
        },
        femalebutton() {
            if (this.patient.sex == 'Female') {
                return 'active'
            }
            return ''
        },
        isDisabled(){
            if(!this.patient.name){
                return true
            }
            if(!this.DOB){
                return true
            }
            if(!this.patient.sex){
                return true
            }
            if(!this.patient.email){
                return true
            }
            if(!this.patient.address){
                return true
            }
            return false
        }
    },
    watch: {
        DOB(newVal, oldVal){
            let pattern = /[^0-9/]/
            let result = newVal.match(pattern)
            if(result){
                this.DOB = oldVal
            }
            if(newVal.length == 2 || newVal.length == 5){
                this.DOB = newVal + '/'
            }
        }
    },
    methods: {
        maleSelect() {
            this.patient.sex = 'Male'
        },
        femaleSelect() {
            this.patient.sex = 'Female'
        },
        shiftFocus() {
            this.$refs.DOB.focus()
        },
        clearDOB(){
            this.DOB = ""
        },
        checkDate() {
            let date = this.DOB

            if (date.length < 8) {
                this.validDate = !this.validDate
                this.date_input = 'error'
                return
            }

            let parts = date.split('/')
            if (parts.length != 3) {
                this.validDate = !this.validDate
                this.date_input = 'error'
                return
            }

            let dateString = `${parts[1]}/${parts[0]}/${parts[2]}`
            let parsed = new Date(dateString)

            if (isNaN(parsed)) {
                this.validDate = !this.validDate
                this.date_input = 'error'
            }
        },
        numberSlash(str) {
            var count = 0
            for (var i = 0; i < str.length; i++) {
                if (str[i] === '/') {
                    count = count + 1
                }
            }
            return count
        },
        removeError() {
            if (this.validDate === false) {
                this.validDate = true
                this.DOB = ''
                this.date_input = 'border-input extra-padding'
            }
        },
        next(){
            this.$router.push('/medical-info')
        }
    }
}
</script>
<style scoped>
.inputName {
    text-transform: capitalize;
}

.error {
    border-bottom: 2px solid red !important;
}

.small-changes {
    font-size: 1rem;
    bottom: 2rem;
}

#selctorButton,
#selctorButton2 {
    font-size: 18px;
    height: 45px;
    width: 90px;
    border-radius: 0;
    background-color: white;
    border: 2px solid black;
    outline: none;
    cursor: pointer;
}

.active {
    background-color: black !important;
    color: white;
}

input[type=text],
input[type=tel],
.vti__input,
input[type=email],
input[type=number] {
    border: 2px solid #0B62F1 !important;
    font-size: 1rem;
    padding: 0.5rem;
    width: 100%;
    max-width: 500px;
}

input[type=text]:focus,
input[type=tel]:focus,
.vti__input,
input[type=email]:focus,
input[type=number]:focus {
    border: 2px solid black !important;
    outline: 0;
    outline-color: transparent;
    outline-style: none;
}

textarea {
    border: 2px solid #0B62F1;
    font-size: 1rem;
    padding: 0.5rem;
    width: 100%;
    max-width: 500px;
    font-family: 'Atkinson Hyperlegible';
}

textarea:focus {
    border: 2px solid black;
    outline: 0;
    outline-color: transparent;
    outline-style: none;

}
</style>